import React from 'react';
import { Navbar } from 'components/navbar/navbar';
import { CustomMenu } from 'components/menu/menu';
import Header from 'components/header/header';

export default ({ children }) => {
  return (
    <div>
      <Header />
      <div className="app-content">{children}</div>
      <Navbar />
      <CustomMenu />
    </div>
  );
};
