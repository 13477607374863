import UIStore from './ui';
import moment from 'moment';
import { action } from 'mobx';
import { browserHistory } from 'react-router';
import { routesService } from 'services/transport-layer';
import RoutingModel from 'models/routing';
import { createDomainStore } from './base';

const config = {
  name: 'routing',
  serviceToInject: routesService,
  domainModel: RoutingModel,
  useQuery: true,
  modelNormalizer: model => model.routing || model,
  selectors: {
    dataSelector: (data, rawData) => {
      if (data.data) {
        return data.data;
      }

      return data;
    }
  }
};

export const {
  store: RoutingStore,
  listResolver: routingsResolver,
  blockingItemResolver: routingResolver
} = createDomainStore(config);

export const { store: RoutingModalStore } = createDomainStore(config);

Object.assign(RoutingStore, {
  create(routing) {
    UIStore.setToTransition();

    routesService
      .createNew(routing)
      .then(
        action(({ insertedId }) => {
          browserHistory.push(`/home/routing`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  update(id, routing) {
    UIStore.setToTransition();

    routesService
      .update(id, routing)
      .then(
        action(() => {
          browserHistory.push(`/home/routing`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  delete(id) {
    UIStore.setToTransition();
    routesService
      .delete(id)
      .then(
        action(() => {
          this.queryItems(false);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  }
});
