import { Model } from './utils';
import { observable } from 'mobx';
import { serializable, alias, list, object } from 'serializr';

export class TemplateInfo extends Model {
  @serializable
  @observable
  routeId;

  @serializable
  @observable
  templateId;
}
export class Swiperead extends Model {
  @serializable
  @observable
  sessionDuration;

  @serializable
  @observable
  caredocs = false;

  @serializable
  @observable
  diagnosis = false;

  @serializable
  @observable
  medications = false;

  @serializable
  @observable
  measurements = false;
}

export default class CareUnitModel extends Model {
  @serializable(alias('_id'))
  id;

  @serializable
  @observable
  isActive;

  @serializable
  @observable
  code;

  @serializable
  @observable
  description;

  @serializable
  @observable
  name;

  @serializable
  @observable
  optionalSITHS = false;

  @serializable
  @observable
  allowChecklist = false;

  @serializable
  @observable
  lastUpdate;

  @serializable
  @observable
  internalId;

  @serializable
  @observable
  cgmxHSAId;

  @serializable
  @observable
  logout;

  @serializable(list(object(TemplateInfo)))
  @observable
  ordinaryTemplates = [];

  @serializable(list())
  @observable
  preliminaryTemplates = [];

  @serializable(list())
  @observable
  checklistTemplates = [];

  @serializable deletable;

  @serializable(object(Swiperead))
  @observable
  swiperead = new Swiperead();
}
