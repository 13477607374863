import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';

import './button.css';

import { translate, getFileExtension } from 'utils/helpers';
const t = translate(['templates']);

@inject('ui')
@observer
export default class extends Component {
  inputEl = null;

  onButtonClick = () => {
    this.inputEl.value = '';
    // emulate click on <input />
    this.inputEl.click();
  };

  onFileSelected = event => {
    const files = event.target.files;

    if (!(files && files[0])) {
      return;
    }

    const file = files[0];
    const fileExtension = getFileExtension(file.name);

    if (this.props.accept) {
      if (
        this.props.accept.includes(fileExtension) ||
        (this.props.accept.includes(file.type) && file.type !== '')
      ) {
        this.props.onFileSelected(file);
      } else {
        this.props.ui.showError({ messageId: 'errors.fileTypeIsInvalid' }, t);
      }
    } else {
      this.props.onFileSelected(file);
    }
  };

  attachRef = node => (this.inputEl = node);

  render() {
    const {
      className,
      children,
      action,
      color,
      isFaded = false,
      isFile = false,
      accept,
      onFileSelected,
      testId,
      ...rest
    } = this.props;

    let ch;
    if (typeof children === 'function') {
      ch = children();
    }

    if (isFile) {
      return (
        <div>
          <button
            className={cx('custom-button', className, action, color, { faded: isFaded })}
            {...rest}
            onClick={this.onButtonClick}
          >
            {ch || children}
          </button>
          <input
            data-test-id={testId}
            onChange={this.onFileSelected}
            type="file"
            ref={this.attachRef}
            className="hidden"
            accept={accept}
          />
        </div>
      );
    }

    return (
      <button
        className={cx('custom-button', className, action, color, { faded: isFaded })}
        {...rest}
      >
        {ch || children}
      </button>
    );
  }
}
