import { observable, action, computed, set, values } from 'mobx';
import { deserialize, update } from 'serializr';
import { errorHandler } from './mixins';
import UIStore, { createFilterStore } from './ui';
import axios from 'axios';

export function createDomainStore({
  name,
  serviceToInject,
  domainModel,
  selectors,
  useQuery = false,
  sortState
}) {
  const { paramsSelector, paramsItemSelector, dataSelector, modelNormalizer } = selectors || {};

  const domainStore = {
    [name]: observable.map({}),
    _rawData: {},
    _queryRawData: {},

    getPageCount() {
      return domainStore.meta.filtered;
    },

    queryItems(silent = false, queryParams = []) {
      if (!silent) {
        this.isListLoading.set(true);
      }

      if (this.cancelToken) {
        this.cancelToken.cancel();
      }

      this.cancelToken = axios.CancelToken.source();

      return serviceToInject
        .query(this.viewState.requestQuery, this.cancelToken.token, ...queryParams)
        .then(useQuery ? this.resetItemsQuery : this.resetItems)
        .catch(error => {
          if (axios.isCancel(error)) {
            // just ignore canceling
            return;
          }

          UIStore.showError(error);
          this.isListLoading.set(false);
        });
    },

    fetchItems(...args) {
      return serviceToInject.fetch(...args).then(this.resetItems);
    },

    fetchItemById(...args) {
      this.isItemFetching.set(true);
      return serviceToInject.fetchOne(...args).then(model => {
        const addedModel = this.addOrUpdateItem(model);
        this.isItemFetching.set(false);
        return addedModel;
      });
    },

    get rawData() {
      return this._rawData;
    },

    get queryRawData() {
      return this._queryRawData;
    },

    @action.bound
    resetItems(data) {
      this._rawData = data;
      const newItems = {};
      const select = dataSelector ? dataSelector(data) : data;

      select.forEach(model => {
        if (this[name].has(model._id)) {
          this.addOrUpdateItem(model);
          newItems[model._id] = this.getItem(model._id);
          return;
        }

        newItems[model._id] = deserialize(domainModel, model);
      });

      this[name] = observable.map(newItems);
    },

    @action.bound
    resetItemsQuery(response) {
      const { data, meta } = response;
      this._rawData = data;
      this._queryRawData = response;
      const newItems = {};
      const select = dataSelector ? dataSelector(data, response) : data;

      select.forEach(model => {
        if (this[name].has(model._id)) {
          this.addOrUpdateItem(model);
          newItems[model._id] = this.getItem(model._id);
          return;
        }

        newItems[model._id] = deserialize(domainModel, model);
      });

      this[name] = observable.map(newItems);
      this.meta.filtered = meta.filtered;
      this.isListLoading.set(false);
      this.cancelToken = null;
    },

    @action.bound
    deleteItem(id) {
      this[name].delete(id);
    },

    @action.bound
    addOrUpdateItem(model) {
      // TODO: dublicates logic with data selector (see patients store)
      const adapted = modelNormalizer ? modelNormalizer(model) : model;

      if (this[name].has(adapted._id)) {
        update(this.getItem(adapted._id), adapted);
      } else {
        this[name].set(adapted._id, deserialize(domainModel, adapted));
      }

      return this.getItem(adapted._id);
    },

    getItem(id) {
      return this[name].get(id);
    },

    @computed
    get list() {
      return values(this[name]);
    }
  };

  set(domainStore, {
    [name]: observable.map({})
  });

  let lastQueryParams = [];

  domainStore.meta = observable({ total: [], filtered: 0 });
  domainStore.viewState = createFilterStore(
    name,
    () => domainStore.queryItems(false, lastQueryParams),
    undefined,
    sortState
  );
  domainStore.isListLoading = observable.box(false);
  domainStore.isItemFetching = observable.box(false);

  function listResolver(nextState, replace, callback) {
    if (useQuery) {
      lastQueryParams = paramsItemSelector ? paramsItemSelector(nextState) : nextState.params.id;
      callback();
      domainStore.queryItems(false, lastQueryParams).catch(errorHandler(...arguments));
      return;
    }

    if (domainStore[name].size > 0) {
      callback();
      domainStore
        .fetchItems(paramsSelector ? paramsSelector(nextState) : [])
        .catch(errorHandler(...arguments));
    } else {
      UIStore.setToTransition();
      domainStore
        .fetchItems(paramsSelector ? paramsSelector(nextState) : [])
        .then(() => {
          callback();
          UIStore.setOutOfTransition();
        })
        .catch(errorHandler(...arguments));
    }
  }

  function itemResolver(nextState, replace, callback) {
    const item = domainStore.getItem(nextState.params.id);

    if (item) {
      callback();
      domainStore
        .fetchItemById(paramsItemSelector ? paramsItemSelector(nextState) : nextState.params.id)
        .catch(errorHandler(...arguments));
    } else {
      UIStore.setToTransition();
      domainStore
        .fetchItemById(paramsItemSelector ? paramsItemSelector(nextState) : nextState.params.id)
        .then(() => {
          callback();
          UIStore.setOutOfTransition();
        })
        .catch(errorHandler(...arguments));
    }
  }

  function blockingItemResolver(nextState, replace, callback) {
    const item = domainStore.getItem(nextState.params.id);

    if (item) {
      domainStore
        .fetchItemById(paramsItemSelector ? paramsItemSelector(nextState) : nextState.params.id)
        .then(() => callback())
        .catch(errorHandler(...arguments));
    } else {
      UIStore.setToTransition();
      domainStore
        .fetchItemById(paramsItemSelector ? paramsItemSelector(nextState) : nextState.params.id)
        .then(() => {
          callback();
          UIStore.setOutOfTransition();
        })
        .catch(errorHandler(...arguments));
    }
  }

  return { store: domainStore, listResolver, itemResolver, blockingItemResolver };
}
