import UIStore from './ui';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import { browserHistory } from 'react-router';
import { mappingService } from 'services/transport-layer';
import MappingModel from 'models/mapping';
import { createDomainStore } from './base';
import { exportJSON } from 'utils/helpers';

export const {
  store: MappingStore,
  listResolver: mappingsResolver,
  blockingItemResolver: mappingResolver
} = createDomainStore({
  name: 'mapping',
  serviceToInject: mappingService,
  domainModel: MappingModel,
  useQuery: true,
  selectors: {
    dataSelector: (data, rawData) => {
      if (data.data) {
        return data.data;
      }

      return data;
    },
    modelNormalizer: model => {
      if (model.elements && model.pages && model.questions) {
        model.elements.forEach(element => (element.key = element.id));
        model.pages.forEach(page => (page.key = page.id));
        model.questions.forEach(question => (question.key = question.id));
      }

      return model;
    }
  }
});

Object.assign(MappingStore, {
  create(mappings) {
    UIStore.setToTransition();

    mappingService
      .createNew(mappings)
      .then(UIStore.setOutOfTransition)
      .then(() => browserHistory.push(`/home/mapping`))
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },
  update(id, mappings) {
    UIStore.setToTransition();

    mappingService
      .update(id, mappings)
      .then(
        action(() => {
          browserHistory.push(`/home/mapping`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  export(id, name) {
    UIStore.setToTransition();

    mappingService
      .export(id)
      .then(
        action(map => {
          const mapping = JSON.stringify(map);
          exportJSON(mapping, name);

          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  delete(id) {
    UIStore.setToTransition();

    mappingService
      .delete(id)
      .then(
        action(() => {
          this.queryItems(false);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  }
});
