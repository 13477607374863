export class BaseService {
  resource;
  restClient;

  constructor({ restClient, resource }) {
    this.restClient = restClient;
    this.resource = resource;
  }

  get(url, options) {
    return this.restClient.get(this.makeUrl(url), options);
  }

  post(url, options) {
    return this.restClient.post(this.makeUrl(url), options);
  }

  put(url, options) {
    return this.restClient.put(this.makeUrl(url), options);
  }

  del(url, options) {
    return this.restClient.del(this.makeUrl(url), options);
  }

  patch(url, options) {
    return this.restClient.patch(this.makeUrl(url), options);
  }

  link(url, options) {
    return this.restClient.link(this.makeUrl(url), options);
  }

  unlink(url, options) {
    return this.restClient.unlink(this.makeUrl(url), options);
  }

  makeUrl(url) {
    return this.resource + url;
  }
}
