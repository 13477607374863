// i18next instance
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const lStorageLangKey = 'sc-lang-v2';

// Langs
import en from './locales/en.js';
import sv from './locales/sv.js';

i18next.use(LanguageDetector).init({
  fallbackLng: 'sv',
  defaultValue: 'sv',
  lng: window.Cypress && window.Cypress.ciLanguage,
  nsSeparator: '::',
  lngs: ['sv', 'en'],
  resources: { sv, en },
  returnNull: false,
  detection: {
    // order and from where user language should be detected
    order: ['localStorage'],

    // keys or params to lookup language from
    lookupQuerystring: lStorageLangKey,
    lookupCookie: lStorageLangKey,
    lookupLocalStorage: lStorageLangKey,

    // cache user language on
    caches: ['localStorage', 'cookie']
  }
});

export default i18next;
