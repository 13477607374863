import React, { Component } from 'react';
import { observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import cx from 'classnames';
import { userService } from 'services/transport-layer';

import Input from 'components/input/input';
import Button from 'components/button/button';
import Form from 'components/form/form';

import LoginModel, { RecoveryRequestModel, RecoveryConfirmModel } from 'stores/models/login';

import { translate, notEmptyValidator } from 'utils/helpers';
const t = translate(['common', 'login', 'users', 'errorsFromBackend']);

import './login.css';

function CycleSpinner({ spin }) {
  return (
    <div className={cx('background-circle', { spin: spin.get() })}>
      <img src={require('assets/svg/sc-background-circle.svg')} />
    </div>
  );
}

@inject('ui', 'session')
@observer
export default class LoginForm extends Component {
  state = {
    isSiths: !this.isAdminOrDemo,
    isRecovery: this.props.route.recovery
  };

  spin = observable.box(false);
  @computed
  get isAdminOrDemo() {
    return this.props.route.admin || this.props.session.isDemo;
  }
  componentWillMount() {
    this.model = new LoginModel();
    this.recoveryRequestModel = new RecoveryRequestModel();
    this.recoveryConfirmModel = new RecoveryConfirmModel();
  }

  onSubmit = e => {
    e.preventDefault();
    this.model.viewModel.submit();

    this.spin.set(true);
    this.props.session
      .login(this.model, this.props.route.admin, this.state.isSiths)
      .then(() => {
        this.spin.set(false);
      })
      .catch(() => {
        this.spin.set(false);
      });
  };

  onSubmitRequestRecovery = e => {
    e.preventDefault();
    this.recoveryRequestModel.viewModel.submit();

    this.spin.set(true);

    userService
      .generateTokenForPasswordReset(this.recoveryRequestModel.recoveryEmail, false)
      .then(() => {
        this.spin.set(false);
        this.toggleModeToRecovery();
      })
      .catch(error => {
        this.spin.set(false);
        this.props.ui.showError(error);
      });
  };

  onSubmitConfirmRecovery = (e, isValid) => {
    if (!isValid) {
      return;
    }

    this.recoveryConfirmModel.viewModel.submit();
    this.spin.set(true);

    userService
      .confirmNewPasswordAfterReset(
        this.props.routeParams.token,
        this.recoveryConfirmModel.password
      )
      .then(() => {
        this.spin.set(false);
        this.toggleModeToRecovery();
        this.toggleModeToSiths();
        browserHistory.replace('/login');
      })
      .catch(() => {
        this.spin.set(false);
      });
  };

  getStandartForm() {
    return (
      <form onSubmit={this.onSubmit}>
        <Input autoFocus form={this.model.viewModel} name="username" placeholder={t('username')} />
        <Input
          form={this.model.viewModel}
          name="password"
          placeholder={t('password')}
          type="password"
        />
        <div className="auth-types">
          <Button type="submit">{t('submit')}</Button>
          <div className={cx('auth-links-box', { centered: this.isAdminOrDemo })}>
            {!this.isAdminOrDemo && (
              <a onClick={this.toggleModeToRecovery}>{t('forgotPassword')}</a>
            )}
            {!this.props.session.isDemo && (
              <a onClick={this.toggleModeToSiths}>
                {this.isAdminOrDemo ? t('loginViaSITHS') : t('getBackToSITHS')}
              </a>
            )}
          </div>
        </div>
      </form>
    );
  }

  getSITHSForm() {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="auth-types">
          <Button type="submit">{t('SITHSAuth')}</Button>
          <a onClick={this.toggleModeToSiths}>{t('signInWithCredentials')}</a>
          {/*
            Hidden due to PM request
            <span>or</span>
            <a onClick={this.redirectToHelp}>{t('troublesWithLogin')}</a>
          */}
        </div>
      </form>
    );
  }

  getRecoveryForm() {
    return (
      <form onSubmit={this.onSubmitRequestRecovery}>
        <Input
          autoFocus
          form={this.recoveryRequestModel.viewModel}
          name="recoveryEmail"
          placeholder={t('username')}
        />
        {/* Hidden to achive correct design */}
        <Input
          style={{ visibility: 'hidden' }}
          form={this.model.viewModel}
          name="text"
          placeholder={t('password')}
          type="text"
        />
        <div className="auth-types">
          <Button type="submit">{t('submitRecovery')}</Button>
          <div className="auth-links-box">
            <a onClick={this.toggleModeToRecovery}>{t('backToLogin')}</a>
          </div>
        </div>
      </form>
    );
  }

  passwordValidator = (value, model) => value === model.password;

  getRecoveryConfirmForm() {
    return (
      <Form
        onSubmit={this.onSubmitConfirmRecovery}
        form={this.recoveryConfirmModel.viewModel}
        autoComplete="nope"
      >
        <Input
          name="password"
          label={t('password')}
          placeholder={t('password')}
          type="password"
          validator={notEmptyValidator}
          validationText={t('errors.pleaseInputField')}
          autoComplete="nope"
          required
        />
        <Input
          name="passwordRepeat"
          label={t('confirmNewPassword')}
          placeholder={t('confirmNewPassword')}
          type="password"
          validator={this.passwordValidator}
          validationText={t('errors.passwordsShouldMatch')}
          autoComplete="nope"
          required
        />
        <div className="auth-types">
          <Button type="submit">{t('update')}</Button>
        </div>
      </Form>
    );
  }

  toggleModeToSiths = () => this.setState({ isSiths: !this.state.isSiths });
  toggleModeToRecovery = () => this.setState({ isRecovery: !this.state.isRecovery });

  redirectToHelp = () => browserHistory.push('/help/login');

  render() {
    let form;
    let greetings;

    if (!this.state.isRecovery) {
      greetings = (
        <div className="greeting">
          <h1>{t('greeting')}</h1>
          <h2>{t('enterCredentials')}</h2>
        </div>
      );

      if (this.state.isSiths) {
        form = this.getSITHSForm();
      } else {
        form = this.getStandartForm();
      }
    } else {
      greetings = (
        <div className="greeting">
          <h1>{t('greetingRecovery')}</h1>
          <h2>{t('enterCredentialsRecovery')}</h2>
        </div>
      );

      if (this.props.route.recovery) {
        greetings = (
          <div className="greeting">
            <h1>{t('greetingRecoveryConfirm')}</h1>
            <h2>{t('enterCredentialsRecoveryConfirm')}</h2>
          </div>
        );
        form = this.getRecoveryConfirmForm();
      } else {
        form = this.getRecoveryForm();
      }
    }

    return (
      <div className={cx('login-page', { 'admin-login': this.props.route.admin })}>
        <div className="layout-centered">
          <CycleSpinner spin={this.spin} />
        </div>
        <div className="layout-centered">
          <div
            className={cx('form', {
              admin: this.props.route.admin,
              ['siths-form']: this.state.isSiths,
              ['recovery-confirm']: this.props.route.recovery
            })}
          >
            {greetings}
            <div className={cx('inputs', { siths: this.state.isSiths })}>{form}</div>
            <div className="form-footer">
              <div
                className={cx('swipecare-logo', {
                  ['recovery-confirm']: this.props.route.recovery
                })}
              >
                <img src={require('assets/svg/sc-logo.svg')} />
              </div>
              <div
                className={cx('swipecare-texture', {
                  ['recovery-confirm']: this.props.route.recovery
                })}
              >
                <img src={require('assets/svg/sc-texture.svg')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
