import { BaseService } from '../base-service';

export class MappingService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/mappings' });
  }

  query(params = {}, cancelToken) {
    params = { ...params };

    return this.get('/', { params, cancelToken });
  }

  fetch() {
    return this.get('/');
  }

  fetchOne(id) {
    return this.get('/' + id);
  }

  export(id) {
    return this.get(`/${id}/export`);
  }

  createNew(data = {}) {
    return this.post('/', { data });
  }

  update(id, data = {}) {
    return this.put('/' + id, { data });
  }

  delete(id) {
    return this.del('/' + id);
  }
}
