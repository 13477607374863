import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

import SurveySection from 'components/surveySection/survey-section';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';

import { surveyStatus } from 'formatters/tables';
import { dateTimeFormat } from 'formatters/dates';
import { surveyStatusMap } from 'stores/models/survey';
import { translate } from 'utils/helpers';

import './survey-details.css';

const t = translate(['common', 'surveys']);

function SurveyDetailBlock({ header = 'header', subheader = '', data = [] }) {
  const content = (
    <table data-test-id={`SurveyDetail_${header}`}>
      <tbody>
        {data.filter(Boolean).map((item, idx) => (
          <tr key={idx}>
            <td>{item.label}</td>
            <td>{item.text}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  if (subheader) {
    return (
      <div className="super-block">
        <h1 className="super-header">{header}</h1>
        <div className="block">
          <h2 className="header">{subheader}</h2>
          {content}
        </div>
      </div>
    );
  }

  return (
    <div className="block">
      <h1 className="header">{header}</h1>
      {content}
    </div>
  );
}

@inject('surveys')
@observer
export default class SurveyDetailsPage extends Component {
  retrySending = () => this.props.surveys.retrySending(this.getModel().id);
  defectize = () => this.props.surveys.defectize(this.getModel().id);
  confirmSITHS = () => this.props.surveys.confirmSITHS(this.getModel().id);
  archive = () => this.props.surveys.archive(this.getModel().id);
  unarchive = () => this.props.surveys.unarchive(this.getModel().id);
  sendSurveyToEmr = () => this.props.surveys.sendSurveyToEmr(this.getModel().id);
  setSurveyToQA = () => this.props.surveys.setSurveyToQA(this.getModel().id);

  componentDidMount() {
    const model = this.getModel();
    this.props.surveys.fetchSurveyEMRDataById(model);
  }

  getModel = () => {
    const { surveys, params } = this.props;
    return surveys.getSurvey(params.id);
  };

  render() {
    const model = this.getModel();

    const doctor = model.doctor || {};
    const patient = model.patient || {};

    const buttons = [];

    if (
      model.status === surveyStatusMap.finished ||
      model.status === surveyStatusMap.requiringAttention
    ) {
      buttons.unshift(
        <Button
          key="finished||requiringAttention"
          type="submit"
          action="secondary"
          color="steel"
          onClick={this.retrySending}
        >
          {t('retryProcessing')}
        </Button>
      );
    }

    if (
      model.status === surveyStatusMap.requiringAttention ||
      model.status === surveyStatusMap.requireSITHSConfirmation
    ) {
      buttons.push(
        <Button
          key="requireSITHSConfirmation||requiringAttention"
          type="submit"
          action="secondary"
          color="peach"
          onClick={this.defectize}
        >
          {t('markAsDefective')}
        </Button>
      );
    }

    if (model.status === surveyStatusMap.requireSITHSConfirmation) {
      buttons.push(
        <Button
          key="requireSITHSConfirmation"
          type="submit"
          action="primary"
          color="green"
          onClick={this.confirmSITHS}
        >
          {t('confirmSITHS')}
        </Button>
      );
    }

    if (
      model.status === surveyStatusMap.waitingForQa ||
      model.status === surveyStatusMap.qa ||
      model.status === surveyStatusMap.importGaveUp
    ) {
      buttons.push(
        <Button
          key="waitingForQa||qa||importGaveUp"
          type="submit"
          action="secondary"
          color="steel"
          onClick={this.archive}
        >
          {t('archiveSurvey')}
        </Button>
      );
    }

    if (model.status === surveyStatusMap.archived) {
      buttons.push(
        <Button
          key="archived"
          type="submit"
          action="secondary"
          color="steel"
          onClick={this.unarchive}
        >
          {t('unarchiveSurvey')}
        </Button>
      );
    }

    if (model.status === surveyStatusMap.importGaveUp) {
      buttons.push(
        <Button key="importGaveUp" action="secondary" color="steel" onClick={this.sendSurveyToEmr}>
          {t('retrySending')}
        </Button>
      );
    }

    if (
      model.status === surveyStatusMap.waitingForQa ||
      model.status === surveyStatusMap.importGaveUp ||
      model.status === surveyStatusMap.imported
    ) {
      buttons.push(
        <Button
          key="waitingForQa||importGaveUp||imported"
          type="submit"
          action="primary"
          color="green"
          onClick={this.setSurveyToQA}
        >
          {t('startQa')}
        </Button>
      );
    }

    return (
      <div className="surveys-details-page action-footer-padded">
        <div className="content">
          <SurveyDetailBlock
            header={t('metadata')}
            data={[
              { label: t('id'), text: model.guid },
              model.error && { label: t('error'), text: model.error },
              { label: t('status'), text: surveyStatus(model.status) },
              { label: t('templateName'), text: model.template.name },
              { label: t('templateCode'), text: model.template.code },
              { label: t('careUnitInternalId'), text: model.orgunit.internalId },
              { label: t('careUnitCode'), text: model.orgunit.code },
              { label: t('uploadDate'), text: dateTimeFormat(moment(model.uploaded).local()) },
              { label: t('lastUpdate'), text: dateTimeFormat(moment(model.lastUpdate).local()) }
            ]}
          />
          <SurveyDetailBlock
            header={t('visit')}
            data={[
              { label: t('time'), text: dateTimeFormat(moment(model.visitDate).local()) },
              { label: t('patientName'), text: patient.name },
              { label: t('doctorName'), text: doctor.name }
            ]}
          />
          {model.primaryData.sections.map((section, idx) => (
            <SurveySection key={idx} section={section} />
          ))}
        </div>
        {!!buttons.length && <ActionFooter>{buttons}</ActionFooter>}
      </div>
    );
  }
}
