import React from 'react';
import RNC from 'react-notification-system';
import { inject } from 'mobx-react';

const style = {
  NotificationItem: {
    DefaultStyle: {
      minHeight: '50px'
    }
  }
};

function NotificationSystem({ ui }) {
  return (
    <RNC
      style={style}
      ref={instance => {
        // Inject notifications instance
        ui.notifications = instance;
      }}
    />
  );
}

export default inject('ui')(NotificationSystem);
