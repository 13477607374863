import React, { Component } from 'react';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory, Link } from 'react-router';

import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import { Tooltip } from 'react-tippy';
import { errorHandler } from '../../stores/mixins';
import Input from 'components/input/input';

import {
  timeFormatter,
  applySorting,
  isActiveToTextFormatter,
  applySortingServerSide
} from 'formatters/tables';

import './templates.css';

import { translate, HideIf } from 'utils/helpers';
import BackTo from 'components/backTo/back-to';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';

const t = translate(['common', 'templates']);

/**
 * Used to track current selected editableTemplate and progenitorTempalte
 * Set to null on unmount
 * Think of this, like a global state for components in this file
 */
const editableTemplate = observable.box(null);
const progenitorTemplate = observable.box(null);

const linkNameEnum = {
  APPLY: t('APPLY'),
  ASSIGN: t('ASSIGN'),
  UNASSIGN: t('UNASSIGN'),
  ASSIGNED: t('ASSIGNED')
};

const TemplateTable = observer(props => (
  <div>
    <div className="panel" data-test-id="applyTable">
      <DataTable
        data={[editableTemplate.get()]}
        columns={props.columns.concat([
          {
            property: 'id',
            cell: {
              formatters: [id => props.quickLinkCell(id, props.linkNameEnum.APPLY, props)],
              props: { style: { whiteSpace: 'nowrap' } }
            }
          }
        ])}
      />
    </div>
  </div>
));

const ProgenitorTable = observer(props => (
  <div>
    <div className="panel linked-table-block" data-test-id="unassignTable">
      <DataTable
        data={[progenitorTemplate.get()]}
        columns={props.columns.concat([
          {
            property: 'id',
            cell: {
              formatters: [id => props.quickLinkCell(id, props.linkNameEnum.UNASSIGN, props)],
              props: { style: { whiteSpace: 'nowrap' } }
            }
          }
        ])}
      />
    </div>
  </div>
));

const TemplatesTable = observer(props => (
  <div className=" action-footer-padded">
    <h1>{t('templateLink')}</h1>
    <div className="panel" data-test-id="assignTable">
      <DataTable
        data={props.templates.list}
        columns={props.columns.concat([
          {
            property: 'id',
            cell: {
              formatters: [id => props.quickLinkCell(id, props.linkNameEnum.ASSIGN, props)],
              props: { style: { whiteSpace: 'nowrap' } }
            }
          }
        ])}
        isLoading={props.templates.isListLoading}
        pageState={props.templates.viewState.pageState}
        pageCount={props.templates.getPageCount}
        onPageChange={props.onPageClick}
        serverSide
      />
    </div>
  </div>
));

const quickLinkCell = (
  id,
  linkName,
  {
    params,
    templates,
    setProgenitorTemplate,
    setEditableTemplate,
    progenitorTemplate,
    linkNameEnum
  }
) => {
  switch (linkName) {
    case linkNameEnum.UNASSIGN:
      return (
        <Tooltip title={t('unassignTemplate')} arrow="true" distance={20}>
          <a
            className="unassign-link"
            data-test-id="unassignTemplate"
            href="#"
            onClick={event => {
              event.preventDefault();

              setProgenitorTemplate();
              setEditableTemplate(editableTemplate.get(), true);
            }}
          >
            {linkName}
          </a>
        </Tooltip>
      );

    case linkNameEnum.APPLY:
      return;
    default:
      return (
        <Tooltip title={t('assignTemplate')} arrow="true" distance={20}>
          <a
            data-test-id="assignTemplate"
            className={
              progenitorTemplate && progenitorTemplate.id === id ? 'assigned-link' : 'assign-link'
            }
            href="#"
            onClick={event => {
              event.preventDefault();

              if (editableTemplate) {
                setProgenitorTemplate(templates.getItem(id));
                setEditableTemplate(editableTemplate.get(), true);
              }
            }}
          >
            {progenitorTemplate && progenitorTemplate.id === id ? linkNameEnum.ASSIGNED : linkName}
          </a>
        </Tooltip>
      );
  }
};

@inject('templates')
@observer
class TemplatesAssignPage extends Component {
  constructor(props) {
    super(props);
    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.templates.viewState.sortState
    );
    this.columns = [
      {
        property: 'isActive',
        header: {
          label: t('isActive'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [isActiveToTextFormatter]
        }
      },
      {
        property: 'name',
        header: {
          label: t('name'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'progenitor',
        header: {
          label: t('progenitorName'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [progenitor => (progenitor ? progenitor.name : '-')]
        }
      },
      {
        property: 'version',
        header: {
          label: t('version'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    ];
    this.simpleColumns = this.columns.map(el => {
      return {
        ...el,
        header: { ...el.header, formatters: [] }
      };
    });
  }

  componentDidMount() {
    const { params, templates } = this.props;

    if (!this.hasEditableTemplate) {
      templates
        .fetchItemById(params.id)
        .then(editableTemplate => this.setEditableTemplate(editableTemplate))
        .catch(errorHandler(...arguments));
    }
  }

  componentWillUnmount() {
    editableTemplate.set(null);
    progenitorTemplate.set(null);
    this.props.templates.viewState.resetFiltered();
  }

  @computed
  get hasEditableTemplate() {
    return !!editableTemplate.get();
  }

  @computed
  get hasProgenitorTemplate() {
    return !!progenitorTemplate.get();
  }

  @action.bound
  setEditableTemplate(newEditableTemplate, setProgenitor = false) {
    if (setProgenitor && editableTemplate) {
      editableTemplate.get().progenitor = progenitorTemplate.get();
    }

    editableTemplate.set(newEditableTemplate);
  }

  @action.bound
  setProgenitorTemplate(newProgenitorTemplate) {
    progenitorTemplate.set(newProgenitorTemplate);
  }

  onPageClick = selected => this.props.templates.viewState.changePage(selected);

  isFiltered = () => this.props.templates.viewState.isFiltered;

  onSortClick = property => this.props.templates.viewState.applySort(property);

  onSubmit = () => {
    const { templates } = this.props;
    const template = editableTemplate.get();

    if (this.hasEditableTemplate && template.progenitor) {
      templates.linkFromProgenitor(template.id, template.progenitor.id);
    }
  };

  render() {
    const { params, templates } = this.props;

    return (
      <div className="templates-page">
        <NavbarInfo content={<BackTo title={t('backToTemplateList')} to="/home/templates" />}>
          {t('linkTitle')}
        </NavbarInfo>
        {this.hasEditableTemplate && (
          <TemplateTable
            quickLinkCell={quickLinkCell}
            columns={this.simpleColumns}
            params={params}
            templates={templates}
            setProgenitorTemplate={this.setProgenitorTemplate}
            setEditableTemplate={this.setEditableTemplate}
            linkNameEnum={linkNameEnum}
          />
        )}
        {this.hasProgenitorTemplate && (
          <ProgenitorTable
            quickLinkCell={quickLinkCell}
            columns={this.simpleColumns}
            params={params}
            templates={templates}
            setProgenitorTemplate={this.setProgenitorTemplate}
            setEditableTemplate={this.setEditableTemplate}
            linkNameEnum={linkNameEnum}
          />
        )}
        <TemplatesTable
          quickLinkCell={quickLinkCell}
          columns={this.columns}
          params={params}
          onPageClick={this.onPageClick}
          templates={templates}
          setProgenitorTemplate={this.setProgenitorTemplate}
          setEditableTemplate={this.setEditableTemplate}
          linkNameEnum={linkNameEnum}
        />
        <FiltersPanel
          className="filter-centered"
          filterState={templates.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="searchByTemplateName"
              name="value"
              form={templates.viewState.templateSearchState}
              leftText
              placeholder={t('filters.name.templateNamePlaceholder')}
              onChange={templates.viewState.changeTemplateSearch}
              innerRef={node => (this.node = node)}
            />
          </FilterView>
        </FiltersPanel>
        <ActionFooter>
          <Button
            data-test-id="searchTemplate"
            action="secondary"
            color="steel"
            onClick={templates.viewState.toggleOpen}
          >
            {!this.searchIsOpen ? t('searchTemplate') : t('closeFilter')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={templates.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
          <Button
            disabled={!this.hasProgenitorTemplate}
            action={this.hasProgenitorTemplate ? 'primary' : 'secondary'}
            data-test-id="save"
            color={this.hasProgenitorTemplate ? 'green' : 'steel'}
            onClick={this.onSubmit}
          >
            {t('save')}
          </Button>
        </ActionFooter>
      </div>
    );
  }
}

export default TemplatesAssignPage;
