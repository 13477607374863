import React, { Component, Fragment } from 'react';
import { observable, action, toJS } from 'mobx';
import { observer, inject, Observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { deserialize } from 'serializr';
import { withFormik, Form, Field, FieldArray, getIn } from 'formik';

import { NavbarInfo } from 'components/navbar/navbar';
import Card from 'components/card/card';
import Input from 'components/input/input';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import Checkbox from 'components/checkbox/checkbox';
import BackTo from 'components/backTo/back-to';

import { dateFormat } from 'formatters/dates';

import Mapping, { ElementsModel, PagesModel, QuestionsModel } from 'stores/models/mapping';

import './mapping.css';

import { translate } from 'utils/helpers';
const t = translate(['common', 'mapping']);

class MappingManage extends Component {
  @observable
  localState = { searchElements: '', searchPages: '', searchQuestions: '', activeTab: 'elements' };

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  }

  routerWillLeave = action => {
    if (this.props.dirty && !this.forceUnload) {
      this.props.ui.showModal(
        () => {
          this.forceUnload = true;
          browserHistory.push(action);
        },
        null,
        t('yourWorkIsNotSaved')
      );

      return false;
    }
  };

  componentWillUnmount() {
    this.forceUnload = false;
  }

  isEditMode = () => this.props.route.edit;

  onTabChange = tab => () => {
    this.localState.activeTab = tab;
  };

  onSearchChange = event => {
    switch (this.localState.activeTab) {
      case 'elements':
        this.localState.searchElements = event.currentTarget.value;
        break;
      case 'pages':
        this.localState.searchPages = event.currentTarget.value;
        break;
      case 'questions':
        this.localState.searchQuestions = event.currentTarget.value;
        break;
    }
  };

  getSearchValue = () => {
    switch (this.localState.activeTab) {
      case 'elements':
        return this.localState.searchElements;
      case 'pages':
        return this.localState.searchPages;
      case 'questions':
        return this.localState.searchQuestions;
    }
  };

  filterElements = element => {
    let search;

    switch (this.localState.activeTab) {
      case 'elements':
        search = this.localState.searchElements.toLowerCase();
        break;
      case 'pages':
        search = this.localState.searchPages.toLowerCase();
        break;
      case 'questions':
        search = this.localState.searchQuestions.toLowerCase();
        break;
    }

    const id = (element.id || '').toString().toLowerCase();
    const term = (element.term || '').toString().toLowerCase();
    const desc = (element.desc || '').toString().toLowerCase();

    return id.includes(search) || term.includes(search) || desc.includes(search);
  };

  renderElement = (form, type) => element => {
    // Get real index from original array
    // If we will use just array index, it will fail when we try to filter things
    const index = form[type].findIndex(item => item.id === element.id);

    return (
      <section className="card-inner-section" key={index}>
        <Field
          name={`${type}.${index}.id`}
          render={({ field }) => (
            <Input
              {...field}
              data-test-id={`${type}-${index}-id`}
              isFormik={true}
              required={true}
              label={t('elementId')}
            />
          )}
        />
        <Field
          name={`${type}.${index}.term`}
          render={({ field, form: { setFieldValue } }) => (
            <Input
              {...field}
              data-test-id={`${type}-${index}-term`}
              isFormik={true}
              required={true}
              onChange={event => {
                const value = event.target.value;

                if (!value) {
                  setFieldValue(`${type}.${index}.term`, '');
                  return;
                }

                if (!isNaN(parseFloat(value)) && isFinite(value)) {
                  field.onChange(event);
                  return;
                }
              }}
              type="number"
              label={t('termId')}
            />
          )}
        />
        <Field
          name={`${type}.${index}.desc`}
          render={({ field }) => (
            <Input
              {...field}
              data-test-id={`${type}-${index}-desc`}
              isFormik={true}
              label={t('description')}
            />
          )}
        />
        {type === 'questions' && (
          <Field
            name={`${type}.${index}.complex`}
            render={({ field }) => (
              <Checkbox
                {...field}
                classNameRoot="mapping"
                checked={element.complex}
                setFieldValue={this.props.setFieldValue}
                value={element.complex || false}
                data-test-id={`${type}-${index}-complex`}
                isFormik={true}
                label={t('complex')}
              />
            )}
          />
        )}
        <div className="remove-button">
          <img
            data-test-id={`delete.${index}.el`}
            alt={t('remove')}
            className="icon-button"
            src={require('assets/svg/sc-trash-active.svg')}
            onClick={this.removeElement(form, index)}
          />
        </div>
      </section>
    );
  };

  addElement = form => () => {
    switch (this.localState.activeTab) {
      case 'elements':
        form.elements.unshift({ key: '' + Math.random(), id: '', term: '', desc: '' });
        break;
      case 'pages':
        form.pages.unshift({ key: '' + Math.random(), id: '', term: '', desc: '' });
        break;
      case 'questions':
        form.questions.unshift({ key: '' + Math.random(), id: '', term: '', desc: '' });
        break;
    }

    this.localState.searchElements = '';
    this.localState.searchPages = '';
    this.localState.searchQuestions = '';

    this.forceUpdate();
  };

  removeElement = (form, index) => () => {
    switch (this.localState.activeTab) {
      case 'elements':
        form.elements.splice(index, 1);
        break;
      case 'pages':
        form.pages.splice(index, 1);
        break;
      case 'questions':
        form.questions.splice(index, 1);
        break;
    }

    this.forceUpdate();
  };

  onFileSelect = file => {
    if (!FileReader) {
      console.warn('Some unsupported browser occurred.');
      return;
    }

    const fr = new FileReader();

    fr.onload = action(() => {
      let data;

      try {
        // user can attach invalid JSON
        data = JSON.parse(fr.result) || {};

        // dirty check is this Strikersoft template
        // in future versions JSON schema should be used
        if (
          !Array.isArray(data.elements) ||
          !Array.isArray(data.questions) ||
          !Array.isArray(data.pages)
        ) {
          throw new Error('Incorrect JSON file uploaded');
        }
      } catch (e) {
        // log error
        console.error(e);

        // show user error about incorrect uploaded file
        this.props.ui.showError({ messageId: 'errors.incorrectJSONFile' }, t);

        return;
      }

      const { name = '', lastUpdate = '', elements, questions, pages } = data;

      this.props.setValues({
        name,
        lastUpdate,
        elements: elements.map((el, key) => ({ ...el, key })),
        questions: questions.map((el, key) => ({ ...el, key })),
        pages: pages.map((el, key) => ({ ...el, key }))
      });
    });

    fr.readAsText(file);
  };

  render() {
    const { values, handleChange, setFieldValue } = this.props;
    let titleText;
    let formClass;

    if (this.isEditMode()) {
      titleText = t('editMapping', { name: values.name });
      formClass = 'editMappingForm';
    } else {
      titleText = t('addMapping');
      formClass = 'addMappingForm';
    }

    return (
      <div className="mapping-page action-footer-padded">
        <NavbarInfo content={<BackTo title={t('backToMappingList')} to="/home/mapping" />}>
          {titleText}
        </NavbarInfo>
        <div className="card-columns-container" key="form">
          <Form data-test-id={formClass}>
            <Card className="card-column fixed-width">
              <Input
                onChange={handleChange}
                name="name"
                data-test-id="name"
                value={values.name}
                label={t('name')}
                placeholder={t('name')}
                isFormik={true}
              />
              <Input
                name="lastUpdate"
                data-test-id="lastUpdateFormat"
                label={t('lastUpdate')}
                placeholder={t('lastUpdate')}
                value={values.lastUpdate ? dateFormat(moment(values.lastUpdate)) : t('never')}
                isFormik={true}
                disabled
              />

              <hr className="card-section-divider" />
              <Observer>
                {() => [
                  <Button
                    key="1"
                    type="button"
                    data-test-id="elementsTab"
                    action="tab"
                    className={this.localState.activeTab === 'elements' ? 'active' : ''}
                    color="transparent"
                    onClick={this.onTabChange('elements')}
                  >
                    {t('elements')}
                  </Button>,
                  <Button
                    key="2"
                    type="button"
                    data-test-id="pagesTab"
                    action="tab"
                    className={this.localState.activeTab === 'pages' ? 'active' : ''}
                    color="transparent"
                    onClick={this.onTabChange('pages')}
                  >
                    {t('pages')}
                  </Button>,
                  <Button
                    key="3"
                    type="button"
                    data-test-id="questionsTab"
                    action="tab"
                    className={this.localState.activeTab === 'questions' ? 'active' : ''}
                    color="transparent"
                    onClick={this.onTabChange('questions')}
                  >
                    {t('questions')}
                  </Button>
                ]}
              </Observer>
            </Card>
            <Card className="card-column">
              <div>
                <Observer>
                  {() => (
                    <Input
                      data-test-id="mappingSearch"
                      onChange={this.onSearchChange}
                      style={{ marginBottom: '30px' }}
                      name="search"
                      label={t('search')}
                      placeholder={t('search')}
                      value={this.getSearchValue()}
                      isFormik={true}
                    />
                  )}
                </Observer>
                <div className="card-scrollable-aria with-search">
                  <Observer>
                    {() => {
                      switch (this.localState.activeTab) {
                        case 'elements':
                          return (
                            <Observer>
                              {() => {
                                const items = values.elements
                                  .filter(this.filterElements)
                                  .map(this.renderElement(values, 'elements'));

                                return <FieldArray name="elements" render={() => items} />;
                              }}
                            </Observer>
                          );
                        case 'pages':
                          return (
                            <Observer>
                              {() => {
                                const items = values.pages
                                  .filter(this.filterElements)
                                  .map(this.renderElement(values, 'pages'));

                                return <FieldArray name="pages" render={() => items} />;
                              }}
                            </Observer>
                          );
                        case 'questions':
                          return (
                            <Observer>
                              {() => {
                                const items = values.questions
                                  .filter(this.filterElements)
                                  .map(this.renderElement(values, 'questions'));

                                return <FieldArray name="questions" render={() => items} />;
                              }}
                            </Observer>
                          );
                        default:
                          return null;
                      }
                    }}
                  </Observer>
                </div>
              </div>
            </Card>

            <ActionFooter key="actions">
              <Button
                action="secondary"
                data-test-id="import"
                color="green"
                isFile
                onFileSelected={this.onFileSelect}
                accept=".json"
                type="button"
              >
                {t('importFile')}
              </Button>
              <Button
                action="secondary"
                data-test-id="addElement"
                color="green"
                type="button"
                onClick={this.addElement(values)}
              >
                {t('addElement')}
              </Button>
              <Button action="primary" data-test-id="saveMapping" color="wisteria" type="submit">
                {t('save')}
              </Button>
            </ActionFooter>
          </Form>
        </div>
      </div>
    );
  }
}

export default inject('mapping', 'ui')(
  withFormik({
    mapPropsToValues: props => {
      const { params, mapping } = props;
      const defaultModel = {
        elements: [],
        pages: [],
        questions: []
      };
      const model = props.route.edit
        ? mapping.getItem(params.id)
        : deserialize(Mapping, defaultModel);

      return {
        name: model.name,
        lastUpdate: model.lastUpdate,

        elements: model.elements.map(e => e.serialize()),
        questions: model.questions.map(e => e.serialize()),
        pages: model.pages.map(e => e.serialize())
      };
    },
    handleSubmit(values, { props, resetForm }) {
      const { mapping, route, params } = props;
      delete values.lastUpdate;

      const elements = values.elements.map(element => ({ ...element, key: undefined }));
      const pages = values.pages.map(pages => ({ ...pages, key: undefined }));
      const questions = values.questions.map(question => ({ ...question, key: undefined }));

      if (route.edit) {
        mapping.update(params.id, { ...values, elements, pages, questions });
      } else {
        mapping.create({ ...values, elements, pages, questions });
      }
      resetForm(values);
    }
  })(observer(MappingManage))
);
