import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';

import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import Input from 'components/input/input';
import Checkbox from 'components/checkbox/checkbox';

import {
  timeFormatter,
  timeFieldFormatter,
  applySortingServerSide,
  isActiveToTextFormatter,
  deletableFormatter
} from 'formatters/tables';

import { translate, HideIf } from 'utils/helpers';
import { computed } from 'mobx';
const t = translate(['common', 'users', 'patients']);

class UsersPage extends Component {
  constructor(props) {
    super(props);
  }

  onDelete = id => (this.isAdminPage ? this.props.admins.delete(id) : this.props.users.delete(id));

  addNewUser = () => browserHistory.push(this.isAdminPage ? `/home/admins/new` : `/home/users/new`);

  onSortClick = property => this.getStore().viewState.applySort(property);

  onPageClick = selected => this.getStore().viewState.changePage(selected);

  onRowClick = row => {
    return {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.nodeName !== 'IMG') {
          browserHistory.push(
            this.isAdminPage ? `/home/admins/${row.id}/edit` : `/home/users/${row.id}/edit`
          );
        }
      }
    };
  };

  getStore(props = this.props) {
    if (props.route.admin) {
      return props.admins;
    }
    return props.users;
  }

  @computed
  get searchIsOpen() {
    return this.getStore().viewState.openState.value;
  }

  @computed
  get isAllowedToFetchUsersData() {
    return this.props.session.isSuperAdmin();
  }

  @computed
  get isAdminPage() {
    return this.props.route.admin;
  }

  isFiltered = () => this.getStore().viewState.isFiltered;

  render() {
    const store = this.getStore();
    const sortFormatter = applySortingServerSide(this.onSortClick, store.viewState.sortState);

    const columns = [
      {
        property: 'isActive',
        header: {
          label: t('isActive'),
          formatters: [sortFormatter],
          props: { style: { width: '135px' } }
        },
        cell: {
          formatters: [isActiveToTextFormatter]
        }
      },
      {
        property: 'username',
        header: {
          label: t('userNameTitle'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'name',
        header: {
          label: t('fullName'),
          formatters: [sortFormatter]
        }
      }
    ];

    !this.isAdminPage &&
      columns.push(
        {
          property: 'orgunits',
          header: {
            label: t('careUnit')
          }
        },
        {
          property: 'hsaId',
          header: {
            label: t('hsaId'),
            formatters: [sortFormatter]
          }
        }
      );
    this.props.session.isDemo &&
      columns.push({
        property: 'expirationDemo',
        header: {
          label: t('expirationDemo'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFieldFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      });
    columns.push(
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        header: {
          label: t('remove')
        },
        cell: {
          formatters: [deletableFormatter(this.onDelete)],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    );

    return (
      <div className="users-page action-footer-padded">
        <NavbarInfo>{this.isAdminPage ? t('listAdminTitle') : t('listTitle')}</NavbarInfo>
        <div className="panel">
          <DataTable
            className="table-fixed table-ellipsed"
            data={store.list}
            columns={columns}
            onRow={this.onRowClick}
            isLoading={store.isListLoading}
            pageState={store.viewState.pageState}
            pageCount={store.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered"
          filterState={store.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="searchByUserName"
              name="value"
              form={store.viewState.userNameState}
              leftText
              placeholder={t('filters.name.userNamePlaceholder')}
              onChange={store.viewState.changeUserName}
              innerRef={node => (this.node = node)}
            />
            <Checkbox
              classNameRoot="form-padding"
              name="value"
              form={store.viewState.userActiveState}
              label={t('filters.isActiveOnly')}
              onChange={store.viewState.changeUserActiveState}
            />
          </FilterView>
        </FiltersPanel>
        <ActionFooter>
          {this.isAllowedToFetchUsersData &&
            !this.isAdminPage && (
              <Button
                action="secondary"
                disabled={this.searchIsOpen}
                color={!this.searchIsOpen ? 'green' : 'steel disabled'}
                onClick={this.props.users.exportExcel}
              >
                {t('export')}
              </Button>
            )}
          <Button
            data-test-id="searchUser"
            action="secondary"
            color="steel"
            onClick={store.viewState.toggleOpen}
          >
            {!this.searchIsOpen ? t('searchUser') : t('closeFilter')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={store.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
          <Button
            data-test-id="addUser"
            disabled={this.searchIsOpen}
            color={!this.searchIsOpen ? 'wisteria' : 'steel disabled'}
            action="primary"
            onClick={this.addNewUser}
          >
            {t('addUser')}
          </Button>
        </ActionFooter>
      </div>
    );
  }
}

export default inject('users', 'admins', 'session')(observer(UsersPage));
