import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';
import BackTo from 'components/backTo/back-to';
import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import Input from 'components/input/input';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';

import {
  timeFormatter,
  applySortingServerSide,
  isActiveToTextFormatter,
  unlinkableFormatter
} from 'formatters/tables';

import { translate, HideIf } from 'utils/helpers';
const t = translate(['common', 'nurses', 'users']);

class NursePage extends Component {
  constructor(props) {
    super(props);
    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.nurses.viewState.sortState
    );

    this.columns = [
      {
        property: 'isActive',
        header: {
          label: t('isActive'),
          formatters: [sortFormatter],
          props: { style: { width: '135px' } }
        },
        cell: {
          formatters: [isActiveToTextFormatter]
        }
      },
      {
        property: 'username',
        header: {
          label: t('userName'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'name',
        header: {
          label: t('fullName'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        header: {
          label: t('remove')
        },
        cell: {
          formatters: [unlinkableFormatter(this.onUnlink)],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    ];
  }
  onSortClick = property => this.getStore().viewState.applySort(property);

  onRowClick = row => {
    return {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.nodeName !== 'IMG') {
          browserHistory.push({
            pathname: `/home/users/${row.id}/edit`,
            search: `?from=nurse&careUnitId=${this.props.params.id}`
          });
        }
      }
    };
  };

  getStore() {
    return this.props.nurses;
  }

  isFiltered = () => this.getStore().viewState.isFiltered;

  onUnlink = (careUnitId, userId) => this.getStore().delete(careUnitId, userId);

  onSortClick = property => this.getStore().viewState.applySort(property);

  onPageClick = selected => this.getStore().viewState.changePage(selected);

  render() {
    const store = this.getStore();
    const careUnit = store.queryRawData.orgunit && store.queryRawData.orgunit.name;

    return (
      <div className="nurse-page action-footer-padded">
        <NavbarInfo
          content={<BackTo title={t('backToCareUnitsList')} to={'/home/care-units/admin'} />}
        >
          {t('listTitle', { careUnit })}
        </NavbarInfo>
        <div className="panel">
          <DataTable
            data={store.list}
            columns={this.columns}
            onRow={this.onRowClick}
            isLoading={store.isListLoading}
            pageState={store.viewState.pageState}
            pageCount={store.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered"
          filterState={store.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="searchByNurseName"
              name="value"
              form={store.viewState.nurseSearchState}
              leftText
              placeholder={t('filters.name.nurseNamePlaceholder')}
              onChange={store.viewState.changeNurseName}
              innerRef={node => (this.node = node)}
            />
          </FilterView>
        </FiltersPanel>
        <ActionFooter>
          <Button
            data-test-id="searchNurse"
            action="secondary"
            color="steel"
            onClick={store.viewState.toggleOpen}
          >
            {t('searchNurse')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={store.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
        </ActionFooter>
      </div>
    );
  }
}

export default inject('nurses')(observer(NursePage));
