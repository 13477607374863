import isPersonnummer from 'is-personnummer';

export const personNumberValidator = (value, model) => {
  if (isPersonnummer(value)) {
    const year = model.personalNumber.substr(0, 4); // YYYY
    const month = model.personalNumber.substr(4, 2); // MM
    const day = model.personalNumber.substr(6, 2); // DD
    const sex = +model.personalNumber.substr(11, 1);
    const date = new Date(+year, +month - 1, +day); // compute date (remove 1 from month since JS have 0 month)
    const male = sex === 0 ? false : sex % 2 !== 0; // detect male\female by dividing by two;

    return { isValid: true, date, male };
  }

  return { isValid: false };
};
