import { Model } from './utils';
import { observable } from 'mobx';
import { serializable, alias, identifier, list, object } from 'serializr';

export class ElementsModel extends Model {
  @serializable key;

  @serializable
  @observable
  id;

  @serializable
  @observable
  desc;

  @serializable
  @observable
  term;
}

export class PagesModel extends Model {
  @serializable key;

  @serializable
  @observable
  id;

  @serializable
  @observable
  desc;

  @serializable
  @observable
  term;
}

export class QuestionsModel extends Model {
  @serializable key;

  @serializable
  @observable
  id;

  @serializable
  @observable
  desc;

  @serializable
  @observable
  term;

  @serializable
  @observable
  complex = false;
}

export default class MappingModel extends Model {
  @serializable(alias('_id'))
  id;

  @serializable
  @observable
  name;

  @serializable
  @observable
  lastUpdate;

  @serializable
  @observable
  version;

  @serializable(list(object(ElementsModel)))
  @observable
  elements = [];

  @serializable(list(object(PagesModel)))
  @observable
  pages = [];

  @serializable(list(object(QuestionsModel)))
  @observable
  questions = [];

  deletable = true;
}
