import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, action } from 'mobx';
import { Async } from 'react-select';

import { translate } from 'utils/helpers';
const t = translate(['common', 'surveys']);

@observer
export default class TermNameSelect extends Component {
  @action
  changeTerm(term, value) {
    term.termId = value.id;
    term.termName = value.name;
    term.termType = value.type;
  }

  @computed
  get filtered() {
    return new Set(this.props.model.emrData.map(term => term.viewModel.termId));
  }

  onChange = value => {
    if (this.props.onChange) {
      return this.props.onChange(value);
    }

    this.changeTerm(this.props.term, value);
  };

  filterBlocked = option => !this.filtered.has(option.id);

  render() {
    const { terms, term } = this.props;

    return (
      <Async
        labelKey="name"
        ignoreAccents={false}
        autosize={false}
        valueKey="id"
        searchPromptText={t('typeToSearch')}
        noResultsText={t('noResults')}
        multi={false}
        cache={false}
        autoload={false}
        loadOptions={terms.fetchTerms}
        placeholder={term.termName}
        backspaceRemoves={false}
        filterOption={this.filterBlocked}
        clearable={false}
        onChange={this.onChange}
      />
    );
  }
}
