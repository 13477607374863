import { BaseService } from '../base-service';

function prepareFormData(data) {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
}

export class TemplatesService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/templates' });
  }

  query(params = {}, cancelToken) {
    params = { ...params };

    return this.get('/', { params, cancelToken });
  }

  fetch() {
    return this.get('');
  }

  fetchOne(id) {
    return this.get('/' + id);
  }

  update(id, code, data) {
    return this.put('/' + id, {
      params: { key: code },
      data: prepareFormData(data)
    });
  }

  create(key, data) {
    return this.post('/', {
      params: { key },
      data: prepareFormData(data)
    });
  }

  delete(id) {
    return this.del('/' + id);
  }

  linkFromProgenitor(templateId, progenitorId) {
    return this.patch(`/${templateId}/progenitors/${progenitorId}/link`);
  }

  unlinkFromProgenitor(templateId, progenitorId) {
    return this.patch(`/${templateId}/progenitors/${progenitorId}/unlink`);
  }
}
