import axios from 'axios';
import { observable, action, computed, values } from 'mobx';
import { deserialize, update } from 'serializr';
import { browserHistory } from 'react-router';

import { surveysService, websocketService, restClient } from 'services/transport-layer';
import SurveyModel, { surveyStatusMap, EMRModel, statusDeserializer } from 'models/survey';
import { errorHandler } from './mixins';
import UIStore, { createFilterStore } from './ui';
import SessionStore from './session';
import { encodeUrlQuery } from 'utils/helpers';

class SurveysStore {
  @observable surveys = observable.map({});
  @observable meta = { total: [], filtered: 0 };

  isListLoading = observable.box(false);
  viewState = createFilterStore('surveys', this.fetchSurveys);

  constructor() {
    SessionStore.addOnLogoutHook(this.viewState.clearAll);
    restClient.addErrorHandler(this.handleForbiddenAccess);
  }

  handleForbiddenAccess = response => {
    if (!SessionStore.currentUser) {
      return;
    }

    if (response.status === 403) {
      browserHistory.replace(SessionStore.isAdmin() ? '/login/admin' : '/login');
    }
  };

  isSurveyEditModeAllowed(status) {
    return status !== surveyStatusMap.qa;
  }

  @action.bound
  initializeWs() {
    websocketService.connect(SessionStore.getToken).onMsg(this.onWSMessage);
  }

  @action.bound
  onWSMessage(data) {
    if (data.type !== 'survey' || UIStore.isInTransition) {
      return;
    }

    if (UIStore.isListView()) {
      this.fetchSurveys(true);
      return;
    }

    if (this.hasGuid(data.GUID) && SessionStore.isSecretary()) {
      this.fetchSurveyById(data._id);
    }
  }

  @action.bound
  fetchSurveys(silent = false) {
    if (!silent) {
      this.isListLoading.set(true);
    }

    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    this.cancelToken = axios.CancelToken.source();

    return surveysService
      .query(this.viewState.requestQuery, this.cancelToken.token)
      .then(this.resetSurveys)
      .catch(error => {
        if (axios.isCancel(error)) {
          // just ignore canceling
          return;
        }

        UIStore.showError(error);
        this.isListLoading.set(false);
      });
  }

  @action.bound
  fetchSurveyById(id) {
    if (this.cancelTokenFetchOne) {
      this.cancelTokenFetchOne.cancel();
    }

    this.cancelTokenFetchOne = axios.CancelToken.source();

    return surveysService
      .fetchOne(id, this.cancelTokenFetchOne.token)
      .then(model => {
        return surveysService
          .fetchSurveyBody(id)
          .then(template => {
            model.primaryData = {};
            model.primaryData.sections = template.assessment.sections;
            return this.addOrUpdateSurvey(model);
          })
          .then(() => Promise.resolve(model));
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          // just ignore canceling
          return;
        }

        UIStore.showError(error);
        return Promise.reject(error);
      });
  }

  @action.bound
  fetchSurveyEMRDataById(model) {
    return surveysService
      .fetchSurveyEmr(model.id)
      .then(
        ({ data }) =>
          (model.emrData = SurveysStore.deserializeEmrData(data).map(term =>
            deserialize(EMRModel, term)
          ))
      )
      .catch(error => {
        if (axios.isCancel(error)) {
          // just ignore canceling
          return;
        }

        UIStore.showError(error);
        return Promise.reject(error);
      });
  }

  @action.bound
  setSurveyToQA(id) {
    UIStore.setToTransition();
    surveysService
      .startQa(id)
      .then(
        action(() => {
          const model = this.getSurvey(id);
          model.status = surveyStatusMap.qa;
          browserHistory.push(`/home/surveys/${id}/${model.guid}/edit`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  @action.bound
  archiveSurvey(id) {
    UIStore.setToTransition();
    surveysService
      .archiveById(id)
      .then(
        action(() => {
          const model = this.getSurvey(id);
          model.status = surveyStatusMap.archived;
          browserHistory.push('/home/surveys');
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  @action.bound
  retrySending(id) {
    UIStore.setToTransition();
    surveysService
      .retrySending(id)
      .then(
        action(() => {
          browserHistory.push('/home/surveys');
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  static serializeEmrData(emrModels = []) {
    return emrModels.map(model => {
      let value;

      switch (typeof model.value) {
        case 'string':
          if (model.value !== '') value = +model.value;

          break;
        case 'number':
          value = model.value;
          break;
        default:
          value = undefined;
      }

      return {
        value,
        note: model.note,
        valueTerms: model.valueTerms.filter(Boolean),
        term: {
          id: model.termId,
          type: model.termType,
          name: model.termName,
          desc: model.termDesc
        }
      };
    });
  }

  static deserializeEmrData(rawEmrData = []) {
    return rawEmrData.map(term => ({
      note: term.note,
      value: term.value,
      valueTerms: term.valueTerms || [],
      termId: term.term.id,
      termName: term.term.name,
      termDesc: term.term.desc,
      termType: term.term.type
    }));
  }

  @action.bound
  saveSurveyEmr(id) {
    UIStore.setToTransition();
    const model = this.getSurvey(id);
    model.viewModel.submit();
    model.emrData.forEach(emrModel => emrModel.viewModel.submit());
    const emrData = SurveysStore.serializeEmrData(model.emrData);

    surveysService
      .saveToEmr(id, emrData)
      .then(
        action(() => {
          browserHistory.push('/home/surveys');
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  @action.bound
  sendSurveyToEmr(id) {
    const model = this.getSurvey(id);
    model.viewModel.submit();
    model.emrData.forEach(emrModel => emrModel.viewModel.submit());

    UIStore.setToTransition();
    surveysService
      .sendToEmr(id, SurveysStore.serializeEmrData(model.emrData))
      .then(
        action(() => {
          const model = this.getSurvey(id);
          model.status = surveyStatusMap.readyForEMR;
          browserHistory.push('/home/surveys');
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  @action.bound
  archive(id) {
    UIStore.setToTransition();
    surveysService
      .archive(id)
      .then(
        action(() => {
          const model = this.getSurvey(id);
          model.status = surveyStatusMap.archived;
          browserHistory.push(`/home/surveys`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  @action.bound
  confirmSITHS(id) {
    UIStore.setToTransition();
    surveysService
      .confirmSITHS(id)
      .then(
        action(() => {
          const model = this.getSurvey(id);
          model.status = surveyStatusMap.waitingForQa;
          browserHistory.push('/home/surveys');
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  @action.bound
  defectize(id) {
    UIStore.setToTransition();
    surveysService
      .defectize(id)
      .then(
        action(() => {
          const model = this.getSurvey(id);
          model.status = surveyStatusMap.defective;
          browserHistory.push('/home/surveys');
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  @action.bound
  unarchive(id) {
    UIStore.setToTransition();
    surveysService
      .unarchive(id)
      .then(
        action(() => {
          const model = this.getSurvey(id);
          model.status = surveyStatusMap.waitingForQa;
          browserHistory.push('/home/surveys');
          UIStore.setOutOfTransition();
        })
      )
      .catch(SurveysStore.genericErrorHandler);
  }

  @action.bound
  exportExcel() {
    const query = Object.assign({}, this.viewState.requestQuery, {
      token: SessionStore.getToken()
    });
    delete query['page[number]'];
    delete query['page[size]'];
    delete query['sort'];
    window.open(`/api/v2/surveys/report?${encodeUrlQuery(query, false)}`, '_blank');
  }

  @action.bound
  deleteEmr(surveyId, termId) {
    const survey = this.getSurvey(surveyId);
    survey.viewModel.emrData = survey.viewModel.emrData.filter(emr => emr.termId !== termId);
  }

  @action.bound
  addToEmr(surveyId, termModel) {
    const survey = this.getSurvey(surveyId).viewModel;
    const newArray = survey.emrData.slice();

    newArray.unshift(deserialize(EMRModel, termModel));
    survey.emrData = newArray;
  }

  @action.bound
  resetSurveys({ data, meta }) {
    const newSurveys = {};
    data.forEach(model => {
      if (this.surveys.has(model._id)) {
        this.addOrUpdateSurvey(model);
        newSurveys[model._id] = this.getSurvey(model._id);
        return;
      }

      newSurveys[model._id] = deserialize(SurveyModel, model);
    });

    this.surveys = observable.map(newSurveys);
    this.meta.total = meta.total;
    this.meta.filtered = meta.filtered;
    this.isListLoading.set(false);
    this.cancelToken = null;
  }

  @action.bound
  addOrUpdateSurvey(model) {
    if (this.surveys.has(model._id)) {
      update(this.getSurvey(model._id), model);
    } else {
      this.surveys.set(model._id, deserialize(SurveyModel, model));
    }
  }

  @action.bound
  resetSurveyEmr(id) {
    const model = this.getSurvey(id);

    model.viewModel.reset();
    model.emrData.forEach(emrModel => emrModel.viewModel.reset());
  }

  @computed
  get list() {
    return values(this.surveys);
  }

  @computed
  get statusesReport() {
    const report = {};
    this.meta.total.forEach(item => {
      report[statusDeserializer(item.status)] = item.count;
    });

    return report;
  }

  @computed
  get guids() {
    return values(this.surveys).map(survey => survey.guid);
  }

  getSurvey(id) {
    return this.surveys.get(id);
  }

  getPageCount = () => this.meta.filtered;

  hasGuid(id) {
    return this.guids.includes(id);
  }

  static genericErrorHandler(error) {
    console.error(error);
    UIStore.showError(error);
    UIStore.setOutOfTransition();
  }
}

const store = new SurveysStore();

const guardSurveyStatusTransition = (model, modes) => {
  if (modes.location.pathname.includes('edit')) {
    return store.isSurveyEditModeAllowed(model.status);
  }
  return false;
};

export function surveyDetailsResolver(nextState, replace, callback) {
  store.initializeWs();

  UIStore.setToTransition();
  store
    .fetchSurveyById(nextState.params.id)
    .then(() => {
      const model = store.getSurvey(nextState.params.id);
      if (guardSurveyStatusTransition(model, nextState)) {
        return Promise.reject(`Can't open edit mode with this status: ${model.status}`);
      }
    })
    .then(() => {
      callback();
      UIStore.setOutOfTransition();
    })
    .catch(errorHandler(...arguments));
}

export function surveyEMRDataResolver(nextState, replace, callback) {
  const survey = store.getSurvey(nextState.params.id);
  store.initializeWs();

  UIStore.setToTransition();
  new Promise(resolve => {
    if (survey) {
      return resolve(survey);
    }
    return resolve(store.fetchSurveyById(nextState.params.id));
  })
    .then(survey => {
      const model = survey && store.getSurvey(nextState.params.id);
      if (guardSurveyStatusTransition(model, nextState)) {
        return Promise.reject(`Can't open edit mode with this status: ${model.status}`);
      }
      return model;
    })
    .then(model => store.fetchSurveyEMRDataById(model))
    .then(() => {
      callback();
      UIStore.setOutOfTransition();
    })
    .catch(errorHandler(...arguments));
}

export function surveysResolver(nextState, replace, callback) {
  store.initializeWs();

  if (SessionStore.isAdmin() && !SessionStore.isSuperAdmin()) {
    replace('/home/care-units/admin');
    callback();
    return;
  }

  callback();
  store.fetchSurveys().catch(errorHandler(...arguments));
}

export default store;
