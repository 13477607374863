import { Model } from './utils';
import { observable } from 'mobx';
import { serializable, alias, object } from 'serializr';

export class AddressModel extends Model {
  @serializable
  @observable
  postcode;
  @serializable
  @observable
  city;
  @serializable
  @observable
  street;
  @serializable
  @observable
  number;
  @serializable
  @observable
  CO; // additional information
}

export default class PatientModel extends Model {
  @serializable(alias('_id'))
  id;
  @serializable
  @observable
  fullName;
  @serializable(alias('patientPlace'))
  @observable
  patientPlace;
  @serializable
  @observable
  lastUpdate;
  deletable = true;
  @serializable(alias('orgunitId'))
  @observable
  careUnitId;
  @serializable
  @observable
  careUnitName;
  @serializable
  @observable
  personalNumber;
  @serializable
  @observable
  isSpareNumber;
  @serializable(object(AddressModel))
  @observable
  address = new AddressModel({});
}
