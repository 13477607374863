import { observable, action, when, computed } from 'mobx';
import { deserialize } from 'serializr';
import { browserHistory } from 'react-router';
import moment from 'moment';

import UIStore from './ui';
import { authService, restClient } from 'services/transport-layer';
import UserModel from 'models/user';

import { encodeUrlQuery, generateGuid, getRedirectParams } from 'utils/helpers';

const LS_CURRENT_USER = 'sc-current-user';
const LS_TAB_SESSION = 'sc-tab-guid';

class SessionStore {
  @observable currentUser = null;
  @observable isLogged = false;

  @observable IDLE_TIMEOUT = 0; // depends on experation time after login
  @observable CONFIRMATION_TIMEOUT = 30000; // ms (30 seconds)

  @computed
  get RENEW_TIMEOUT() {
    return this.IDLE_TIMEOUT + this.CONFIRMATION_TIMEOUT + 15000; // ms (15 seconds)
  }

  @computed
  get isDemo() {
    return getRedirectParams(window.location.hostname).subDomain === 'demo';
  }

  idleTimer;
  confirmationTimer;
  autoRenewTimer;

  onLogoutHooks = [];

  constructor() {
    this.checkLogin();
    this.registerTab();
    window.addEventListener('storage', this.trackLogon);
  }

  trackLogon = event => {
    if (event.key === LS_TAB_SESSION) {
      if (event.newValue !== this.currentTabGuid) {
        this.logout(true);
      }
    }
  };

  registerTab = () => {
    this.currentTabGuid = generateGuid();
    localStorage.setItem(LS_TAB_SESSION, this.currentTabGuid);
  };

  startTrackingIdle() {
    // Subtract 1 minute from expirationDate
    // Use this time to run timers and track user idle time
    this.IDLE_TIMEOUT = moment(this.currentUser.expirationDate)
      .subtract(1, 'minutes')
      .diff(moment(), 'milliseconds');

    this.restartTimer();
    this.restartRenewInterval();
    document.addEventListener('mousemove', this.restartTimer);
    document.addEventListener('click', this.restartTimer);
    document.addEventListener('scroll', this.restartTimer);
    document.addEventListener('keydown', this.restartTimer);
  }

  stopTrackingIdle() {
    clearTimeout(this.idleTimer);
    clearInterval(this.autoRenewTimer);
    document.removeEventListener('mousemove', this.restartTimer);
    document.removeEventListener('click', this.restartTimer);
    document.removeEventListener('scroll', this.restartTimer);
    document.removeEventListener('keydown', this.restartTimer);
  }

  restartRenewInterval = () => {
    clearInterval(this.autoRenewTimer);
    this.autoRenewTimer = setInterval(this.onRenewToken, this.RENEW_TIMEOUT);
  };

  restartTimer = () => {
    clearTimeout(this.idleTimer);
    clearTimeout(this.confirmationTimer);
    this.idleTimer = setTimeout(this.onIdleLock, this.IDLE_TIMEOUT);
  };

  onIdleLock = () => {
    UIStore.showModal(
      () => this.onRenewToken(),
      () => {
        UIStore.closeModal();
        this.logout();
      },
      'Are you still here ?'
    );

    this.confirmationTimer = setTimeout(this.logout, this.CONFIRMATION_TIMEOUT);
  };

  onRenewToken = () => {
    if (this.isAdmin()) {
      return authService
        .renewAsAdmin()
        .then(this.autoRenewSuccess)
        .catch(() => this.logout());
    }
    return authService
      .renew()
      .then(this.autoRenewSuccess)
      .catch(() => this.logout());
  };

  // Auto-logout from all tabs
  attachAuthAutoLogout = () => window.addEventListener('storage', this.handleAuthLogout, false);
  deattachAuthAutoLogout = () => window.removeEventListener('storage', this.handleAuthLogout);
  handleAuthLogout = ({ key, newValue }) => key === LS_CURRENT_USER && !newValue && this.logout();

  addOnLogoutHook(cb) {
    this.onLogoutHooks.push(cb);
  }

  attachRequestsHandlers() {
    restClient.addBeforeRequestHandler(this.onBeforeRequest);
    restClient.addBeforeRequestHandler(this.onBeforeRequestLocalisation);
    restClient.addErrorHandler(this.onErrorResponse);
  }

  dettachRequestHandlers() {
    restClient.removeBeforeRequestHandler(this.onBeforeRequest);
    restClient.removeErrorHandler(this.onErrorResponse);
  }

  isLoggedIn() {
    return this.isLogged;
  }

  onBeforeRequest = (url, options) => {
    options.headers.token = this.currentUser.token;
  };

  onBeforeRequestLocalisation = (url, options) => {
    options.headers.In = UIStore.currentLang;
  };

  onErrorResponse = response => {
    if (response.status === 401) {
      this.logout();
    }
  };

  @action
  checkLogin() {
    const user = localStorage.getItem(LS_CURRENT_USER);

    if (user) {
      this.isLogged = true;
      this.currentUser = deserialize(UserModel, JSON.parse(user));
      this.attachAuthAutoLogout();
      this.attachRequestsHandlers();

      // when session is active, /renew since open app is an activity
      if (moment(this.currentUser.expirationDate).diff(moment()) > 0) {
        this.onRenewToken().then(() => this.startTrackingIdle());
      } else {
        this.logout();
      }
    } else {
      this.isLogged = false;
      this.currentUser = null;
      this.deattachAuthAutoLogout();
      this.dettachRequestHandlers();
      this.stopTrackingIdle();
    }
  }

  @action.bound
  logout(silent = false) {
    const url = this.isAdmin() ? '/login/admin' : '/login';

    this.isLogged = false;
    this.currentUser = null;

    this.deattachAuthAutoLogout();
    this.stopTrackingIdle();
    this.dettachRequestHandlers();

    if (silent) {
      browserHistory.replace(url);
    } else {
      localStorage.removeItem(LS_CURRENT_USER);
      browserHistory.replace(url);
      this.onLogoutHooks.forEach(cb => cb());
      window.location.reload();
    }
  }

  sitshSuccess(sessionId) {
    return authService
      .loginAsSithsEnd({ sessionId })
      .then(this.loginSuccess)
      .catch(error => {
        UIStore.showError(error);
        browserHistory.replace('/login');
      });
  }

  sitshAdminSuccess(sessionId) {
    return authService
      .loginAsAdminSithsEnd({ sessionId })
      .then(this.loginSuccess)
      .catch(error => {
        UIStore.showError(error);
        browserHistory.replace('/login/admin');
      });
  }

  login(model, isAdminRoute, asSiths = false) {
    if (asSiths) {
      return authService
        .loginAsSithsStart({
          callbackUrl: window.location.origin + (isAdminRoute ? '/autologinAdmin' : '/autologin')
        })
        .then(({ netid }) => (window.location = netid.endsWith('/') ? netid : netid + '/'))
        .catch(UIStore.showError);
    }

    if (isAdminRoute) {
      return authService
        .loginAsAdmin(model.serialize())
        .then(this.loginSuccess)
        .catch(UIStore.showError);
    }

    return authService
      .login(model.serialize())
      .then(this.loginSuccess)
      .catch(UIStore.showError);
  }

  isAdmin() {
    return this.checkPermission('admin') || this.checkPermission('super');
  }

  isSuperAdmin() {
    return this.checkPermission('super');
  }

  isSecretary() {
    return this.checkPermission('secretary');
  }

  checkPermission(permission) {
    if (this.currentUser) {
      return this.currentUser.actions.includes(permission);
    }

    return false;
  }

  getToken = () => {
    if (this.currentUser) {
      return this.currentUser.token;
    }

    return null;
  };

  validateHelpTicket(token) {
    return authService.checkHelp(token);
  }

  makeStorageUrl(url) {
    return `/${url}?${encodeUrlQuery({ token: this.getToken() })}`;
  }

  @action
  autoRenewSuccess = response => {
    this.currentUser = deserialize(UserModel, response);
    localStorage.setItem(LS_CURRENT_USER, JSON.stringify(this.currentUser.serialize()));
  };

  @action
  loginSuccess = response => {
    this.registerTab();

    this.currentUser = deserialize(UserModel, response);
    this.isLogged = true;

    localStorage.setItem(LS_CURRENT_USER, JSON.stringify(this.currentUser.serialize()));

    this.attachAuthAutoLogout();
    this.attachRequestsHandlers();

    if (this.isAdmin() && !this.isSuperAdmin()) {
      browserHistory.replace('/home/care-units/admin');
    } else {
      browserHistory.replace('/home/surveys');
    }

    this.startTrackingIdle();
  };
}

export default new SessionStore();
