import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Spinner from 'react-spinkit';
import Progress from 'react-progress-bar-plus';

import './ui-spinner.css';

@inject('ui')
@observer
export default class extends Component {
  render() {
    const { ui } = this.props;

    if (ui.isInTransition) {
      return (
        <div className="global-spinner">
          <Spinner name="double-bounce" fadeIn="none" />
        </div>
      );
    }

    return <Progress autoIncrement={true} spinner={false} percent={ui.isInSync ? 100 : 40} />;
  }
}
