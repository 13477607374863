export function hideWithFade(uiState) {
  return {
    opacity: (70 - uiState.pageYOffset) / 70,
    transform: `translateY(-${uiState.pageYOffset * 0.5}px`
  };
}

// http://www.joshondesign.com/2013/03/01/improvedEasingEquations
function easeOutElastic(t) {
  const p = 0.5; // 0.3 -> 0.5 for smooth
  return Math.pow(2, -10 * t) * Math.sin((t - p / 4) * (2 * Math.PI) / p) + 1;
}

export function runOutAnimation(state, onEnd, translateEnd = 432) {
  let start;

  const render = time => {
    let progress;

    if (!start) {
      start = time;
    }

    progress = (time - start) / 200;
    progress = progress >= 1 ? 1 : progress;

    if (progress <= 1) {
      state.translate = state.translate && translateEnd * (1 - progress);
      if (progress !== 1) {
        window.requestAnimationFrame(render);
      } else {
        onEnd();
      }
    }
  };

  window.requestAnimationFrame(render);
}

export function runInAnimation(state, onEnd, translateEnd = 532) {
  let start;

  const render = time => {
    if (!start) {
      start = time;
    }

    const progress = (time - start) / 800;

    if (progress <= 1) {
      state.translate = translateEnd * easeOutElastic(progress);
      window.requestAnimationFrame(render);
    } else {
      onEnd();
    }
  };

  window.requestAnimationFrame(render);
}
