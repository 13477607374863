import React, { PureComponent } from 'react';
import { Table, AutoSizer } from 'react-virtualized';

import './data-table.css';

export default class DataTableVirtualized extends PureComponent {
  rowGetter = ({ index }) => this.props.data[index];

  render() {
    return (
      <div className="reactvirtualized-container">
        <AutoSizer>
          {({ width, height }) => (
            <Table
              className={this.props.className}
              width={width}
              height={height}
              headerHeight={45}
              rowHeight={155}
              rowCount={this.props.data.length}
              rowGetter={this.rowGetter}
              overscanRowCount={10}
            >
              {this.props.children}
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}
