import { observable } from 'mobx';
import { serializable, alias, list } from 'serializr';
import { Model } from './utils';

export class SecretaryModel extends Model {
  @serializable(alias('_id'))
  id;
  @serializable
  @observable
  name;
  @serializable
  @observable
  email;
  @serializable username;
  @serializable
  @observable
  personalNumber;
  @serializable
  @observable
  hsaId;
  @serializable(list())
  @observable
  orgunitIds;
}

export class AdminModel extends Model {
  @serializable(alias('_id'))
  id;
  @serializable
  @observable
  name;
  @serializable
  @observable
  email;
  @serializable username;
}
