import { BaseService } from '../base-service';

export class AuthService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/auth' });
  }

  login(data = {}) {
    return this.post('/secretary', { data });
  }

  loginAsAdmin(data = {}) {
    return this.post('/admin', { data });
  }

  loginAsSithsStart(data = {}) {
    return this.post('/siths/start', { data });
  }

  loginAsSithsEnd(data = {}) {
    return this.post('/siths/secretary', { data });
  }

  loginAsAdminSithsEnd(data = {}) {
    return this.post('/siths/admin', { data });
  }

  renew() {
    return this.post('/secretary/renew');
  }

  renewAsAdmin() {
    return this.post('/admin/renew');
  }

  checkHelp(token) {
    return this.post('/helptoken', { data: { token } });
  }
}
