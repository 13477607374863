import { Model } from './utils';
import { observable } from 'mobx';
import { serializable, list, alias, object } from 'serializr';
import CareUnitModel from './care-unit';

export default class LoggedUserModel extends Model {
  @serializable(alias('_id'))
  id;

  @serializable
  @observable
  name;

  @serializable(list())
  actions;

  @serializable token;
  @serializable(alias('expiration_date'))
  expirationDate;
}

export class UserModel extends Model {
  @serializable(alias('_id'))
  id;

  @serializable careUnitId;

  @serializable
  @observable
  isActive;

  @serializable
  @observable
  name;

  @serializable
  @observable
  username;

  @serializable
  @observable
  email;

  @serializable(alias('noPasswordNotification'))
  @observable
  isNotSendEmail;

  @serializable
  @observable
  lastUpdate;

  @serializable
  @observable
  jobTitle;

  @serializable
  @observable
  personalNumber;

  @serializable
  @observable
  expirationDemo;

  @serializable
  @observable
  hsaId;

  @serializable
  @observable
  sithsId;

  deletable = true;

  @serializable(alias('orgunits', list(object(CareUnitModel))))
  @observable
  careUnits = [];

  @serializable(list())
  orgunitIds = [];

  @serializable
  @observable
  password;

  @observable confirmPassword;

  get orgunits() {
    return this.careUnits.map(careUnit => careUnit.name).join(', ');
  }

  get careUnitsIds() {
    return this.careUnits.map(cu => cu.name);
  }
}
