import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const axiosConfig = {
  baseURL: '/api/v2'
};

export class RestClient {
  axios;

  errorMiddlewares;
  beforeRequestMiddlewares;

  constructor() {
    this.axios = axios.create(axiosConfig);
    this.errorMiddlewares = new Set();
    this.beforeRequestMiddlewares = new Set();
  }

  get(url, options = {}) {
    return this.request('get', url, options);
  }

  post(url, options = {}) {
    return this.request('post', url, options);
  }

  patch(url, options = {}) {
    return this.request('patch', url, options);
  }

  put(url, options = {}) {
    return this.request('put', url, options);
  }

  del(url, options = {}) {
    return this.request('delete', url, options);
  }

  link(url, options = {}) {
    return this.request('link', url, options);
  }

  unlink(url, options = {}) {
    return this.request('unlink', url, options);
  }

  addBeforeRequestHandler(handler) {
    this.beforeRequestMiddlewares.add(handler);
  }

  addErrorHandler(handler) {
    this.errorMiddlewares.add(handler);
  }

  removeBeforeRequestHandler(handler) {
    this.beforeRequestMiddlewares.delete(handler);
  }

  removeErrorHandler(handler) {
    this.errorMiddlewares.delete(handler);
  }

  request(method, url, options = {}) {
    const opts = this.cloneHeaders(options);
    const { body: data, headers, params, ...rest } = opts;

    this.beforeRequestMiddlewares.forEach(m => m(url, opts));

    return this.axios
      .request({ url, method, data, headers, params, ...rest })
      .then(response => response.data)
      .catch(this.requestErrorHandler.bind(this));
  }

  /**
   * Process error and structure it to some pretty format
   * One gotcha - if you canceled request, we just throw original axios error

   */
  requestErrorHandler(error) {
    if (error.response) {
      console.error(error.response);

      // delegate to middlewares
      this.errorMiddlewares.forEach(m => m(error.response));

      if (error.response.headers['x-swipecare-backend']) {
        return Promise.reject({
          messageId:
            (error.response.data || {}).messageId || error.response.data || 'unexpected_error',
          message: (error.response.data || {}).message || error.response.data || 'Unknown error'
        });
      }
      return Promise.reject({
        messageId: 'unexpected_error'
      });
    }

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    if (!error.response) {
      return Promise.reject({ messageId: 'offline_error' });
    }

    return Promise.reject({ messageId: 'unexpected_error' });
  }

  cloneHeaders(options = {}) {
    return Object.assign({}, { headers: {} }, options);
  }
}
