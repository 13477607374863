import { Model } from './utils';
import { observable } from 'mobx';
import {
  serializable,
  alias,
  map,
  custom,
  object,
  list,
  identifier,
  date,
  deserialize
} from 'serializr';

class DoctorSurveyModel extends Model {
  @serializable name;
  @serializable speciality;
}

class PatientSurveyModel extends Model {
  @serializable name;
  @serializable sex;
}

class UploadedBySurveyModel extends Model {
  @serializable email;
  @serializable name;
  @serializable username;
}

class QABySurveyModel extends Model {
  @serializable email;
  @serializable name;
  @serializable username;
}

class TemplateModel {
  @serializable name;
  @serializable code;
}

class CareUnitModel {
  @serializable code;
  @serializable name;
  @serializable internalId;
}

function safeObjectDeserializer(model) {
  return jsonValue => deserialize(model, jsonValue || {});
}

export class EMRModel extends Model {
  @serializable(identifier())
  @observable
  termId;

  @serializable
  @observable
  termName;

  @serializable
  @observable
  termDesc;

  @serializable
  @observable
  termType;

  @serializable
  @observable
  value;

  @serializable
  @observable
  note;

  @serializable(list())
  @observable
  valueTerms = [];
}

class PrimaryDataModel {
  @serializable(list(custom(() => {}, v => v)))
  @observable
  sections = [];
}

export default class SurveyModel extends Model {
  @serializable(alias('_id'))
  id;

  @serializable(object(CareUnitModel))
  orgunit;

  @serializable(alias('orgunitCode'))
  @observable
  careUnitCode;

  @serializable(alias('orgunitName'))
  @observable
  careUnitName;

  @serializable(alias('orgunitId'))
  @observable
  careUnitId;

  @serializable(alias('orgunitInternalId'))
  @observable
  careUnitInternalId;

  @serializable(alias('GUID'))
  @observable
  guid;

  @serializable(object(TemplateModel))
  @observable
  template;

  @serializable
  @observable
  lastUpdate;

  @serializable
  @observable
  templateCode;

  @serializable
  @observable
  templateId;

  @serializable
  @observable
  error;

  @serializable
  @observable
  duration;

  // our backend is so smooth. it can just not send required fields...
  @serializable
  @observable
  visitDate = null;

  @serializable(map())
  @observable
  source;
  @serializable(date())
  @observable
  uploaded;

  @serializable(list(object(EMRModel)))
  @observable
  emrData = [];

  @serializable(custom(statusSerializer, statusDeserializer))
  @observable
  status;

  @serializable
  @observable
  uploadedBy;

  @serializable
  @observable
  qaBy;

  // use custom deserialization since, in some cases and on some models null values comes...
  // it related to issue when survey is on status RequiringAttention and than goes to WaitingForQA, fields become null
  @serializable(custom(value => {}, safeObjectDeserializer(PatientSurveyModel)))
  @observable
  patient = {};

  @serializable(custom(value => {}, safeObjectDeserializer(DoctorSurveyModel)))
  @observable
  doctor = {};

  @serializable(object(PrimaryDataModel))
  @observable
  primaryData = new PrimaryDataModel({});
}

export const surveyStatusMap = {
  waitingForQa: 'waitingForQa',
  qa: 'QA',
  imported: 'imported',
  importGaveUp: 'importGaveUp',
  requiringAttention: 'requiringAttention',
  readyForEMR: 'readyForEMR',
  finished: 'finished',
  requireSITHSConfirmation: 'requireSITHSConfirmation',
  defective: 'defective',
  archived: 'archived',
  unknownStatus: 'unknownStatus'
};

export function statusSerializer(value) {
  switch (value) {
    case surveyStatusMap.waitingForQa:
      return 'Waiting4QA';
    case surveyStatusMap.qa:
      return 'QA';
    case surveyStatusMap.imported:
      return 'Imported';
    case surveyStatusMap.importGaveUp:
      return 'ImportGaveUp';
    case surveyStatusMap.readyForEMR:
      return 'Ready4EMR';
    case surveyStatusMap.requiringAttention:
      return 'RequireAttention';
    case surveyStatusMap.finished:
      return 'Finished';
    case surveyStatusMap.requireSITHSConfirmation:
      return 'RequireSITHSConfirmation';
    case surveyStatusMap.defective:
      return 'Defective';
    case surveyStatusMap.archived:
      return 'Archived';
    default:
      return surveyStatusMap.unknownStatus;
  }
}

export function statusDeserializer(value) {
  switch (value) {
    case 'Waiting4QA':
      return surveyStatusMap.waitingForQa;
    case 'QA':
      return surveyStatusMap.qa;
    case 'Imported':
      return surveyStatusMap.imported;
    case 'ImportGaveUp':
      return surveyStatusMap.importGaveUp;
    case 'Ready4EMR':
      return surveyStatusMap.readyForEMR;
    case 'RequireAttention':
      return surveyStatusMap.requiringAttention;
    case 'Finished':
      return surveyStatusMap.finished;
    case 'RequireSITHSConfirmation':
      return surveyStatusMap.requireSITHSConfirmation;
    case 'Defective':
      return surveyStatusMap.defective;
    case 'Archived':
      return surveyStatusMap.archived;
    default:
      return surveyStatusMap.unknownStatus;
  }
}
