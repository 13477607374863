import React from 'react';
import { userService } from 'services/transport-layer';

import SessionStore from './session';
import UIStore from './ui';
import GenericErrorPage from 'pages/generic-error';
import { translate } from 'utils/helpers';
import { getRedirectParams } from 'utils/helpers';

const t = translate(['login']);

export function recoveryRouteHandler(nextState, replace, callback) {
  if (SessionStore.isLoggedIn()) {
    replace('/home');
    callback();
    UIStore.setOutOfTransition();
    UIStore.showError({ messageId: 'errors.youCantOpenRecoveryWhenLoggedIn' }, t);
    return;
  }

  UIStore.setToTransition();
  userService
    .activateTokenForPasswordReset(nextState.params.token)
    .then(() => {
      UIStore.setOutOfTransition();
      callback();
    })
    .catch(error => {
      UIStore.setOutOfTransition();
      replace('/');
      callback();
    });
}

export function requireAuth(nextState, replace) {
  const isAdmin = SessionStore.isAdmin();

  if (!SessionStore.isLoggedIn()) {
    replace({
      pathname: isAdmin ? '/login/admin' : '/login',
      state: { nextPathname: nextState.location.pathname }
    });
  }
}

export function requireUnAuth(nextState, replace) {
  if (SessionStore.isLoggedIn()) {
    replace({
      pathname: '/',
      state: { nextPathname: nextState.location.pathname }
    });
  }
}

export function detectHelpAuth(nextState, replace, callback) {
  const { subDomain, link } = getRedirectParams(window.location.hostname);
  window.location.replace(`${link}/${subDomain}`);
}

export function componentResolver(component, ErrorComponent = GenericErrorPage) {
  return (nextState, cb) =>
    cb(null, nextState.isError ? () => <ErrorComponent error={nextState.error} /> : component);
}

export function errorHandler(nextState, replace, callback) {
  return error => {
    UIStore.setOutOfTransition();
    UIStore.showError(error);

    console.error(error);
    nextState.isError = true;
    nextState.error = error;
    callback();
  };
}
