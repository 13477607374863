import { RestClient } from './client/base';
import { AuthService } from './services/auth/auth';
import { AdminsService } from './services/admins/admins';
import { CareUnitsSmallService } from './services/care-units/care-units-small';
import { CareUnitsService } from './services/care-units/care-units';
import { PatientsService } from './services/care-units/patients';
import { NursesService } from './services/care-units/nurses';
import { SecretaryProfileService } from './services/profiles/secretary-profile';
import { AdminProfileService } from './services/profiles/admin-profile';
import { ReportsService } from './services/reports/reports';
import { SurveysService } from './services/surveys/surveys';
import { TemplatesService } from './services/templates/templates';
import { TermsService } from './services/terms/terms';
import { TrailService } from './services/audit-trail/trail';
import { UsersService } from './services/users/users';
import { WebSocketService } from './services/websocket/websocket';
import { VersionService } from './services/version/version';
import { MappingService } from './services/mapping/mapping';
import { RoutesService } from './services/routes/routes';

const restClient = new RestClient();
const config = { restClient };

const authService = new AuthService(config);
const adminsService = new AdminsService(config);
const careUnitsSmallService = new CareUnitsSmallService(config);
const careUnitsService = new CareUnitsService(config);
const patientsService = new PatientsService(config);
const nursesService = new NursesService(config);
const secretaryProfileService = new SecretaryProfileService(config);
const adminProfileService = new AdminProfileService(config);
const reportsService = new ReportsService(config);
const surveysService = new SurveysService(config);
const templatesService = new TemplatesService(config);
const termsService = new TermsService(config);
const trailService = new TrailService(config);
const userService = new UsersService(config);
const versionService = new VersionService(config);
const mappingService = new MappingService(config);
const routesService = new RoutesService(config);

const websocketService = new WebSocketService();

export {
  restClient,
  authService,
  adminsService,
  careUnitsSmallService,
  careUnitsService,
  patientsService,
  nursesService,
  secretaryProfileService,
  adminProfileService,
  reportsService,
  surveysService,
  templatesService,
  termsService,
  trailService,
  userService,
  versionService,
  websocketService,
  mappingService,
  routesService
};
