import { Model } from './utils';
import { serializable, alias, object } from 'serializr';

class SourceModel {
  @serializable filename;
  @serializable mimetype;
  @serializable size;
}

export default class ReportModel extends Model {
  @serializable(alias('_id'))
  id;
  @serializable username;
  @serializable note;
  @serializable lastUpdate;
  @serializable(object(SourceModel))
  source;
  @serializable username;
}
