import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { browserHistory, Link } from 'react-router';
import { Tooltip } from 'react-tippy';
import cx from 'classnames';

import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import Input from 'components/input/input';

import {
  applySortingServerSide,
  isActiveToTextFormatter,
  timeFormatter,
  deletableFormatter
} from 'formatters/tables';

import { translate, HideIf } from 'utils/helpers';
const t = translate(['common', 'careUnits']);
import { computed } from 'mobx';

import './care-units.css';

const quickLinkCell = id => {
  return (
    <span>
      <Tooltip title={t('nurseList')} arrow="true" distance={20}>
        <Link data-test-id="NurseList" to={`/home/care-units/admin/${id}/nurses`}>
          <img src={require('assets/svg/sc-nurses.svg')} />
        </Link>
      </Tooltip>
      <Tooltip title={t('edit')} arrow="true" distance={20}>
        <Link data-test-id="EditCareUnit" to={`/home/care-units/admin/${id}/edit`}>
          <img src={require('assets/svg/sc-edit.svg')} />
        </Link>
      </Tooltip>
    </span>
  );
};

const checkboxCell = ({ rowData }, { careUnits, ui, session }) => {
  let src = '';
  if (session.isSuperAdmin()) {
    src = rowData.optionalSITHS
      ? require('assets/svg/ic-checkbox-active.svg')
      : require('assets/svg/ic-checkbox-default.svg');
  } else {
    src = rowData.optionalSITHS
      ? require('assets/svg/ic-checkbox-active-disabled.svg')
      : require('assets/svg/ic-checkbox-default.svg');
  }

  return (
    <span>
      <img
        className={cx({ notAllow: !session.isSuperAdmin() })}
        src={src}
        onClick={() => {
          if (ui && !ui.transitionPending && session.isSuperAdmin()) {
            careUnits.switchOptionalSITHS(rowData.id, { optional: !rowData.optionalSITHS });
          }
        }}
      />
    </span>
  );
};

class CareUnitsPage extends Component {
  constructor(props) {
    super(props);

    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.careUnits.viewState.sortState
    );

    this.columns = [
      {
        property: 'isActive',
        header: {
          label: t('isActive'),
          formatters: [sortFormatter],
          props: { style: { width: '135px' } }
        },
        cell: {
          formatters: [isActiveToTextFormatter]
        }
      },
      {
        property: 'name',
        header: {
          label: t('name'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'description',
        header: {
          label: t('description'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'optionalSITHS',
        header: {
          label: t('optionalSITHS'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [(_, dataRow) => checkboxCell(dataRow, this.props)],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'id',
        header: {
          label: t('quickLink')
        },
        cell: {
          formatters: [quickLinkCell, deletableFormatter(this.onDelete)],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    ];
  }

  onDelete = id => this.props.careUnits.delete(id);

  onSortClick = property => this.props.careUnits.viewState.applySort(property);

  onPageClick = selected => this.props.careUnits.viewState.changePage(selected);

  addNewCareUnit = () => browserHistory.push('/home/care-units/admin/new');

  isFiltered = () => this.props.careUnits.viewState.isFiltered;

  @computed
  get searchIsOpen() {
    return this.props.careUnits.viewState.openState.value;
  }

  render() {
    const { careUnits } = this.props;

    return (
      <div className="careUnits-page action-footer-padded">
        <NavbarInfo>{t('listItems')}</NavbarInfo>
        <div className="panel">
          <DataTable
            data={careUnits.list}
            columns={this.columns}
            isLoading={careUnits.isListLoading}
            pageState={careUnits.viewState.pageState}
            pageCount={careUnits.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered"
          filterState={careUnits.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="inpSearchCareUnit"
              name="value"
              form={careUnits.viewState.careUnitSearchState}
              leftText
              placeholder={t('filters.name.careUnitNamePlaceholder')}
              onChange={careUnits.viewState.changeCareUnitSearchState}
              innerRef={node => (this.node = node)}
            />
          </FilterView>
        </FiltersPanel>
        <ActionFooter>
          <Button
            type="button"
            data-test-id="btnSearchCareUnit"
            action="secondary"
            color="steel"
            onClick={careUnits.viewState.toggleOpen}
          >
            {!this.searchIsOpen ? t('searchCareUnit') : t('closeFilter')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button
              type="button"
              action="secondary"
              color="peach"
              onClick={careUnits.viewState.resetFiltered}
            >
              {t('resetFilter')}
            </Button>
          </HideIf>
          <Button
            action="primary"
            disabled={this.searchIsOpen}
            color={!this.searchIsOpen ? 'wisteria' : 'steel disabled'}
            data-test-id="addCareUnit"
            onClick={this.addNewCareUnit}
          >
            {t('addCareUnit')}
          </Button>
        </ActionFooter>
      </div>
    );
  }
}

export default inject('careUnits', 'session', 'ui')(observer(CareUnitsPage));
