import React from 'react';
import { Link } from 'react-router';

import './back-to.css';

export default ({ to, title }) => (
  <div className="back-to">
    <img className="img" src={require('assets/svg/sc-back.svg')} />
    <Link to={to} className="link">
      {title}
    </Link>
  </div>
);
