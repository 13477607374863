import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS, observable, action, autorun, computed } from 'mobx';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { Column } from 'react-virtualized';

import SurveyQuickInfo from 'components/surveyQuickInfo/survey-quick-info';
import DataTable from 'components/dataTable/data-table-virtualized';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import TextArea from 'components/textarea/textarea';
import Input from 'components/input/input';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import QuickLinkSurveyEdit from 'components/surveyQuickLink/survey-quick-link';
import TermNameSelect from 'components/surveyTerm/survey-term-name-select';
import TermId from 'components/surveyTerm/survey-term-id';

import { dateFormat } from 'formatters/dates';
import { translate, HideIf } from 'utils/helpers';
const t = translate(['common', 'surveys']);

import './survey-edit.css';

function createNewTermModel() {
  return {
    termId: 'custom',
    termName: '',
    value: '',
    note: '',
    valueTerms: [],
    termType: 'text'
  };
}

const ReactiveColRenderer = observer(
  ({ dataKey, model, children }) =>
    children ? children(model, dataKey) : <span>{model.viewModel[dataKey]}</span>
);

@inject('surveys', 'terms', 'ui')
@observer
export default class SurveyEditPage extends Component {
  @observable temporaryTerm = createNewTermModel();

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    this.disposeAutoclean = autorun(() => {
      if (!this.props.surveys.viewState.openState.value) {
        this.temporaryTerm = createNewTermModel();
      }
    });
  }
  componentWillUnmount() {
    this.onReset();
    this.forceUnload = false;
    this.disposeAutoclean();
  }

  routerWillLeave = action => {
    if (this.isDirty && !this.forceUnload) {
      this.props.ui.showModal(
        () => {
          this.forceUnload = true;
          browserHistory.push(action);
        },
        null,
        t('yourWorkIsNotSaved')
      );

      return false;
    }
  };

  @computed
  get isDirty() {
    const model = this.getModel();
    return model.isDirty || model.emrData.some(emrModel => emrModel.viewModel.isDirty);
  }

  @action
  changeTemporaryTerm = value => {
    this.temporaryTerm.termId = value.id;
    this.temporaryTerm.termName = value.name;
    this.temporaryTerm.termType = value.type;
    this.temporaryTerm.termDesc = value.desc;
  };

  @action
  addToEmr = () => {
    const { surveys, params } = this.props;
    const model = this.getModel();
    const blockedOptions = model.emrData.map(emr => emr.viewModel.termId);

    if (this.temporaryTerm.termId === 'custom') {
      this.props.ui.showError({ messageId: 'errors.termNameIsNotSelected' }, t);
      return;
    }

    if (blockedOptions.includes(this.temporaryTerm.termId)) {
      this.props.ui.showError({ messageId: 'errors.termNameAlreadyExist' }, t);
      return;
    }

    surveys.addToEmr(params.id, toJS(this.temporaryTerm));
    this.temporaryTerm = createNewTermModel();
  };

  @action
  onReset = () => {
    const { surveys, params } = this.props;
    const model = surveys.getSurvey(params.id);
    surveys.resetSurveyEmr(model.id);
    this.temporaryTerm = createNewTermModel();
  };

  termIdText = ({ rowData: term }) => (
    <ReactiveColRenderer>{() => <TermId term={term.viewModel} />}</ReactiveColRenderer>
  );

  termNameSelect = ({ rowData: term }) => (
    <TermNameSelect
      key={term.termId}
      model={this.getModel()}
      term={term.viewModel}
      {...this.props}
    />
  );

  termValueInput = ({ rowData: term }) => {
    return (
      <ReactiveColRenderer>
        {() => {
          if (term.viewModel.termType !== 'numeric') {
            return null;
          }

          return <Input name="value" type="number" maxLength="16" form={term.viewModel} />;
        }}
      </ReactiveColRenderer>
    );
  };

  termNoteTextArea = ({ rowData: term }) => {
    return (
      <ReactiveColRenderer>
        {() => <TextArea name="note" form={term.viewModel} validator={this.noteFieldValidator} />}
      </ReactiveColRenderer>
    );
  };

  noteFieldValidator = (target, form) => {
    const symb = /[^\u0009\u000A\u000D\u0020-\u007E\u00A0-\u00A5\u00A7\u00A8\u00AF\u00B4\u00B6\u00B7\u00C0-\u00DD\u00DF-\u00FE]/g; // eslint-disable-line no-control-regex

    if (symb.test(target.value)) {
      const validated = target.value.replace(symb, '');
      form['note'] = validated;
    } else {
      form['note'] = target.value;
    }
  };

  valueTermsValidator = (target, form) => {
    const regInput = /^[0-9\b\n]+$/;
    const regEnter = /\n{2}/;

    if ((regInput.test(target.value) && !regEnter.test(target.value)) || !target.value) {
      form['valueTerms'] = target.value ? target.value.split('\n').map(el => el && +el) : [];
    }
  };

  valueTermsTextArea = ({ rowData: term }) => {
    return (
      <ReactiveColRenderer>
        {() => (
          <TextArea
            data-test-id="valueTerms"
            name="valueTerms"
            form={term.viewModel}
            validator={this.valueTermsValidator}
          />
        )}
      </ReactiveColRenderer>
    );
  };

  termQuickActions = ({ rowData: term }) => {
    const { surveys } = this.props;
    return (
      <QuickLinkSurveyEdit
        term={term.viewModel}
        surveys={surveys}
        model={this.getModel()}
        id={term.termId}
      />
    );
  };

  getModel() {
    return this.props.surveys.getSurvey(this.props.params.id).viewModel;
  }

  getFilterButtonState = () =>
    this.props.surveys.viewState.openState.value ? t('closeAddNewToEmr') : t('openAddNewToEmr');

  performSurveyAction = action => {
    const model = this.getModel();

    if (this.props.surveys.viewState.isAnimating) {
      return;
    }

    if (this.props.surveys.viewState.openState.value) {
      this.props.surveys.viewState.closeViewPanel(action(model));
    } else {
      action(model)();
    }
  };

  archiveSurvey = () => {
    this.performSurveyAction(model => () => this.props.surveys.archiveSurvey(model.id));
  };

  saveSurvey = () => {
    this.performSurveyAction(model => () => this.props.surveys.saveSurveyEmr(model.id));
  };

  sendSurvey = () => {
    this.performSurveyAction(model => () => this.props.surveys.sendSurveyToEmr(model.id));
  };

  @action
  addToEmr = () => {
    const { surveys, params } = this.props;
    const model = this.getModel();
    const blockedOptions = model.emrData.map(emr => emr.viewModel.termId);

    if (this.temporaryTerm.termId === 'custom') {
      this.props.ui.showError({ messageId: 'errors.termNameIsNotSelected' }, t);
      return;
    }

    if (blockedOptions.includes(this.temporaryTerm.termId)) {
      this.props.ui.showError({ messageId: 'errors.termNameAlreadyExist' }, t);
      return;
    }

    surveys.addToEmr(params.id, toJS(this.temporaryTerm));
    this.temporaryTerm = createNewTermModel();
  };

  @action
  onReset = () => {
    const { surveys, params } = this.props;
    const model = surveys.getSurvey(params.id);
    surveys.resetSurveyEmr(model.id);
    this.temporaryTerm = createNewTermModel();
  };

  render() {
    const { surveys } = this.props;
    const model = this.getModel();

    // since from API sometimes can be doctor = null
    const doctor = model.doctor ? model.doctor.name : '';

    return (
      <div className="surveys-edit-page action-footer-padded">
        <div className="quick-info">
          <SurveyQuickInfo label={t('uploadDate')} data={dateFormat(moment(model.uploaded))} />
          <SurveyQuickInfo label={t('careUnit')} data={model.orgunit.name} />
          <SurveyQuickInfo label={t('doctor')} data={doctor} />
          <SurveyQuickInfo label={t('patient')} data={model.patient.name} />
        </div>
        <div>
          <DataTable data={model.emrData} className="data-table">
            <Column
              className="overflow-col"
              label={t('id')}
              dataKey="termId"
              width={90}
              style={{ paddingRight: '20px', paddingLeft: '20px' }}
              cellRenderer={this.termIdText}
            />
            <Column
              width={300}
              label={t('termName')}
              dataKey="termName"
              flexGrow={1}
              cellRenderer={this.termNameSelect}
            />
            <Column
              width={100}
              className="overflow-col"
              label={t('value')}
              dataKey="value"
              flexGrow={1}
              cellRenderer={this.termValueInput}
            />
            <Column
              width={350}
              className="overflow-col"
              label={t('note')}
              dataKey="note"
              flexGrow={1}
              cellRenderer={this.termNoteTextArea}
            />
            <Column
              width={70}
              className="overflow-col"
              label={t('valueTerms')}
              dataKey="valueTerms"
              flexGrow={1}
              cellRenderer={this.valueTermsTextArea}
            />
            <Column
              className="overflow-col"
              width={60}
              label={t('quickLink')}
              dataKey="termId"
              flexGrow={1}
              cellRenderer={this.termQuickActions}
            />
          </DataTable>
          <FiltersPanel
            className="filter-centered"
            filterState={surveys.viewState}
            transitionEnd={500}
          >
            <FilterView header={t('addNewToEmr')} onCloseRequire={surveys.viewState.toggleOpen}>
              <Input label={t('termName')} leftText>
                <TermNameSelect
                  term={this.temporaryTerm}
                  onChange={this.changeTemporaryTerm}
                  model={this.getModel()}
                  usePlaceholderAsValue
                  {...this.props}
                />
              </Input>
              <HideIf check={() => this.temporaryTerm.termType === 'numeric'}>
                <Input
                  name="value"
                  type="number"
                  label={t('numeric')}
                  leftText
                  maxLength="16"
                  form={this.temporaryTerm}
                />
              </HideIf>
              <Input label={t('note')} leftText placeholder={t('note')}>
                <TextArea name="note" form={this.temporaryTerm} />
              </Input>
              <Button action="primary" color="steel" onClick={this.addToEmr}>
                {t('addNewBnt')}
              </Button>
            </FilterView>
          </FiltersPanel>
          <ActionFooter>
            <Button action="primary" color="wisteria" onClick={surveys.viewState.toggleOpen}>
              {this.getFilterButtonState}
            </Button>
            <Button action="secondary" color="steel" onClick={this.archiveSurvey}>
              {t('archive')}
            </Button>
            <Button action="secondary" color="peach" onClick={this.onReset}>
              {t('reset')}
            </Button>
            <Button
              action="secondary"
              data-test-id="saveSurvey"
              color="green"
              onClick={this.saveSurvey}
            >
              {t('save')}
            </Button>
            <Button action="primary" color="green" onClick={this.sendSurvey}>
              {t('sendToEmr')}
            </Button>
          </ActionFooter>
        </div>
      </div>
    );
  }
}
