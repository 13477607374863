import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { runInAnimation, runOutAnimation } from 'utils/animations';

import './filter-view.css';

const animationState = observable({ translate: 0 });

export const FilterView = observer(({ header, subheader, children, onCloseRequire }) => {
  return (
    <div
      className={cx('filter-view')}
      style={{ transform: `translateY(-${animationState.translate}px)` }}
    >
      <div className={cx({ close: !!onCloseRequire })}>
        <h1>{header}</h1>
        <h2>{subheader}</h2>
        {!!onCloseRequire && (
          <div>
            <img src={require('assets/svg/sc-close.svg')} onClick={onCloseRequire} />
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
});

class FiltersPanel extends Component {
  componentDidUpdate() {
    if (this.lastState === this.props.filterState.openState.value) {
      return;
    }

    if (this.props.filterState.isAnimating) {
      return;
    }

    const onEndAnimationHook = () => {
      this.props.filterState.isAnimating = false;

      if (this.props.onAnimationEnd) {
        this.props.onAnimationEnd();

        // If someone (for testing proposes) will add global hook to wait on animation end
        // We will try to invoke it
        if (window.onFilterViewAnimationEnd) {
          try {
            window.onFilterViewAnimationEnd();
          } catch (e) {
            // really does not care about it
          }
        }
      }
    };

    if (this.props.filterState.openState.value) {
      this.props.filterState.isAnimating = true;
      runInAnimation(animationState, onEndAnimationHook, this.props.transitionEnd);
    } else {
      this.props.filterState.isAnimating = true;
      runOutAnimation(
        animationState,
        () => {
          this.props.filterState.openStateDoneCb.value();
          this.props.filterState.openStateDoneCb.value = () => {};
          this.props.filterState.isAnimating = false;
        },
        this.props.transitionEnd
      );
    }

    this.lastState = this.props.filterState.openState.value;
  }

  render() {
    const { filterState, children, className } = this.props;
    const isOpened = filterState.openState.value;

    return (
      <div>
        <div className={cx('overlay', { show: isOpened })} onClick={filterState.toggleOpen} />
        <div className={cx('filters-body', className)}>{children}</div>
      </div>
    );
  }
}

export default observer(FiltersPanel);
