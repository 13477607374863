import { Model } from './utils';
import { observable } from 'mobx';
import { serializable } from 'serializr';

export default class LoginModel extends Model {
  @serializable
  @observable
  username;

  @serializable
  @observable
  password;

  @observable isAdmin;
}

export class RecoveryRequestModel extends Model {
  @serializable
  @observable
  recoveryEmail;
}

export class RecoveryConfirmModel extends Model {
  @serializable
  @observable
  password;

  @serializable
  @observable
  passwordRepeat;
}
