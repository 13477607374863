import React from 'react';
import cx from 'classnames';

import './card.css';

export default ({ children, className, ...props }) => (
  <div className={cx('card', className)} {...props}>
    {children}
  </div>
);
