import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

import './survey-stats.css';

@inject('surveys')
@observer
export default class SurveyStats extends Component {
  onClick = () => this.props.onClick && this.props.onClick(this.props.statusId);

  getColor() {
    if (this.props.detectColor) {
      return this.props.detectColor(this.props.statusId);
    }

    if (this.props.color) {
      return this.props.color;
    }

    return 'wisteria';
  }

  getCount() {
    if (this.props.statusId && !this.props.forceEmpty) {
      return this.props.surveys.statusesReport[this.props.statusId] || '-';
    }

    if (this.props.forceEmpty && this.props.detectColor) {
      const color = this.props.detectColor(this.props.statusId);

      if (color === 'green') {
        return '-';
      }

      return '+';
    }

    return '-';
  }

  render() {
    const {
      status = '<empty>',
      count = '-',
      small = false,
      onClick = null,
      statusId = null,
      isEnabled
    } = this.props;

    return (
      <div className={cx('app-stats', { small, clickable: !!onClick })} onClick={this.onClick}>
        <div className={cx('background-count', this.getColor(), { small })}>{this.getCount()}</div>
        <span className="description">{status}</span>
      </div>
    );
  }
}
