function isBrowser() {
  return !!window;
}

function getProtocol() {
  if (isBrowser()) {
    return window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  }

  return 'wss://';
}

export class WebSocketService {
  cbs;
  isWSConnected;

  constructor() {
    this.cbs = new Set();
    this.isWSConnected = false;
  }

  connect(getToken) {
    if (!this.isWSConnected) {
      const ws = new WebSocket(`${getProtocol()}${location.host}/ws?token=${getToken()}`);

      ws.onclose = ev => {
        this.isWSConnected = false;

        if (ev.code !== 1000) {
          ws.close();
          setTimeout(() => this.connect(getToken), 3000);
        }
      };

      ws.onmessage = ({ data }) => this.processMessage(JSON.parse(data));
      this.isWSConnected = true;
    }

    return this;
  }

  processMessage(json = {}) {
    this.cbs.forEach(cb => cb(json));
  }

  onMsg(cb) {
    if (!this.cbs.has(cb)) {
      this.cbs.add(cb);
    }

    return this;
  }
}
