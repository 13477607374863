import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import './textarea.css';

@observer
export default class extends Component {
  onInput = ({ target }) => {
    const { validator, form, name } = this.props;

    if (validator) {
      this.props.validator(target, form);
    } else {
      form[name] = target.value;
    }
  };

  render() {
    const { form, name, className, validator, ...rest } = this.props;
    return (
      <textarea
        className={cx('custom-textarea', className)}
        onChange={this.onInput}
        value={form[name] instanceof Array ? form[name].join('\n') : form[name] || ''}
        {...rest}
      />
    );
  }
}
