import { BaseService } from '../base-service';

export class NursesService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/orgunits' });
  }

  query(params = {}, cancelToken, careUnitId) {
    params = { ...params };

    return this.get(`/${careUnitId}/users`, { params, cancelToken });
  }

  unlinkFromCareUnit(careUnitId, userId) {
    return this.unlink(`/${careUnitId}/users/${userId}`);
  }
}
