import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cx from 'classnames';
import MaskedInput from 'react-maskedinput';

import './input.css';

const numberRegExp = /^[0-9\b\n]+$/;

@observer
export default class Input extends Component {
  componentDidMount() {
    if (this.props.masked) {
      window.clearMask = () => {
        this.props.form[this.props.name] = '';
        this.props.onChange && this.props.onChange('');
      };
    }
  }

  onInput = event => {
    let eventValue = event.target.value;

    if (this.props.type === 'number') {
      if (isNaN(eventValue)) {
        return;
      }
      if (eventValue !== '' && numberRegExp.test(eventValue)) {
        eventValue = Number(eventValue);
      }
    }

    if (this.props.isFormik) {
      let newEvent = event;

      // FIXME: TODO: Alert! Super dirty workaround to fix MS EDGE issue with <input type=number />
      if (this.props.type === 'number') {
        newEvent = {
          ...event,
          currentTarget: {},
          target: {}
        };

        newEvent.currentTarget.value = eventValue;
        newEvent.target.value = eventValue;
        newEvent.target.id = event.currentTarget.id;
        newEvent.target.name = event.currentTarget.name;
        newEvent.persist = () => {};
      }

      this.props.onChange(newEvent);
      return;
    }

    this.props.form[this.props.name] = eventValue;
    this.props.onChange && this.props.onChange(eventValue);
  };

  detectValidation = () => {
    if (this.props.isFormik) {
      return false;
    }

    if (
      !this.props.children &&
      this.props.validator &&
      this.props.form[this.props.name] !== undefined
    ) {
      return !this.props.validator(this.props.form[this.props.name], this.props.form);
    }

    return false;
  };

  getValue() {
    let value;

    if (this.props.isFormik) {
      return this.props.value;
    }

    if (this.props.children) {
      return '';
    }

    value = this.props.form[this.props.name];

    if (value === null || value === undefined) {
      return '';
    }

    if (this.props.type === 'number' && numberRegExp.test(value)) {
      if (value !== '') {
        value = Number(value);
      }
    }

    return value;
  }

  render() {
    const {
      form,
      name,
      className,
      label,
      required,
      children,
      masked,
      validationText,
      validator,
      leftText,
      onChange,
      isFormik,
      setFieldValue,
      type,
      innerRef,
      ...rest
    } = this.props;

    const isError = this.detectValidation();
    const id = `input-${this.props.name}`;
    const inputType = type === 'number' ? 'text' : type;

    const labelEl = (
      <label className={cx('custom-input-label')} htmlFor={id}>
        {label}
        {!!required && <span>{' *'}</span>}
      </label>
    );

    let input;

    if (masked) {
      input = (
        <MaskedInput
          className={cx('custom-input', className, { error: isError, left: leftText })}
          onChange={this.onInput}
          value={this.getValue()}
          id={id}
          required={required}
          name={name}
          autoComplete="off"
          {...rest}
        />
      );
    } else {
      input = (
        <input
          className={cx('custom-input', className, { error: isError, left: leftText })}
          onChange={this.onInput}
          value={this.getValue()}
          id={id}
          required={required}
          name={name}
          type={inputType}
          autoComplete="off"
          {...rest}
          ref={node => innerRef && innerRef(node)}
        />
      );
    }

    return (
      <div>
        <div className="custom-input-label-group">
          {!!label && labelEl}
          {isError && <label className="label-error">{validationText}</label>}
        </div>
        {children || input}
      </div>
    );
  }
}
