import React, { Component } from 'react';
import { observable } from 'mobx';
import { Provider as MobxProvider, observer } from 'mobx-react';

import './styles/app.css';

import Router from './router';
import UISpinner from 'components/spinner/ui-spinner';
import NotificationSystem from 'components/notification/notification-system';
import Modal from 'components/modal/modal';

import SurveysStore from 'stores/surveys';
import { PatientsStore } from 'stores/patients';
import { NursesStore } from 'stores/nurses';
import { UsersStore } from 'stores/users';
import { MappingStore } from 'stores/mapping';
import { RoutingStore } from 'stores/routing';
import { SecretaryProfileStore, AdminProfileStore } from 'stores/profile';
import { AdminsStore } from 'stores/admins';
import { TemplatesStore } from 'stores/templates';
import { CareUnitsSmallStore } from 'stores/care-units-small';
import { CareUnitsStore } from 'stores/care-units';
import { ReportsStore } from 'stores/reports';
import SessionStore from 'stores/session';
import TermsStore from 'stores/terms';
import UIStore from 'stores/ui';
import SurveyAuditTrail from 'stores/survey-audit-trail';

import { careUnitsSmallService } from 'services/transport-layer';
import { routesService } from 'services/transport-layer';
import { TemplatesSmallStore } from './stores/templates-small';
import { UserCareUnitsStore } from './stores/user-care-units';
import { RoutingModalStore } from './stores/routing';
import Raven from 'raven-js';

if (process.env.NODE_ENV === 'production') {
  Raven.config('https://577167ae6c114a13878283424f10d8fb@sentry.io/665168').install();
}

const uiState = observable({ pageYOffset: 0 });

const toInject = {
  ui: UIStore,
  surveys: SurveysStore,
  session: SessionStore,
  terms: TermsStore,
  patients: PatientsStore,
  users: UsersStore,
  userCareUnits: UserCareUnitsStore,
  admins: AdminsStore,
  templates: TemplatesStore,
  templatesSmall: TemplatesSmallStore,
  careUnitsSmall: CareUnitsSmallStore,
  careUnits: CareUnitsStore,
  profileSecretary: SecretaryProfileStore,
  profileAdmin: AdminProfileStore,
  reports: ReportsStore,
  trail: SurveyAuditTrail,
  nurses: NursesStore,
  mapping: MappingStore,
  routing: RoutingStore,
  routingModal: RoutingModalStore,
  uiState,
  careUnitsSmallService,
  routesService
};

@observer
class ApplicationRoot extends Component {
  @observable hasError = false;
  componentDidMount = () => window.addEventListener('scroll', this.updateScrollPos);
  updateScrollPos = () => window.requestAnimationFrame(this.doUpdate);
  doUpdate = () => (uiState.pageYOffset = window.pageYOffset);
  componentDidCatch(error, errorInfo) {
    this.hasError = true;
    if (process.env.NODE_ENV === 'production') {
      Raven.captureException(error, { extra: errorInfo });
    }
  }

  render() {
    if (this.hasError) {
      return (
        <div className="app-content">
          <p>
            We're sorry — something's gone wrong! Our team has been notified. Please, click here to
            <a
              href=""
              style={{ paddingLeft: '5px', paddingRight: '5px' }}
              onClick={e => {
                e.preventDefault();
                location.replace('/');
              }}
            >
              reload
            </a>
            this page!
          </p>
        </div>
      );
    }
    return (
      <div className="app-root">
        <Router />
        <UISpinner />
        <NotificationSystem />
        <Modal />
      </div>
    );
  }
}

export default function SwipecareApp() {
  return (
    <MobxProvider {...toInject}>
      <ApplicationRoot />
    </MobxProvider>
  );
}
