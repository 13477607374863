import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { observer } from 'mobx-react';

import UIStore from 'stores/ui';
import { surveyStatusMap } from 'stores/models/survey';
import { dateFormat, timeFormat } from './dates';
import { Tooltip } from 'react-tippy';

import { translate } from 'utils/helpers';
const t = translate(['common', 'surveys']);

function onDelete(cb, id) {
  UIStore.showModal(() => cb(id));
}

function onUnlink(cb, from, to) {
  UIStore.showModal(() => cb(from, to));
}

export function deletableFormatter(onClick) {
  return (quickLink = null, { rowData }) => {
    return (
      <span>
        {quickLink}
        <Tooltip title={t('remove')} arrow="true" distance={20}>
          <img
            data-test-id="deleteFromTable"
            onClick={rowData.deletable ? () => onDelete(onClick, rowData.id) : null}
            src={require(`assets/svg/sc-trash-${rowData.deletable ? 'active' : 'disabled'}.svg`)}
          />
        </Tooltip>
      </span>
    );
  };
}

export function unlinkableFormatter(onClick) {
  return (quickLink = null, { rowData }) => {
    return (
      <span>
        {quickLink}
        <img
          onClick={
            rowData.deletable ? () => onUnlink(onClick, rowData.careUnitId, rowData.id) : null
          }
          src={require(`assets/svg/sc-trash-${rowData.deletable ? 'active' : 'disabled'}.svg`)}
        />
      </span>
    );
  };
}

export function timeFormatter(val) {
  const datetime = moment(val);
  return [
    dateFormat(datetime),
    <br key={'br' + val} />,
    <small key={'small' + val} className="survey-time">
      {timeFormat(datetime)}
    </small>
  ];
}

export function timeFieldFormatter(val) {
  if (!val) {
    return;
  }
  return timeFormatter(val);
}

export function momentTimeFormatter(datetime) {
  const val = datetime.format();
  return [
    dateFormat(datetime),
    <br key={'br' + val} />,
    <small key={'small' + val} className="survey-time">
      {timeFormat(datetime)}
    </small>
  ];
}

export function userNameFormatter(user, { rowData }) {
  return [
    user.username,
    <br key={'br' + rowData.id} />,
    <small key={'small' + rowData.id} className="survey-time">
      HSA ID: {user.hsaId}
    </small>
  ];
}

export function isActiveToTextFormatter(val) {
  return val ? t('yes') : t('no');
}

export function surveyStatus(value) {
  switch (value) {
    case surveyStatusMap.requiringAttention:
      return t('requiringAttention');
    case surveyStatusMap.waitingForQa:
      return t('waitingForQa');
    case surveyStatusMap.imported:
      return t('imported');
    case surveyStatusMap.importGaveUp:
      return t('importGaveUp');
    case surveyStatusMap.qa:
      return t('qaStage');
    case surveyStatusMap.readyForEMR:
      return t('readyForEMR');
    case surveyStatusMap.finished:
      return t('finished');
    case surveyStatusMap.requireSITHSConfirmation:
      return t('requireSITHSConfirmation');
    case surveyStatusMap.defective:
      return t('defective');
    case surveyStatusMap.archived:
      return t('archived');
    case surveyStatusMap.unknownStatus:
      return 'UNMAPPED STATUS';
  }
}

export function parentCareUnit(parent) {
  return (
    !!parent && (
      <span>
        {`(${parent.code})`}
        <br />
        {parent.name}
      </span>
    )
  );
}

export function addressFormatter(address) {
  return (
    [address.city, address.street, address.number, address.postCode, address.CO]
      .filter(item => !!item)
      .join(', ') || ''
  );
}

export function isActiveFormatter(isActive, onClick) {
  const handler = typeof onClick === 'function' ? onClick : null;
  if (!isActive) {
    return <div className="is-disabled-icon" onClick={handler} />;
  }

  // typeof check is required for reacttabular. it passes second arg as object
  return (
    <img
      src={require('assets/svg/sc-is-active.svg')}
      onClick={handler}
      className={cx('is-active-icon', { disabled: !isActive })}
    />
  );
}

const SortImg = observer(({ sortState, cb, property }) => {
  let arrow = 'empty';

  if (property === sortState.property) {
    if (sortState.value === 'asc') {
      arrow = 'down';
    }

    if (sortState.value === 'desc') {
      arrow = 'up';
    }

    if (!sortState.value) {
      arrow = 'empty';
    }
  }

  return (
    <img
      data-test-id={property + '-' + arrow}
      onClick={() => cb(property)}
      src={require(`assets/svg/sc-sort-${arrow}.svg`)}
      className="sort"
    />
  );
});

export const applySortingServerSide = (cb, sortState) => (val, { property }) => {
  return [
    <span key={`sort-val-${property}`}>{val}</span>,
    <SortImg key={`sort-img-${property}`} cb={cb} sortState={sortState} property={property} />
  ];
};

export const applySorting = (val, { property }) => {
  return [<span key={`sort-val-${val}`}>{val}</span>];
};
