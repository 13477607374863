import React from 'react';

function GenericErrorPage({ error }) {
  if (error instanceof Error) {
    return (
      <div>
        <div>Message: {JSON.stringify(error.message)}</div>
        <div>Stack: {JSON.stringify(error.stack)}</div>
      </div>
    );
  }

  return null;
}

export default GenericErrorPage;
