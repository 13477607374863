import { Model } from './utils';
import { observable } from 'mobx';
import { serializable, alias } from 'serializr';

export default class CareUnitSmallModel extends Model {
  @serializable(alias('_id'))
  id;
  @serializable
  @observable
  code;
  @serializable
  @observable
  description;
  @serializable
  @observable
  name;
}
