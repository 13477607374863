import { browserHistory } from 'react-router';
import { action } from 'mobx';
import moment from 'moment';

import { templatesService } from 'services/transport-layer';
import TemplateModel from 'models/template';
import UIStore from './ui';
import { createDomainStore } from './base';

const config = {
  name: 'templates',
  serviceToInject: templatesService,
  domainModel: TemplateModel,
  useQuery: true,
  selectors: {
    dataSelector: (data, rawData) => {
      if (data.data) {
        return data.data;
      }

      return data;
    }
  }
};

export const {
  store: TemplatesStore,
  listResolver: templatesResolver,
  itemResolver: templateResolver
} = createDomainStore(config);

Object.assign(TemplatesStore, {
  create(model) {
    model.viewModel.id = Math.random();
    model.viewModel.submit();
    model.source.viewModel.submit();

    const preparedModel = {
      description: model.viewModel.description,
      isActive: model.viewModel.isActive,
      template: model.source.viewModel.file
    };

    model.viewModel.resources.forEach(resource => {
      if (resource.viewModel.isDirty) {
        preparedModel[resource.viewModel.id] = resource.viewModel.file;
      }
    });

    UIStore.setToTransition();
    model.viewModel.submit();
    model.source.viewModel.submit();

    return templatesService
      .create(model.code, preparedModel)
      .then(
        action(({ insertedId }) => {
          model.id = insertedId;
          model.lastUpdate = moment().format();
          model.source.file = null;
          model.source.originalFilename = null;
          model.viewModel.resources.forEach(resource => {
            resource.viewModel.submit();
            resource.file = null;
          });

          this.addOrUpdateItem(model.serialize());

          browserHistory.push(`/home/templates`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  update(id) {
    const model = this.getItem(id);

    // TODO: reuse
    UIStore.setToTransition();

    const preparedModel = {
      description: model.viewModel.description,
      isActive: model.viewModel.isActive
    };

    if (model.source.viewModel.isDirty) {
      preparedModel.template = model.source.viewModel.file;
    }

    model.viewModel.resources.forEach(resource => {
      if (resource.viewModel.isDirty) {
        preparedModel[resource.viewModel.id] = resource.viewModel.file;
      }
    });

    model.viewModel.submit();
    model.source.viewModel.submit();

    return templatesService
      .update(model.id, model.body.metadata.template.code, preparedModel)
      .then(
        action(() => {
          model.viewModel.resources.forEach(resource => {
            resource.viewModel.submit();
            resource.file = null;
          });
          model.source.originalFilename = null;
          model.source.file = null;
          browserHistory.push(`/home/templates`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  delete(id) {
    UIStore.setToTransition();
    templatesService
      .delete(id)
      .then(
        action(() => {
          this.queryItems(false);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },
  linkFromProgenitor(templateId, progenitorId) {
    UIStore.setToTransition();
    templatesService
      .linkFromProgenitor(templateId, progenitorId)
      .then(
        action(() => {
          browserHistory.push(`/home/templates`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },
  unlinkFromProgenitor(templateId, progenitorId) {
    const model = this.getItem(templateId);
    UIStore.setToTransition();
    templatesService
      .unlinkFromProgenitor(templateId, progenitorId)
      .then(
        action(item => {
          this.viewState.changePage(this.viewState.pageState.value);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  }
});
