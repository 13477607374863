import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Downshift from 'downshift';

import './downshift.css';

export const AutoComplete = ({
  items,
  dataTestId,
  placeholder,
  selectedItem,
  onChange,
  defaultSelectedItem,
  itemToString
}) => (
  <Downshift
    dataTestId={dataTestId}
    placeholder={placeholder}
    onChange={onChange}
    itemToString={item => (item || {}).name || ''}
    defaultSelectedItem={defaultSelectedItem}
    render={({
      getInputProps,
      getItemProps,
      isOpen,
      highlightedIndex,
      selectedItem: dsSelectedItem,
      inputValue
    }) => (
      <div className="downshift-container">
        <input
          data-test-id={dataTestId}
          placeholder={placeholder}
          className="custom-input downshift-complete"
          {...getInputProps()}
        />
        {isOpen ? (
          <div className="downshift-list complete">
            {items
              .filter(
                item => !inputValue || item.name.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((item, index) => (
                <div
                  className="downshift-listitem"
                  {...getItemProps({ item })}
                  key={item._id}
                  style={{
                    backgroundColor: highlightedIndex === index ? '#f3f3f3' : 'white',
                    fontWeight: selectedItem === item ? 'bold' : 'normal'
                  }}
                >
                  {item.name}
                </div>
              ))}
          </div>
        ) : null}
      </div>
    )}
  />
);
