import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';

import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';
import BackTo from 'components/backTo/back-to';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import Input from 'components/input/input';

import {
  timeFormatter,
  applySortingServerSide,
  addressFormatter,
  deletableFormatter
} from 'formatters/tables';

import { translate, HideIf } from 'utils/helpers';
import { computed } from 'mobx';
const t = translate(['common', 'patients']);

class PatientsPage extends Component {
  constructor(props) {
    super(props);
    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.patients.viewState.sortState
    );
    this.columns = [
      {
        property: 'personalNumber',
        header: {
          label: t('personalNumber'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'fullName',
        header: {
          label: t('fullName'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'patientPlace',
        header: {
          label: t('patientPlace'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'address',
        header: {
          label: t('address'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [addressFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        header: {
          label: t('remove')
        },
        cell: {
          formatters: [deletableFormatter(this.onDelete)],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    ];
  }

  componentWillMount() {
    if (this.props.session.isAdmin()) {
      this.props.router.replace('/');
    }
  }

  onDelete = id => this.props.patients.delete(id, this.props.params.careUnitId);

  onNewClick = () =>
    browserHistory.push(`/home/care-units/${this.props.params.careUnitId}/patients/new`);

  onRowClick = row => {
    return {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.nodeName !== 'IMG') {
          browserHistory.push(
            `/home/care-units/${this.props.params.careUnitId}/patients/${row.id}/edit`
          );
        }
      }
    };
  };

  onPageClick = selected => this.props.patients.viewState.changePage(selected);

  onSortClick = property => this.props.patients.viewState.applySort(property);

  isFiltered = () => this.props.patients.viewState.isFiltered;

  columnsAdmin = columns => {
    columns = columns.slice(1);
    return columns;
  };

  @computed
  get searchIsOpen() {
    return this.props.patients.viewState.openState.value;
  }

  render() {
    const { patients, session, params } = this.props;
    const filteredPatients = patients.getListByCareUnitId(params.careUnitId);

    // Getting care unit name from first patient, if any.
    const careUnit = filteredPatients[0]
      ? filteredPatients[0].careUnitName
      : patients.getCareUnitName();
    return (
      <div className="patients-page action-footer-padded">
        <NavbarInfo
          content={
            <BackTo
              title={t('backToCareUnitsList')}
              to={session.isAdmin() ? '/home/care-units/admin' : '/home/care-units'}
            />
          }
        >
          {t('listTitle', { careUnit })}
        </NavbarInfo>
        <div className="panel">
          <DataTable
            className="table-fixed table-ellipsed"
            data={filteredPatients}
            columns={session.isAdmin() ? this.columnsAdmin(this.columns) : this.columns}
            onRow={this.onRowClick}
            isLoading={patients.isListLoading}
            pageState={patients.viewState.pageState}
            pageCount={patients.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered"
          filterState={patients.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')}>
            <Input
              data-test-id="searchByPatientName"
              name="value"
              form={patients.viewState.patientSearchState}
              leftText
              label={t('filters.name.fullNameLabel')}
              placeholder={t('filters.name.fullNamePlaceholder')}
              onChange={patients.viewState.changePatientSearch}
            />
            <Input
              data-test-id="searchBypatientPlace"
              name="value"
              form={patients.viewState.patientPlaceSearchState}
              leftText
              placeholder={t('filters.name.patientPlacePlaceholder')}
              label={t('filters.name.patientPlaceLabel')}
              onChange={patients.viewState.changepatientPlaceSearch}
            />
            {!session.isAdmin() && (
              <Input
                data-test-id="searchByPersonalNumber"
                name="value"
                form={patients.viewState.personalNumberSearchState}
                label={t('filters.name.personalNumberLabel')}
                leftText
                masked
                mask="11111111-1111"
                placeholder={t('filters.name.personalNumberPlaceholder')}
                onChange={patients.viewState.changePersonalNumberSearch}
              />
            )}
            {!session.isAdmin() && (
              <Input
                data-test-id="searchByPersonalNumber"
                name="value"
                form={patients.viewState.spareNumberSearchState}
                label={t('filters.name.sparelNumberLabel')}
                leftText
                masked
                mask="1111-111111"
                placeholder={t('filters.name.spareNumberPlaceholder')}
                onChange={patients.viewState.changeSparePersonalNumberSearch}
              />
            )}
          </FilterView>
        </FiltersPanel>
        <ActionFooter>
          <Button
            data-test-id="searchPatient"
            action="secondary"
            color="steel"
            onClick={patients.viewState.toggleOpen}
          >
            {!this.searchIsOpen ? t('searchPatient') : t('closeFilter')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={patients.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
          <Button
            action="primary"
            disabled={this.searchIsOpen}
            color={!this.searchIsOpen ? 'wisteria' : 'steel disabled'}
            onClick={this.onNewClick}
          >
            {t('addPatient')}
          </Button>
        </ActionFooter>
      </div>
    );
  }
}

export default inject('patients', 'session')(observer(PatientsPage));
