import React, { Component } from 'react';
import { observable, computed, action } from 'mobx';
import { observer, inject, Observer } from 'mobx-react';
import { deserialize } from 'serializr';
import { browserHistory } from 'react-router';

import { updateFocus, notEmptyValidator } from 'utils/helpers';

import Card from 'components/card/card';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import Input from 'components/input/input';
import Form from 'components/form/form';
import BackTo from 'components/backTo/back-to';
import Checkbox from 'components/checkbox/checkbox';
import { NavbarInfo } from 'components/navbar/navbar';

import './templates.css';

import { translate } from 'utils/helpers';
import Template, { TemplateResourcesModel } from 'stores/models/template';
const t = translate(['common', 'templates']);

@inject('session')
@observer
class Resource extends Component {
  onFileSelect = file => {
    this.props.item.file = file;

    if (FileReader) {
      const fr = new FileReader();
      fr.onload = action(() => (this.props.item.filename = fr.result));
      fr.readAsDataURL(file);
    }
  };

  getImage() {
    if (this.props.item.isPropertyDirty('filename')) {
      return <img className="padded-top20" src={this.props.item.filename} />;
    }

    if (this.props.item.filename) {
      return (
        <img
          className="padded-top20"
          src={this.props.session.makeStorageUrl(this.props.item.filename)}
        />
      );
    }

    return null;
  }

  render() {
    const { item } = this.props;

    return (
      <Card className="template-card padded-top20 resource">
        <p className="header">
          {t('resourceId', { resourceId: item.id })}
          {' *'}
        </p>
        <Button
          isFile
          accept=".png,.jpg,.jpeg"
          onFileSelected={this.onFileSelect}
          action="primary"
          color="wisteria"
        >
          {t('chooseNewFile')}
        </Button>
        {this.getImage()}
        {!item.filename && <p>{t('noFilesSelected')}</p>}
      </Card>
    );
  }
}

@observer
class ResourcesList extends Component {
  getItem = (item, index) => <Resource item={item.viewModel} key={item.viewModel.id} />;

  render() {
    const { model } = this.props;
    return <div>{model.resources.map(this.getItem)}</div>;
  }
}

@inject('session', 'ui')
@observer
class FileUploader extends Component {
  @observable isProcessing = false;

  @action
  onFileSelect = file => {
    if (!FileReader) {
      console.warn('Some unsupported browser occurred.');
      return;
    }

    const fr = new FileReader();
    this.isProcessing = true;

    fr.onload = action(() => {
      const source = this.props.model.source.viewModel;

      let data;

      try {
        // user can attach invalid JSON
        data = JSON.parse(fr.result);

        const metadata = data.metadata || {};

        // dirty check is this Strikersoft template
        // in future versions JSON schema should be used
        if (!metadata.template || !data.assessment) {
          throw new Error('Incorrect JSON file uploaded');
        }
      } catch (e) {
        // log error
        console.error(e);

        // show user error about incorrect uploaded file
        this.props.ui.showError({ messageId: 'errors.incorrectJSONFile' }, t);

        // hide `processing` label
        this.isProcessing = false;
        return;
      }

      const { patch, minor, major } = data.metadata.template.version;

      source.file = file;
      source.uploaded = true;
      source.originalFilename = file.name;

      this.props.model.name = data.metadata.template.title;
      this.props.model.version = major + '.' + minor + '.' + patch;

      const resourses = [];
      /*eslint-disable */
      (data.assessment.sections || []).forEach(section => {
        (section.subsections || []).forEach(subsection => {
          (subsection.groups || []).forEach(group => {
            (group.questions || []).forEach(question => {
              (question.extensions || []).forEach(extension => {
                if (extension.image && extension.image.id) resourses.push(extension.image.id);
              });
            });
          });
        });
      });
      /*eslint-enable */

      this.props.model.resources = resourses.map(resourceId => {
        const existingResource = this.props.model.resources.find(r => r.id === resourceId);

        if (existingResource) {
          return existingResource;
        }

        const model = deserialize(TemplateResourcesModel, {});
        model.viewModel.id = resourceId;

        return model;
      });

      this.props.model.code = data.metadata.template.code;

      this.isProcessing = false;
    });

    fr.readAsText(file);
  };

  getLinkToFile() {
    const source = this.props.model.source.viewModel;

    if (source.isPropertyDirty('originalFilename')) {
      return (
        <a className="filename">
          {this.isProcessing ? `${t('processingYourFile')} ...` : source.originalFilename}
        </a>
      );
    }

    return (
      <a href={this.props.session.makeStorageUrl(source.filename)} className="filename">
        {source.originalFilename}
      </a>
    );
  }

  render() {
    const source = this.props.model.source.viewModel;

    return (
      <Card className="template-card padded-top20">
        <p className="header">{t('templateSourceFile')}</p>
        <div className="url">{this.getLinkToFile()}</div>
        <Button
          isFile
          testId="template"
          onFileSelected={this.onFileSelect}
          accept=".json,.js"
          action="primary"
          color="wisteria"
        >
          {t('chooseNewFile')}
        </Button>
        {!source.originalFilename && <p>{t('noFilesSelected')}</p>}
      </Card>
    );
  }
}

@inject('templates', 'ui')
@observer
class TemplatesManagePage extends Component {
  componentWillMount() {
    const { params, templates } = this.props;

    this.model = this.isEditMode()
      ? templates.getItem(params.id)
      : deserialize(Template, { isActive: true });
  }

  isEditMode() {
    return this.props.route.edit;
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  }

  componentWillUnmount() {
    this.model.viewModel.reset();
    this.model.source.viewModel.reset();
    this.model.resources.forEach(resource => resource.viewModel.reset());
    this.forceUnload = false;
  }

  routerWillLeave = () => {
    if (this.isFormDirty()) return t('yourWorkIsNotSaved');
  };

  routerWillLeave = action => {
    if (this.isFormDirty() && !this.forceUnload) {
      this.props.ui.showModal(
        () => {
          this.forceUnload = true;
          browserHistory.push(action);
        },
        null,
        t('yourWorkIsNotSaved')
      );

      return false;
    }
  };

  isFormDirty() {
    return this.model.viewModel.isDirty || this.model.viewModel.uploaded || this.isResourcesDirty;
  }

  @computed
  get isResourcesDirty() {
    return this.model.resources.some(resource => resource.viewModel.isDirty);
  }

  onSubmit = (e, isValid) => {
    const { params, templates } = this.props;

    if (!isValid) {
      return;
    }

    if (this.model.viewModel.resources.some(resource => !resource.viewModel.filename)) {
      this.props.ui.showError({ messageId: 'errors.addAllResources' }, t);
      return;
    }

    if (this.isEditMode()) {
      templates.update(params.id);
    } else {
      templates.create(this.model);
    }
  };

  render() {
    const model = this.model.viewModel;

    return (
      <div className="templates-page action-footer-padded">
        <NavbarInfo content={<BackTo title={t('backToTemplateList')} to="/home/templates" />}>
          {this.isEditMode()
            ? t('editTemplate', { name: model.code }) + (this.isFormDirty() ? ' *' : '')
            : t('createTemplate')}
        </NavbarInfo>
        <Card>
          <Form onSubmit={this.onSubmit} form={model}>
            <Input
              name="name"
              label={t('name')}
              placeholder={t('name')}
              validator={notEmptyValidator}
              disabled
            />
            <Input
              name="version"
              label={t('version')}
              placeholder={t('version')}
              validator={notEmptyValidator}
              disabled
            />
            <Input
              name="description"
              label={t('templateDescription')}
              placeholder={t('templateDescription')}
              validator={notEmptyValidator}
              validationText={t('errors.pleaseInputField')}
              required
            />
            <Checkbox classNameRoot="form-padding" name="isActive" label={t('isActive')} />
            <ActionFooter>
              <Button type="submit" onClick={updateFocus} action="primary" color="green">
                {t('save')}
              </Button>
            </ActionFooter>
          </Form>
        </Card>
        <FileUploader model={model} />
        <ResourcesList model={model} />
      </div>
    );
  }
}

export default TemplatesManagePage;
