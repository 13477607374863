import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import CopyToClipboard from 'react-copy-to-clipboard';

@observer
class QuickLinkSurveyEdit extends Component {
  @action.bound
  onDelete() {
    this.props.surveys.deleteEmr(this.props.model.id, this.props.id);
  }

  render() {
    const { surveys, model, term, id } = this.props;

    return (
      <span>
        <CopyToClipboard
          text={`${term.termName || ''}\n\n${term.value || ''}\n\n${term.note ||
            ''}\n\n${term.valueTerms || ''}`}
          onCopy={() => {
            /*TODO: display message to user */
          }}
        >
          <img className="icon-button" src={require('assets/svg/sc-copy.svg')} />
        </CopyToClipboard>
        <img
          className="icon-button"
          src={require('assets/svg/sc-trash.svg')}
          onClick={this.onDelete}
        />
      </span>
    );
  }
}

export default QuickLinkSurveyEdit;
