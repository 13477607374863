import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';

import { translate } from 'utils/helpers';
import { getRedirectParams } from 'utils/helpers';
import { hideWithFade } from 'utils/animations';
import RouterLink from 'components/routerLink/router-link';
import cx from 'classnames';

import './navbar.css';

const t = translate(['common', 'navigation']);

const NAVBAR_UNDER_HEADER_PX = 158;

@inject('uiState', 'session', 'ui')
@observer
export class Navbar extends Component {
  changeLangToEng = () => this.props.ui.changeLanguage('en');

  changeLangToSv = () => this.props.ui.changeLanguage('sv');

  goToUserProfile = () => {
    if (this.props.session.isAdmin()) {
      browserHistory.push(`/home/me/admin`);
    } else {
      browserHistory.push(`/home/me/secretary`);
    }
  };

  render() {
    const { uiState, session, ui, notLogged = false } = this.props;

    const isAdmin = session.isAdmin();
    const isSuperAdmin = session.isSuperAdmin();
    const { link, subDomain } = getRedirectParams(window.location.hostname);

    return (
      <nav className={cx('navbar-root', { shadow: uiState.pageYOffset >= NAVBAR_UNDER_HEADER_PX })}>
        <ul className="list-root">
          {(!isAdmin || isSuperAdmin) &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="Surveys" to="/home/surveys">
                  {t('surveys')}
                </RouterLink>
              </li>
            )}
          {!isAdmin &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="CareUnits" to="/home/care-units">
                  {t('careUnits')}
                </RouterLink>
              </li>
            )}
          {isAdmin &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="CareUnitsAdmin" to="/home/care-units/admin">
                  {t('careUnits')}
                </RouterLink>
              </li>
            )}
          {isAdmin &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="Users" to="/home/users">
                  {t('users')}
                </RouterLink>
              </li>
            )}
          {isAdmin &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="Templates" to="/home/templates">
                  {t('templates')}
                </RouterLink>
              </li>
            )}
          {isAdmin &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="Reports" to="/home/reports">
                  {t('reports')}
                </RouterLink>
              </li>
            )}
          {isSuperAdmin &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="Admins" to="/home/admins">
                  {t('admins')}
                </RouterLink>
              </li>
            )}
          {isAdmin &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="Mapping" to="/home/mapping">
                  {t('mapping')}
                </RouterLink>
              </li>
            )}
          {isAdmin &&
            !notLogged && (
              <li>
                <RouterLink data-test-id="Routing" to="/home/routing">
                  {t('routing')}
                </RouterLink>
              </li>
            )}
          {!notLogged && (
            <li>
              <a data-test-id="LoggedHelp" target="_blank" href={`${link}/${subDomain}`}>
                {t('help')}
              </a>
            </li>
          )}
          {notLogged && (
            <li>
              <RouterLink data-test-id="UnloggedHelp" to="/help">
                {t('help')}
              </RouterLink>
            </li>
          )}
        </ul>
        <div className="navbar-right">
          {!notLogged && (
            <span>
              <span
                onClick={this.changeLangToEng}
                className={cx({ isActive: ui.isActiveLanguage('en') })}
              >
                EN
              </span>
              <span>/</span>
              <span
                onClick={this.changeLangToSv}
                className={cx({ isActive: ui.isActiveLanguage('sv') })}
              >
                SV
              </span>
            </span>
          )}
          {!isSuperAdmin &&
            !notLogged && (
              <img
                data-test-id="profileUser"
                src={require('assets/svg/sc-profile.svg')}
                onClick={this.goToUserProfile}
              />
            )}
          {notLogged ? null : <a onClick={() => session.logout()}>{t('logout')}</a>}
        </div>
      </nav>
    );
  }
}

function NavbarInfoComponent({ children, content, uiState, subtitle }) {
  const subt = <div className="navbar-subtitle">{subtitle}</div>;
  return (
    <div className="navbar-info" style={hideWithFade(uiState)}>
      <span>{children}</span>
      <div className="content">{content}</div>
      {subtitle ? subt : null}
    </div>
  );
}

export const NavbarInfo = inject('uiState')(observer(NavbarInfoComponent));
