import { createViewModel } from 'mobx-utils';
import { serialize } from 'serializr';

export class Model {
  _viewModel = null;

  get viewModel() {
    if (!this._viewModel) {
      this._viewModel = createViewModel(this);
      return this._viewModel;
    }
    return this._viewModel;
  }

  serialize() {
    return serialize(this);
  }
}
