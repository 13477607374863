import React, { Component } from 'react';
import { versionService } from 'services/transport-layer';

// eslint-disable-next-line no-undef
const version = __VERSION__;
// eslint-disable-next-line no-undef
const commitHash = __COMMITHASH__;

class Version extends Component {
  state = {
    backendVersion: 'loading...'
  };

  componentDidMount() {
    versionService
      .fetch()
      .then(response => {
        this.setState({ backendVersion: response.version });
      })
      .catch(error => {
        console.error(error);
        this.setState({ backendVersion: `fetch error (${error.messageId})` });
      });
  }

  render() {
    return (
      <div className="version">
        <pre>Version: {version}</pre>
        <pre>Backend version: {this.state.backendVersion}</pre>
        <pre>Git revision: {commitHash}</pre>
      </div>
    );
  }
}

export default Version;
