import React, { Component } from 'react';
import { observable, action, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { deserialize } from 'serializr';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { updateFocus } from 'utils/helpers';
import Checkbox from 'components/checkbox/checkbox';
import Card from 'components/card/card';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import Input from 'components/input/input';
import Form from 'components/form/form';
import { NavbarInfo } from 'components/navbar/navbar';
import BackTo from 'components/backTo/back-to';

import Patient, { AddressModel } from 'stores/models/patient';
import { dateFormat } from 'formatters/dates';
import { translate } from 'utils/helpers';
import { personNumberValidator } from 'utils/custom-validations';
import { spareNumberValidator } from 'utils/sparenumber-validation';
const t = translate(['common', 'patients']);

@inject('patients', 'session', 'ui')
@observer
class PatientsManagePage extends Component {
  @observable
  form = {
    sex: '',
    birthday: ''
  };

  componentWillMount() {
    const { params, patients, session } = this.props;

    if (session.isAdmin()) {
      this.props.router.replace('/');
    }

    this.model = this.isEditMode()
      ? patients.getItem(params.patientId)
      : deserialize(Patient, { address: new AddressModel({}) });
  }

  isEditMode() {
    return this.props.route.edit;
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  }

  componentWillUnmount() {
    this.model.viewModel.reset();
    this.model.address.viewModel.reset();
    this.forceUnload = false;
  }

  routerWillLeave = action => {
    if (this.isFormDirty() && !this.forceUnload) {
      this.props.ui.showModal(
        () => {
          this.forceUnload = true;
          browserHistory.push(action);
        },
        null,
        t('yourWorkIsNotSaved')
      );

      return false;
    }
  };

  isFormDirty() {
    return this.model.viewModel.isDirty || this.model.address.viewModel.isDirty;
  }

  onSubmit = (e, isValid) => {
    const { params, patients } = this.props;

    if (!isValid) {
      return;
    }

    if (this.isEditMode()) {
      patients.update(params.patientId, params.careUnitId);
    } else {
      patients.create(this.model, params.careUnitId);
    }
  };

  notEmptyValidator = value => !!value;
  @action
  personalOrSpareNumberValidator = (value, model) => {
    const { isValid, date, male } = personNumberValidator(value, model);

    if (isValid) {
      this.form.sex = male ? t('male') : t('female');
      this.form.birthday = dateFormat(moment(date));

      return true;
    }

    this.form.sex = '';
    this.form.birthday = '';

    return spareNumberValidator(value).isValid;
  };

  clearPN = () => {
    setTimeout(() => (this.model.viewModel['personalNumber'] = ''), 10);
  };

  render() {
    const model = this.model.viewModel;
    const address = this.model.address.viewModel;
    const backUrl = `/home/care-units/${this.props.params.careUnitId}/patients`;

    return (
      <div className="patients-page action-footer-padded">
        <NavbarInfo content={<BackTo title={t('backToPatientsList')} to={backUrl} />}>
          {this.isEditMode()
            ? t('editPatient', { name: model.fullName }) + (this.isFormDirty() ? ' *' : '')
            : t('createPatient')}
        </NavbarInfo>
        <Card>
          <Form onSubmit={this.onSubmit} form={model}>
            <Input
              name="fullName"
              label={t('fullName')}
              placeholder={t('fullName')}
              validator={this.notEmptyValidator}
              validationText={t('inputCorrectFullNam')}
              required
            />
            <Input
              form={model}
              name="personalNumber"
              label={t('personalOrSpareNumber')}
              validator={this.personalOrSpareNumberValidator}
              validationText={t(
                model.isSpareNumber
                  ? 'errors.inputCorrectSpareNumber'
                  : 'errors.inputCorrectPersonalNumber'
              )}
              required
              masked
              mask={model.isSpareNumber ? '1111-111111' : '11111111-1111'}
            />
            <Checkbox
              form={model}
              checked={model.isSpareNumber}
              onChange={this.clearPN}
              name="isSpareNumber"
              label={t('isSpareNumber')}
            />
            <Input
              form={model}
              name="patientPlace"
              label={t('patientPlace')}
              placeholder={t('patientPlace')}
            />
            <Input name="sex" label={t('sex')} form={this.form} disabled />
            <Input name="birthday" label={t('birthday')} form={this.form} disabled />
            <ActionFooter>
              <Button type="submit" onClick={updateFocus} action="primary" color="green">
                {t('save')}
              </Button>
            </ActionFooter>
          </Form>
        </Card>
        <Card className="padded-top20">
          <Form onSubmit={this.onSubmit} form={model}>
            <Input
              name="postcode"
              label={t('postCode')}
              placeholder={t('postCode')}
              form={address}
            />
            <Input name="city" label={t('city')} placeholder={t('city')} form={address} />
            <Input name="street" label={t('street')} placeholder={t('street')} form={address} />
            <Input name="number" label={t('number')} placeholder={t('number')} form={address} />
            <Input name="CO" label={t('co')} placeholder={t('co')} form={address} />
          </Form>
        </Card>
      </div>
    );
  }
}

export default PatientsManagePage;
