import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';

import { getRedirectParams } from 'utils/helpers';
import { translate } from 'utils/helpers';
import RouterLink from 'components/routerLink/router-link';
import cx from 'classnames';

import './menu.css';

const t = translate(['common', 'navigation']);

@inject('uiState', 'session', 'ui')
@observer
export class CustomMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  changeLangToEng = () => this.props.ui.changeLanguage('en');

  changeLangToSv = () => this.props.ui.changeLanguage('sv');

  goToUserProfile = () => {
    if (this.props.session.isAdmin()) {
      browserHistory.push(`/home/me/admin`);
    } else {
      browserHistory.push(`/home/me/secretary`);
    }
  };

  render() {
    const { uiState, session, ui, notLogged = false } = this.props;

    const isAdmin = session.isAdmin();
    const isSuperAdmin = session.isSuperAdmin();
    const { subDomain, link } = getRedirectParams(window.location.hostname);

    return (
      <div className="mobile-navbar">
        <Menu isOpen={this.state.menuOpen} onStateChange={state => this.handleStateChange(state)}>
          <ul>
            {!notLogged && (
              <li className="langs">
                <span
                  onClick={this.changeLangToEng}
                  className={cx({ isActive: ui.isActiveLanguage('en') })}
                >
                  EN
                </span>
                <span>/</span>
                <span
                  onClick={this.changeLangToSv}
                  className={cx({ isActive: ui.isActiveLanguage('sv') })}
                >
                  SV
                </span>
              </li>
            )}
            {(!isAdmin || isSuperAdmin) &&
              !notLogged && (
                <li>
                  <RouterLink
                    onClick={() => this.closeMenu()}
                    data-test-id="SurveysMob"
                    to="/home/surveys"
                  >
                    {t('surveys')}
                  </RouterLink>
                </li>
              )}
            {!isAdmin &&
              !notLogged && (
                <li>
                  <RouterLink
                    onClick={() => this.closeMenu()}
                    data-test-id="CareUnitsMob"
                    to="/home/care-units"
                  >
                    {t('careUnits')}
                  </RouterLink>
                </li>
              )}
            {isAdmin &&
              !notLogged && (
                <li>
                  <RouterLink
                    onClick={() => this.closeMenu()}
                    data-test-id="CareUnitsAdminMob"
                    to="/home/care-units/admin"
                  >
                    {t('careUnits')}
                  </RouterLink>
                </li>
              )}
            {isAdmin &&
              !notLogged && (
                <li>
                  <RouterLink
                    onClick={() => this.closeMenu()}
                    data-test-id="UsersMob"
                    to="/home/users"
                  >
                    {t('users')}
                  </RouterLink>
                </li>
              )}
            {isAdmin &&
              !notLogged && (
                <li>
                  <RouterLink
                    onClick={() => this.closeMenu()}
                    data-test-id="TemplatesMob"
                    to="/home/templates"
                  >
                    {t('templates')}
                  </RouterLink>
                </li>
              )}
            {isAdmin &&
              !notLogged && (
                <li>
                  <RouterLink
                    onClick={() => this.closeMenu()}
                    data-test-id="ReportsMob"
                    to="/home/reports"
                  >
                    {t('reports')}
                  </RouterLink>
                </li>
              )}
            {isSuperAdmin &&
              !notLogged && (
                <li>
                  <RouterLink
                    onClick={() => this.closeMenu()}
                    data-test-id="AdminsMob"
                    to="/home/admins"
                  >
                    {t('admins')}
                  </RouterLink>
                </li>
              )}

            {isAdmin &&
              !notLogged && (
                <li>
                  <RouterLink data-test-id="MappingMob" to="/home/mapping">
                    {t('mapping')}
                  </RouterLink>
                </li>
              )}
            {isAdmin &&
              !notLogged && (
                <li>
                  <RouterLink data-test-id="RoutingMob" to="/home/routing">
                    {t('routing')}
                  </RouterLink>
                </li>
              )}
            {!notLogged && (
              <li>
                <a
                  onClick={() => this.closeMenu()}
                  data-test-id="LoggedHelpMob"
                  href={`${link}/${subDomain}`}
                >
                  {t('help')}
                </a>
              </li>
            )}
            {notLogged && (
              <li>
                <RouterLink
                  onClick={() => this.closeMenu()}
                  data-test-id="UnloggedHelpMob"
                  to="/help"
                >
                  {t('help')}
                </RouterLink>
              </li>
            )}
            <li className="logout">
              {!isSuperAdmin &&
                !notLogged && (
                  <img src={require('assets/svg/sc-profile.svg')} onClick={this.goToUserProfile} />
                )}
              {notLogged ? null : <a onClick={() => session.logout()}>{t('logout')}</a>}
            </li>
          </ul>
        </Menu>
      </div>
    );
  }
}
