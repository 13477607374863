import { action } from 'mobx';
import { adminsService } from 'services/transport-layer';
import { UserModel } from 'models/user';
import { createDomainStore } from './base';
import { browserHistory } from 'react-router';
import UIStore from 'stores/ui';
import moment from 'moment';

const config = {
  name: 'admins',
  serviceToInject: adminsService,
  domainModel: UserModel,
  useQuery: true
};

export const {
  store: AdminsStore,
  listResolver: adminsResolver,
  itemResolver: adminResolver
} = createDomainStore(config);

function resetModelPasswords(model) {
  model.password = undefined;
  model.confirmPassword = undefined;
}

Object.assign(AdminsStore, {
  createUser(model) {
    model.viewModel.id = Math.random();
    model.viewModel.submit();

    UIStore.setToTransition();
    adminsService
      .createNew(
        Object.assign(model.serialize(), {
          _id: undefined,
          orgunitIds: undefined,
          noPasswordNotification: undefined,
          lastUpdate: undefined,
          orgunits: undefined
        })
      )
      .then(
        action(({ insertedId }) => {
          resetModelPasswords(model);
          model.id = insertedId;
          model.lastUpdate = moment().format();

          this.addOrUpdateItem(model.serialize());

          browserHistory.push(`/home/admins`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          resetModelPasswords(model);

          browserHistory.push({
            pathname: `/home/admins/new`,
            state: { error }
          });

          // make model dirty again!
          model.viewModel.lastUpdate = moment().format();
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  updateUser(id) {
    const model = this.getItem(id);
    model.viewModel.submit();
    UIStore.setToTransition();

    adminsService
      .update(
        id,
        Object.assign(model.serialize(), {
          _id: undefined,
          username: undefined,
          lastUpdate: undefined,
          orgunitIds: undefined,
          noPasswordNotification: undefined,
          orgunits: undefined
        })
      )
      .then(
        action(() => {
          browserHistory.push(`/home/admins`);
          UIStore.setOutOfTransition();

          // clean-up passwords
          resetModelPasswords(model);
        })
      )
      .catch(
        action(error => {
          resetModelPasswords(model);

          // make model dirty again!
          model.viewModel.lastUpdate = moment().format();

          UIStore.showError(error);
          UIStore.setOutOfTransition();
          browserHistory.push({
            pathname: `/home/admins/${id}/edit`,
            state: { error }
          });
          console.error(error);
        })
      );
  },
  delete(id) {
    UIStore.setToTransition();
    adminsService
      .delete(id)
      .then(
        action(() => {
          this.queryItems(false);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  }
});
