import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';
import Spinner from 'react-spinkit';
import moment from 'moment';
import { deserialize } from 'serializr';
import { withFormik, Form } from 'formik';
import { restClient } from 'services/transport-layer';

import { NavbarInfo } from 'components/navbar/navbar';
import Card from 'components/card/card';
import Input from 'components/input/input';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import Checkbox from 'components/checkbox/checkbox';
import BackTo from 'components/backTo/back-to';
import { AutoComplete } from 'components/downshift/routing-autocomplete';
import { Select } from 'components/downshift/routing-select';
import { dateFormat } from 'formatters/dates';

import Routing, { EMRModel } from 'stores/models/routing';

import { translate } from 'utils/helpers';
const t = translate(['common', 'routing']);

class FetchDropdown extends React.Component {
  state = { items: [], selectedItem: null, loaded: false, error: false };

  componentDidMount() {
    restClient
      .get(`/${this.props.type}`)
      .then(data => {
        this.setState({
          items: data.map(item => item.code),
          loaded: true
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: true });
      });
  }

  handleChange = selectedItem => {
    this.props.setFieldValue(this.props.name, selectedItem);
  };

  render() {
    if (this.state.error) {
      if (this.props.type === 'converters') return t('convertersNotLoaded');

      return t('sendersNotLoaded');
    }

    if (!this.state.loaded) {
      return (
        <div className="global-spinner">
          <Spinner name="double-bounce" fadeIn="none" />
        </div>
      );
    }

    return (
      <Select
        dataTestId={this.props.type === 'converters' ? 'converter' : 'sender'}
        placeholder={this.props.type === 'converters' ? t('converter') : t('sender')}
        items={this.state.items}
        selectedItem={this.props.selectedItem}
        onChange={this.handleChange}
      />
    );
  }
}

class TransformedAutocomplete extends React.Component {
  state = { items: [], loaded: false, error: false };

  componentDidMount() {
    restClient
      .get(`/${this.props.type}`)
      .then(data => {
        this.setState({
          items: data.data,
          loaded: true
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: true });
      });
  }

  handleChange = selectedItem => {
    this.props.setFieldValue(this.props.name, selectedItem._id);
  };

  render() {
    if (this.state.error) {
      return t('mappingNamesNotLoaded');
    }

    if (!this.state.loaded) {
      return <Spinner name="double-bounce" fadeIn="none" />;
    }

    return (
      <AutoComplete
        dataTestId={t('mapping')}
        placeholder={t('typeMappingName')}
        items={this.state.items}
        selectedItem={this.props.selectedItem}
        defaultSelectedItem={this.state.items.find(item => item._id === this.props.selectedItem)}
        onChange={this.handleChange}
      />
    );
  }
}

class TransformedFetchDropdown extends React.Component {
  state = { items: [t('templateTypeLocal'), t('templateTypeGlobal')], selectedItem: null };

  handleChange = selectedItem => {
    selectedItem === t('templateTypeLocal') ? (selectedItem = 0) : (selectedItem = 1);

    this.props.setFieldValue(this.props.name, selectedItem);
  };

  render() {
    let selectedItem;

    if (this.props.selectedItem === 0) {
      selectedItem = t('templateTypeLocal');
    } else if (this.props.selectedItem === 1) {
      selectedItem = t('templateTypeGlobal');
    }

    return (
      <Select
        dataTestId="templateType"
        placeholder={this.props.placeholder}
        items={this.state.items}
        selectedItem={selectedItem}
        onChange={this.handleChange}
      />
    );
  }
}

class RoutingManage extends Component {
  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  }

  routerWillLeave = action => {
    if (this.props.dirty && !this.forceUnload) {
      this.props.ui.showModal(
        () => {
          this.forceUnload = true;
          browserHistory.push(action);
        },
        null,
        t('yourWorkIsNotSaved')
      );

      return false;
    }
  };

  componentWillUnmount() {
    this.forceUnload = false;
  }

  isEditMode() {
    return this.props.route.edit;
  }

  render() {
    const { values, handleSubmit, handleChange, setFieldValue } = this.props;

    let titleText;
    let formClass;

    if (this.isEditMode()) {
      titleText = t('editRouting', { name: values.name });
      formClass = 'editRoutingForm';
    } else {
      titleText = t('addRouting');
      formClass = 'addRoutingForm';
    }

    return (
      <div className="routing-page action-footer-padded">
        <NavbarInfo content={<BackTo title={t('backToRoutingList')} to="/home/routing" />}>
          {titleText}
        </NavbarInfo>
        <div className="card-columns-container">
          <Form data-test-id={formClass}>
            <Card className="card-column fixed-width">
              <Input
                name="lastUpdate"
                data-test-id="lastUpdateFormat"
                label={t('lastUpdate')}
                placeholder={t('lastUpdate')}
                disabled
                value={values.lastUpdate ? dateFormat(moment(values.lastUpdate)) : t('never')}
                isFormik={true}
              />
              <Checkbox
                classNameRoot="form-padding"
                name="sign"
                label={t('signEmr')}
                isFormik={true}
                setFieldValue={setFieldValue}
                checked={values.sign}
              />
              <Checkbox
                classNameRoot="form-padding"
                name="test"
                label={t('testEmr')}
                isFormik={true}
                setFieldValue={setFieldValue}
                checked={values.test}
              />
              <Checkbox
                classNameRoot="form-padding"
                name="directPush"
                label={t('directPush')}
                isFormik={true}
                setFieldValue={setFieldValue}
                checked={values.directPush}
              />
            </Card>
            <Card className="card-column">
              <div className="card-scrollable-aria">
                <section className="card-inner-section">
                  <div className="column fixed-width">
                    <Input
                      required
                      name="name"
                      data-test-id="name"
                      label={t('name')}
                      placeholder={t('name')}
                      onChange={handleChange}
                      value={values.name}
                      isFormik={true}
                    />
                    <div className="custom-input-label-group">
                      <label className="custom-input-label" htmlFor="input-converter">
                        {t('converter')}
                        <span>{' *'}</span>
                      </label>
                    </div>
                    <FetchDropdown
                      required
                      placeholder={t('converter')}
                      name="converter"
                      type="converters"
                      selectedItem={values.converter}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="column">
                    <Input
                      name="description"
                      label={t('description')}
                      data-test-id="description"
                      placeholder={t('description')}
                      onChange={handleChange}
                      value={values.description}
                      isFormik={true}
                    />
                    <div className="custom-input-label-group">
                      <label className="custom-input-label" htmlFor="input-sender">
                        {t('sender')}
                        <span>{' *'}</span>
                      </label>
                    </div>
                    <FetchDropdown
                      required
                      placeholder={t('sender')}
                      name="sender"
                      type="senders"
                      selectedItem={values.sender}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <hr className="card-section-divider flexible" />
                  <div className="column fixed-width">
                    <Input
                      required
                      name="templateId"
                      data-test-id="templateId"
                      label={t('templateId')}
                      placeholder={t('templateId')}
                      onChange={event => {
                        const value = event.target.value;
                        if (!value) {
                          setFieldValue(`templateId`, '');
                          return;
                        }
                        if (/^([0-9-]+)$/.test(value)) {
                          handleChange(event);
                        }
                      }}
                      value={values.templateId}
                      isFormik={true}
                    />
                    <div className="custom-input-label-group">
                      <label className="custom-input-label" htmlFor="input-templateType">
                        {t('templateType')}
                        <span>{' *'}</span>
                      </label>
                    </div>
                    <TransformedFetchDropdown
                      placeholder={t('templateType')}
                      name="templateType"
                      selectedItem={values.templateType}
                      onChange={this.handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="column">
                    <div className="custom-input-label-group">
                      <label className="custom-input-label" htmlFor="input-mappingId">
                        {t('mappingName')}
                        <span>{' *'}</span>
                      </label>
                    </div>
                    <TransformedAutocomplete
                      required
                      data-test-id="mappingName"
                      placeholder={t('data-test-id="Name"')}
                      name="mappingId"
                      type="mappings"
                      selectedItem={values.mappingId}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </section>
              </div>
            </Card>
            <ActionFooter>
              <Button action="primary" color="wisteria" onClick={handleSubmit}>
                {t('save')}
              </Button>
            </ActionFooter>
          </Form>
        </div>
      </div>
    );
  }
}

export default inject('routing', 'ui')(
  observer(
    withFormik({
      mapPropsToValues: props => {
        const { params, routing } = props;
        const model = props.route.edit
          ? routing.getItem(params.id)
          : deserialize(Routing, { EMR: new EMRModel({}) });
        const emr = model.EMR;
        return {
          name: model.name,
          description: model.description || '',
          converter: model.converter,
          sender: model.sender,
          lastUpdate: model.lastUpdate,
          templateId: emr.templateId,
          mappingId: emr.mappingId,
          templateType: emr.templateType,
          sign: emr.sign,
          test: emr.test,
          directPush: emr.directPush
        };
      },
      handleSubmit(values, { props, resetForm }) {
        const { routing, route, params } = props;
        const preparedData = {
          name: values.name,
          description: values.description,
          converter: values.converter,
          sender: values.sender,
          EMR: {
            templateId: values.templateId,
            mappingId: values.mappingId,
            templateType: values.templateType,
            sign: values.sign || false,
            test: values.test || false,
            directPush: values.directPush || false
          }
        };

        if (route.edit) {
          routing.update(params.id, preparedData);
        } else {
          routing.create(preparedData);
        }

        resetForm(values);
      }
    })(RoutingManage)
  )
);
