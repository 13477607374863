import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { browserHistory, Link } from 'react-router';
import { resolve, nested } from 'table-resolver';

import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';
import SurveyStats from 'components/surveyStats/survey-stats';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import Input from 'components/input/input';
import { Tooltip } from 'react-tippy';

import { timeFormatter, applySortingServerSide, surveyStatus } from 'formatters/tables';
import { surveyStatusMap } from 'stores/models/survey';
import moment from 'moment';

import { translate, HideIf } from 'utils/helpers';
import { computed } from 'mobx';

import './surveys.css';

const t = translate(['common', 'surveys']);

class SurveyPage extends Component {
  constructor(props) {
    super(props);

    const quickLinkCell = (id, { rowData: model }) => {
      // FIXME: v2 backend does not support v2 for now
      // if (props.session.isAdmin() && !props.session.isSuperAdmin()) {
      //   return (
      //     <Link to={`/home/surveys/${id}/${model.guid}/trail`}>
      //       <img src={require('assets/svg/sc-compare.svg')} />
      //     </Link>
      //   );
      // }

      if (props.session.isAdmin() && !props.session.isSuperAdmin()) {
        return null;
      }

      switch (model.status) {
        case surveyStatusMap.qa:
          return (
            <Tooltip title={t('edit')} arrow="true" distance={20}>
              <Link to={`/home/surveys/${id}/${model.guid}/edit`}>
                <img src={require('assets/svg/sc-edit.svg')} />
              </Link>
            </Tooltip>
          );
        case surveyStatusMap.waitingForQa:
          return (
            <Tooltip title={t('startQa')} arrow="true" distance={20}>
              <img
                src={require('assets/svg/sc-start-qa.svg')}
                onClick={() => props.surveys.setSurveyToQA(id)}
              />
            </Tooltip>
          );
      }
    };

    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.surveys.viewState.sortState
    );

    this.columns = [
      {
        property: 'visitDate',
        header: {
          label: t('visitDate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'template.name',
        header: {
          label: t('templateName'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'orgunit.name',
        header: {
          label: t('careUnit'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'patient.name',
        header: {
          label: t('patientName'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'doctor.name',
        header: {
          label: t('doctorName'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'status',
        header: {
          label: t('status'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [surveyStatus]
        }
      },
      {
        property: 'id',
        header: {
          label: t('quickLink')
        },
        cell: {
          formatters: [quickLinkCell]
        }
      }
    ];
  }

  onSortClick = property => this.props.surveys.viewState.applySort(property);
  onPageClick = selected => this.props.surveys.viewState.changePage(selected);

  onRowClick = row => {
    const onClick = e => {
      e.preventDefault();
      e.stopPropagation();

      // TODO: better way to track cell
      if (e.target.nodeName === 'IMG') {
        return;
      }

      if (this.props.session.isAdmin() && !this.props.session.isSuperAdmin()) {
        // FIXME: v2 backend does not support v2 for now
        // browserHistory.push(`/home/surveys/${row.id}/${row.guid}/trail`);
      } else {
        browserHistory.push(`/home/surveys/${row.id}/${row.guid}`);
      }
    };

    return { onClick };
  };

  listData = () => resolve({ columns: this.columns, method: nested })(this.props.surveys.list);

  validateDate = value => {
    if (!value || value.includes('_')) {
      return true;
    }

    return moment(value, 'YYYY-MM-DD').isValid();
  };

  onFilterDate = type => value => {
    if (!value.includes('_')) {
      this.props.surveys.viewState.changeDate(type, value);
    }
  };

  onFilterLastUpdateDate = type => value => {
    if (!value.includes('_')) {
      this.props.surveys.viewState.changeLastUpdate(type, value);
    }
  };

  detectStatusFilterColor = id => {
    const { viewState } = this.props.surveys;

    if (viewState.surveyStatusesState.value.includes(id)) {
      return 'green';
    }

    return 'peach';
  };

  isFiltered = () => this.props.surveys.viewState.isFiltered;

  @computed
  get searchIsOpen() {
    return this.props.surveys.viewState.openState.value;
  }

  render() {
    const { surveys } = this.props;

    const stats = (
      <div className="survey-stats">
        <SurveyStats
          status={t('waitingForQa')}
          statusId={surveyStatusMap.waitingForQa}
          color="green"
        />
        <SurveyStats
          status={t('requiringAttention')}
          statusId={surveyStatusMap.requiringAttention}
          color="peach"
        />
        <SurveyStats status={t('qaStage')} statusId={surveyStatusMap.qa} color="wisteria" />
        <SurveyStats
          status={t('importGaveUp')}
          statusId={surveyStatusMap.importGaveUp}
          color="butterscotch"
        />
      </div>
    );

    return (
      <div className="surveys-page action-footer-padded">
        <NavbarInfo content={stats}>{t('listTitle')}</NavbarInfo>
        <div className="panel">
          <DataTable
            className="table-fixed table-ellipsed"
            data={this.listData}
            columns={this.columns}
            rowKey={'id'}
            onRow={this.onRowClick}
            isLoading={surveys.isListLoading}
            pageState={surveys.viewState.pageState}
            pageCount={surveys.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel filterState={surveys.viewState}>
          <FilterView header={t('filters.status.header')} subheader={t('filters.status.desc')}>
            <div className="filter-status">
              <div className="hint">
                <label>{t('filters.status.chooseStatus')}</label>
              </div>
              <div className="types-wrap">
                <div className="types">
                  <SurveyStats
                    status={t('requireSITHSConfirmation')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.requireSITHSConfirmation}
                    onClick={surveys.viewState.changeStatus}
                  />
                  <SurveyStats
                    status={t('requiringAttention')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.requiringAttention}
                    onClick={surveys.viewState.changeStatus}
                  />
                  <SurveyStats
                    status={t('waitingForQa')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.waitingForQa}
                    onClick={surveys.viewState.changeStatus}
                  />
                  <SurveyStats
                    status={t('importGaveUp')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.importGaveUp}
                    onClick={surveys.viewState.changeStatus}
                  />
                  <SurveyStats
                    status={t('qaStage')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.qa}
                    onClick={surveys.viewState.changeStatus}
                  />
                </div>
                <div className="types">
                  <SurveyStats
                    status={t('readyForEMR')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.readyForEMR}
                    onClick={surveys.viewState.changeStatus}
                  />
                  <SurveyStats
                    status={t('finished')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.finished}
                    onClick={surveys.viewState.changeStatus}
                  />
                  <SurveyStats
                    status={t('imported')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.imported}
                    onClick={surveys.viewState.changeStatus}
                  />
                  <SurveyStats
                    status={t('archived')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.archived}
                    onClick={surveys.viewState.changeStatus}
                  />
                  <SurveyStats
                    status={t('defective')}
                    detectColor={this.detectStatusFilterColor}
                    small
                    forceEmpty
                    statusId={surveyStatusMap.defective}
                    onClick={surveys.viewState.changeStatus}
                  />
                </div>
              </div>
            </div>
          </FilterView>
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              name="value"
              form={surveys.viewState.patientNameState}
              label={t('filters.name.patientNameLabel')}
              leftText
              placeholder={t('filters.name.patientNamePlaceholder')}
              onChange={surveys.viewState.changePatientName}
            />
            <Input
              name="value"
              form={surveys.viewState.templateNameState}
              label={t('filters.name.templateNameLabel')}
              leftText
              placeholder={t('filters.name.templateNamePlaceholder')}
              onChange={surveys.viewState.changeTemplateName}
            />
            <Input
              name="value"
              form={surveys.viewState.doctorByState}
              label={t('filters.name.doctorByLabel')}
              leftText
              placeholder={t('filters.name.doctorByPlaceholder')}
              onChange={surveys.viewState.changeDoctorBy}
            />
            <Input
              name="value"
              form={surveys.viewState.careUnitNameState}
              label={t('filters.name.careUnitNameLabel')}
              leftText
              placeholder={t('filters.name.careUnitNamePlaceholder')}
              onChange={surveys.viewState.changeCareUnitName}
            />
          </FilterView>
          <FilterView header={t('filters.date.header')} subheader={t('filters.date.desc')}>
            <Input
              name="value"
              form={surveys.viewState.dateFromState}
              label={t('filters.date.fromLabel')}
              leftText
              placeholder={t('filters.date.fromPlaceholder')}
              masked
              mask="1111-11-11"
              validator={this.validateDate}
              validationText={t('filters.errors.pleaseInputCorrectDate')}
              onChange={this.onFilterDate('from')}
            />
            <Input
              name="value"
              form={surveys.viewState.dateToState}
              label={t('filters.date.toLabel')}
              leftText
              placeholder={t('filters.date.toPlaceholder')}
              masked
              mask="1111-11-11"
              validator={this.validateDate}
              validationText={t('filters.errors.pleaseInputCorrectDate')}
              onChange={this.onFilterDate('to')}
            />
            <Input
              name="value"
              form={surveys.viewState.lastUpdateFromState}
              label={t('filters.lastUpdate.labelFrom')}
              leftText
              placeholder={t('filters.lastUpdate.placeholderFrom')}
              masked
              mask="1111-11-11"
              validator={this.validateDate}
              validationText={t('filters.errors.pleaseInputCorrectDate')}
              onChange={this.onFilterLastUpdateDate('from')}
            />
            <Input
              name="value"
              form={surveys.viewState.lastUpdateToState}
              label={t('filters.lastUpdate.labelTo')}
              leftText
              placeholder={t('filters.lastUpdate.placeholderTo')}
              masked
              mask="1111-11-11"
              validator={this.validateDate}
              validationText={t('filters.errors.pleaseInputCorrectDate')}
              onChange={this.onFilterLastUpdateDate('to')}
            />
          </FilterView>
        </FiltersPanel>
        <ActionFooter>
          <Button
            action="secondary"
            disabled={this.searchIsOpen}
            color={!this.searchIsOpen ? 'green' : 'steel disabled'}
            onClick={this.props.surveys.exportExcel}
          >
            {t('export')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={surveys.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
          <Button action="primary" color="wisteria" onClick={surveys.viewState.toggleOpen}>
            {!this.searchIsOpen ? t('openFilter') : t('closeFilter')}
          </Button>
        </ActionFooter>
      </div>
    );
  }
}

export default inject('surveys', 'session')(observer(SurveyPage));
