import { templatesService } from 'services/transport-layer';
import TemplateModel from 'models/template';
import { createDomainStore } from './base';

const config = {
  name: 'templatesSmall',
  serviceToInject: templatesService,
  domainModel: TemplateModel,
  useQuery: true,
  selectors: {
    dataSelector: (data, rawData) => {
      if (data.data) {
        return data.data;
      }

      return data;
    }
  }
};

export const {
  store: TemplatesSmallStore,
  listResolver: templatesResolver,
  itemResolver: templateResolver
} = createDomainStore(config);
