import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { browserHistory } from 'react-router';
import { updateFocus } from 'utils/helpers';

import Card from 'components/card/card';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import Input from 'components/input/input';
import Form from 'components/form/form';
import { NavbarInfo } from 'components/navbar/navbar';
import BackTo from 'components/backTo/back-to';

import { translate, validateEmail, notEmptyValidator } from 'utils/helpers';
import { personNumberValidator } from 'utils/custom-validations';
const t = translate(['common', 'users', 'patients']);

class PasswordsModel {
  @observable oldPass;
  @observable newPass;
  @observable toMatchPass;
}

@inject('profileAdmin', 'session', 'profileSecretary', 'ui')
@observer
class ProfilePage extends Component {
  @observable passwords = new PasswordsModel();
  store;

  static propTypes = {
    profileAdmin: PropTypes.object.isRequired, // store
    profileSecretary: PropTypes.object.isRequired, // store
    route: PropTypes.shape({ admin: PropTypes.bool.isRequired }).isRequired // passed from router
  };

  componentWillMount() {
    this.store = this.isAdmin ? this.props.profileAdmin : this.props.profileSecretary;
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  }

  routerWillLeave = action => {
    if (this.store.list[0].viewModel.isDirty && !this.forceUnload) {
      this.props.ui.showModal(
        () => {
          this.forceUnload = true;
          browserHistory.push(action);
        },
        null,
        t('yourWorkIsNotSaved')
      );

      return false;
    }
  };

  componentWillUnmount() {
    this.store.list[0].viewModel.reset();
    this.forceUnload = false;
  }

  get isAdmin() {
    return this.props.route.admin;
  }

  onSubmitProfile = () => {
    updateFocus();
    if (this.form.isValid()) {
      this.store.updateProfile();
    }
  };

  onSubmitPassword = () => {
    if (!this.passwords.oldPass) {
      this.props.ui.showError({ messageId: 'errors.pleaseInputOldPassword' }, t);
      return;
    }

    if (!this.passwords.newPass) {
      this.props.ui.showError({ messageId: 'errors.pleaseInputNewPassword' }, t);
      return;
    }

    if (!this.passwords.toMatchPass) {
      this.props.ui.showError({ messageId: 'errors.pleaseInputToMatchPassword' }, t);
      return;
    }

    if (this.passwords.newPass !== this.passwords.toMatchPass) {
      this.props.ui.showError({ messageId: 'errors.passwordsShouldMatch' }, t);
      return;
    }

    this.store.updatePassword(this.passwords.oldPass, this.passwords.newPass);
    this.passwords = new PasswordsModel();
  };

  passwordValidator = (value, model) => value === this.passwords.newPass;

  personNumberValidator = (value, model) => {
    if (!value) {
      return true;
    }

    return personNumberValidator(value, model).isValid;
  };

  render() {
    const model = this.store.list[0].viewModel; // it always only one
    const { session } = this.props;
    let secretaryFields;

    if (!this.isAdmin) {
      secretaryFields = [
        <Input
          key="personalNumber"
          data-test-id="personalNumber"
          name="personalNumber"
          label={t('personalNumber')}
          validator={this.personNumberValidator}
          validationText={t('errors.inputCorrectPersonalNumber')}
          masked
          mask="11111111-1111"
        />,
        <Input
          key="hsaId"
          data-test-id="hsaid"
          name="hsaId"
          required
          label={t('hsaId')}
          placeholder={t('hsaId')}
          onChange={value => (model.hsaId = value.toUpperCase())}
        />
      ];
    }
    const isAdmin = session.isAdmin();
    return (
      <div className="users-page action-footer-padded">
        <NavbarInfo
          content={
            <BackTo
              title={t(isAdmin ? 'backToCareList' : 'backToSurveyList')}
              to={isAdmin ? '/home/care-units/admin' : '/home/surveys'}
            />
          }
        >
          {t('editProfile', { name: model.name }) + (model.isDirty ? ' *' : '')}
        </NavbarInfo>
        <Card>
          <Form
            ref={form => (this.form = form)}
            form={model}
            autoComplete="nope"
            data-test-id="updateProfileForm"
          >
            <Input
              name="username"
              label={t('username')}
              placeholder={t('username')}
              disabled={true}
              autoComplete="nope"
            />
            <Input
              name="name"
              label={t('name')}
              placeholder={t('name')}
              validator={notEmptyValidator}
              validationText={t('errors.pleaseInputField')}
              required
            />
            <Input
              name="email"
              label={t('email')}
              placeholder={t('email')}
              type="email"
              validator={validateEmail}
              validationText={t('errors.emailShouldMatchRules')}
            />
            {secretaryFields}
            <ActionFooter>
              <Button type="submit" action="primary" color="peach" onClick={this.onSubmitPassword}>
                {t('updatePassword')}
              </Button>
              <Button
                type="submit"
                action="primary"
                data-test-id="submitProfileForm"
                color="green"
                onClick={this.onSubmitProfile}
              >
                {t('updateProfile')}
              </Button>
            </ActionFooter>
          </Form>
        </Card>
        <Card className="padded-top20">
          <Input
            name="oldPass"
            type="password"
            label={t('oldPassword')}
            placeholder={t('oldPassword')}
            validator={notEmptyValidator}
            validationText={t('errors.pleaseInputField')}
            autoComplete="nope"
            form={this.passwords}
          />
          <Input
            name="newPass"
            type="password"
            label={t('newPassword')}
            placeholder={t('newPassword')}
            validator={notEmptyValidator}
            validationText={t('errors.pleaseInputField')}
            autoComplete="nope"
            form={this.passwords}
          />
          <Input
            name="toMatchPass"
            type="password"
            label={t('confirmNewPassword')}
            placeholder={t('confirmNewPassword')}
            validator={this.passwordValidator}
            validationText={t('errors.passwordsShouldMatch')}
            autoComplete="nope"
            form={this.passwords}
          />
        </Card>
      </div>
    );
  }
}

export default ProfilePage;
