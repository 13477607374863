import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { NavbarInfo } from 'components/navbar/navbar';
import BackTo from 'components/backTo/back-to';
import Tabs from 'components/tabs/tabs';

import { translate } from 'utils/helpers';
const t = translate(['common', 'surveys']);

class SurveyPageWrapper extends Component {
  componentWillUnmount() {
    this.props.trail.viewState.resetAll();
  }

  render() {
    const { children, params, location } = this.props;
    const backTo = <BackTo title={t('backToSurveysList')} to="/home/surveys" />;
    const title = location.pathname.includes('edit') ? t('editTitle') : t('detailsTitle');

    return (
      <div>
        <NavbarInfo content={backTo}>{title}</NavbarInfo>
        <Tabs surveyId={params.id} surveyGUID={params.guid} />
        {children}
      </div>
    );
  }
}

export default inject('surveys', 'trail')(observer(SurveyPageWrapper));
