import { action, computed } from 'mobx';
import UIStore from './ui';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { nursesService } from 'services/transport-layer';
import { UserModel } from 'models/user';
import { createDomainStore } from './base';

export const { store: NursesStore, listResolver: nursesResolver } = createDomainStore({
  name: 'nurses',
  serviceToInject: nursesService,
  domainModel: UserModel,
  useQuery: true,
  selectors: {
    paramsSelector: nextState => [nextState.params.id],
    dataSelector: (data, rawData) => {
      return data.map(user => {
        user.careUnitId = rawData.orgunit._id;
        return user;
      });
    }
  }
});

Object.assign(NursesStore, {
  delete(careUnitId, userId) {
    UIStore.setToTransition();
    nursesService
      .unlinkFromCareUnit(careUnitId, userId)
      .then(
        action(() => {
          this.queryItems(true, [careUnitId]);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  }
});
