import React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

const RouterLink = inject('ui')(
  observer(({ className, to, ui, children, exact = false, ...rest }) => (
    <Link className={cx(className, { active: ui.isActiveNavigation(to, exact) })} {...rest} to={to}>
      {children}
    </Link>
  ))
);

export default RouterLink;
