import { BaseService } from '../base-service';

export class ReportsService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/reports' });
  }

  query(params = {}, cancelToken) {
    params = { ...params };

    return this.get('/', { params, cancelToken });
  }

  fetch() {
    return this.get('/');
  }
}
