import { Model } from './utils';
import { serializable, object, alias, custom } from 'serializr';
import moment from 'moment';

class UserTrail {
  @serializable username;
  @serializable name;
  @serializable role;
  @serializable email;
  @serializable hsaId;
}

export default class Trail extends Model {
  @serializable(alias('_id'))
  id;

  @serializable(alias('sumetaId'))
  surveyId;

  @serializable(alias('GUID'))
  surveyGUID;

  @serializable event;

  @serializable(custom(() => {}, trailDeserializer))
  value;

  @serializable(object(UserTrail))
  user;

  @serializable(custom(() => {}, date => moment(date)))
  timestamp;
}

// TODO: support dynamic deserializing in future releases
function trailDeserializer(value) {
  return value;
}
