import React from 'react';
import { Router, Route, browserHistory, IndexRedirect, applyRouterMiddleware } from 'react-router';
import { useScroll } from 'react-router-scroll';

import Surveys from 'pages/surveys/surveys';
import SurveyEdit from 'pages/surveys/survey-edit';
import SurveyDetails from 'pages/surveys/survey-details';
import SurveyAuditTrail from 'pages/surveys/audit-trail';
import SurveyAuditTrailDiff from 'pages/surveys/audit-trail-diff';
import SurveyDetailsUI from 'pages/surveys/survey-details-wrapper';

import CareUnitsSmall from 'pages/care-units/care-units-small';
import CareUnits from 'pages/care-units/care-units';
import CareUnitsManage from 'pages/care-units/care-unit-manage';

import Patients from 'pages/patients/patients';
import PatientManage from 'pages/patients/patient-manage';

import Nurses from 'pages/nurses/nurses';

import Users from 'pages/users/users';
import UserManage from 'pages/users/users-manage';

import ProfileManage from 'pages/me/profile-manage';
import Reports from 'pages/reports/reports';

import Mapping from 'pages/mapping/mapping';
import MappingManage from 'pages/mapping/mapping-manage';

import Routing from 'pages/routing/routing';
import RoutingManage from 'pages/routing/routing-manage';

import Templates from 'pages/templates/templates';
import AssignTemplates from 'pages/templates/templates-assign';
import TemplateManage from 'pages/templates/template-manage';

import Login from 'pages/login/login';
import NotFound from 'pages/not-found';
import LoggedUI from 'pages/logged-ui';

import Version from 'components/version/version';

import SessionStore from 'stores/session';
import {
  requireAuth,
  requireUnAuth,
  componentResolver,
  detectHelpAuth,
  recoveryRouteHandler
} from 'stores/mixins';
import { surveysResolver, surveyDetailsResolver, surveyEMRDataResolver } from 'stores/surveys';
import { patientsResolver, patientResolver } from 'stores/patients';
import { nursesResolver } from 'stores/nurses';
import { mappingsResolver, mappingResolver } from 'stores/mapping';
import { routingsResolver, routingResolver } from 'stores/routing';
import { usersResolver, userResolver } from 'stores/users';
import { adminProfileResolver, secretaryProfileResolver } from 'stores/profile';
import { adminsResolver, adminResolver } from 'stores/admins';
import { templatesResolver, templateResolver } from 'stores/templates';
import { careUnitsSmallResolver } from 'stores/care-units-small';
import { careUnitsResolver, careUnitResolver } from 'stores/care-units';
import { reportsResolver } from 'stores/reports';
import { auditTrailResolver, auditTrailDetailsResolver } from 'stores/survey-audit-trail';
import UIStore from 'stores/ui';

// Always scroll to top (https://github.com/taion/react-router-scroll)
const render = applyRouterMiddleware(useScroll(() => [0, 0]));

export default function SwipecareRouter() {
  return (
    <Router history={browserHistory} render={render}>
      <Route path="/help" onEnter={detectHelpAuth} />
      <Route path="/login" component={Login} onEnter={requireUnAuth} />
      <Route path="/login/admin" component={Login} onEnter={requireUnAuth} admin={true} />
      <Route
        path="/autologin/:token/*"
        onEnter={({ params }) => SessionStore.sitshSuccess(params.token)}
      />
      <Route
        path="/autologinAdmin/:token/*"
        onEnter={({ params }) => SessionStore.sitshAdminSuccess(params.token)}
      />
      <Route
        path="/recovery/:token"
        onEnter={recoveryRouteHandler}
        component={Login}
        recovery={true}
      />
      <Route path="/" onEnter={requireAuth}>
        <IndexRedirect to="home" />
        <Route path="home" component={LoggedUI}>
          <IndexRedirect to="surveys" />
          <Route
            path="surveys"
            getComponent={componentResolver(Surveys)}
            onEnter={surveysResolver}
          />
          <Route path="surveys/" component={SurveyDetailsUI}>
            <Route
              path=":id/:guid"
              getComponent={componentResolver(SurveyDetails)}
              onEnter={surveyDetailsResolver}
            />
            <Route
              path=":id/:guid/edit"
              getComponent={componentResolver(SurveyEdit)}
              onEnter={surveyEMRDataResolver}
            />
            <Route
              path=":id/:guid/trail"
              getComponent={componentResolver(SurveyAuditTrail)}
              onEnter={auditTrailResolver}
            />
          </Route>

          <Route
            path="surveys/:id/:guid/trail/:type/diff"
            getComponent={componentResolver(SurveyAuditTrailDiff)}
            onEnter={auditTrailDetailsResolver}
          />

          <Route
            path="care-units"
            getComponent={componentResolver(CareUnitsSmall)}
            onEnter={careUnitsSmallResolver}
          />
          <Route
            path="care-units/:careUnitId/patients"
            getComponent={componentResolver(Patients)}
            onEnter={patientsResolver}
          />
          <Route
            path="care-units/:careUnitId/patients/new"
            getComponent={componentResolver(PatientManage)}
          />
          <Route
            path="care-units/:careUnitId/patients/:patientId/edit"
            getComponent={componentResolver(PatientManage)}
            onEnter={patientResolver}
            edit={true}
          />
          <Route
            path="care-units/admin"
            getComponent={componentResolver(CareUnits)}
            onEnter={careUnitsResolver}
          />
          <Route path="care-units/admin/new" getComponent={componentResolver(CareUnitsManage)} />
          <Route
            path="care-units/admin/:id/edit"
            getComponent={componentResolver(CareUnitsManage)}
            onEnter={careUnitResolver}
            edit={true}
          />
          <Route
            path="care-units/admin/:careUnitId/patients"
            getComponent={componentResolver(Patients)}
            onEnter={patientsResolver}
          />
          <Route
            path="care-units/admin/:id/nurses"
            getComponent={componentResolver(Nurses)}
            onEnter={nursesResolver}
          />

          <Route path="users" getComponent={componentResolver(Users)} onEnter={usersResolver} />
          <Route path="users/new" getComponent={componentResolver(UserManage)} />
          <Route
            path="users/:id/edit"
            getComponent={componentResolver(UserManage)}
            onEnter={userResolver}
            edit={true}
          />

          <Route
            path="admins"
            getComponent={componentResolver(Users)}
            onEnter={adminsResolver}
            admin={true}
          />
          <Route path="admins/new" getComponent={componentResolver(UserManage)} admin={true} />
          <Route
            path="admins/:id/edit"
            getComponent={componentResolver(UserManage)}
            onEnter={adminResolver}
            edit={true}
            admin={true}
          />

          <Route
            path="me/admin"
            getComponent={componentResolver(ProfileManage)}
            onEnter={adminProfileResolver}
            admin={true}
          />
          <Route
            path="me/secretary"
            getComponent={componentResolver(ProfileManage)}
            onEnter={secretaryProfileResolver}
            admin={false}
          />

          <Route
            path="reports"
            getComponent={componentResolver(Reports)}
            onEnter={reportsResolver}
          />

          <Route
            path="templates"
            getComponent={componentResolver(Templates)}
            onEnter={templatesResolver}
          />
          <Route
            path="templates/:id/assign"
            getComponent={componentResolver(AssignTemplates)}
            onEnter={templatesResolver}
            assign={true}
          />
          <Route
            path="templates/:id/assign/:progenitorId"
            getComponent={componentResolver(AssignTemplates)}
            onEnter={templatesResolver}
            assign={true}
          />
          <Route
            path="templates/:id/edit"
            getComponent={componentResolver(TemplateManage)}
            onEnter={templateResolver}
            edit={true}
          />
          <Route path="templates/new" getComponent={componentResolver(TemplateManage)} />

          <Route
            path="mapping"
            getComponent={componentResolver(Mapping)}
            onEnter={mappingsResolver}
          />
          <Route path="mapping/new" getComponent={componentResolver(MappingManage)} />

          <Route
            path="mapping/:id/edit"
            getComponent={componentResolver(MappingManage)}
            onEnter={mappingResolver}
            edit={true}
          />

          <Route
            path="routing"
            getComponent={componentResolver(Routing)}
            onEnter={routingsResolver}
          />

          <Route path="routing/new" getComponent={componentResolver(RoutingManage)} />

          <Route
            path="routing/:id/edit"
            getComponent={componentResolver(RoutingManage)}
            onEnter={routingResolver}
            edit={true}
          />
        </Route>
      </Route>
      <Route path="version" component={Version} />
      <Route path="*" component={NotFound} />
    </Router>
  );
}
