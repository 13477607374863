import React from 'react';

import '../../pages/surveys/survey-details.css';

function getQuestionDataForRendering(question) {
  let value;
  let note;
  let result;

  switch (question.type) {
    case 'strikersoft.question.text':
    case 'strikersoft.question.number':
      note = (question.note || {}).value;
      value = question.value;
      break;
    case 'strikersoft.question.singleSelect':
      note = (question.note || {}).value;
      if (question.selected !== undefined) {
        value = question.answers[question.selected].value;
      }
      break;
    case 'strikersoft.question.multiSelect':
      note = (question.note || {}).value;
      value = (question.selected || [])
        .map(selected => question.answers[selected].value)
        .join(', ');
      break;
    case 'strikersoft.question.collection':
      note = (question.note || {}).value;
      result = getGroupsDataForRendering(question.items);
      if (note || (result.length && question.title)) {
        result.unshift({
          type: question.type,
          title: question.title ? question.title : '',
          note
        });
      }

      return result;
  }

  // for all question types except collection
  if (value === undefined && note) {
    value = note;
    note = undefined;
  }

  return value || value === 0
    ? [
        {
          title: question.title,
          value,
          note
        }
      ]
    : [];
}

function getGroupsDataForRendering(groups) {
  const groupsData = [];

  groups.forEach(group => {
    if (group.questions) {
      group.questions.forEach(question => {
        const questionData = getQuestionDataForRendering(question);
        if (questionData.length) {
          if (group.title && question.type !== 'strikersoft.question.collection') {
            questionData.forEach(questionDataItem => {
              questionDataItem.title = group.title + ': ' + questionDataItem.title;
            });
          }
          groupsData.push(...questionData);
        }
      });
    }
  });

  return groupsData;
}

export default function SurveySection({ section }) {
  const data = [];

  section.subsections.forEach(subsection => {
    const groupsData = getGroupsDataForRendering(subsection.groups);

    if (groupsData.length) {
      data.push({
        title: subsection.title,
        questions: groupsData,
        note: []
      });
    }
  });

  function questionValueHandler(question) {
    if (question.note) {
      if (question.value === undefined || question.value === '' || question.value === null) {
        return question.note;
      }
      return question.value + ', ' + question.note;
    }
    return question.value;
  }

  return (
    <div className="super-block">
      <h1 className="super-header">{section.title}</h1>
      {data.map((section, idx) => (
        <div className="block" key={idx}>
          <h2 className="header">{section.title}</h2>
          <table data-test-id={`SurveyDetail_${section.title}`}>
            <tbody>
              {section.questions.map((question, x) => (
                <tr key={x}>
                  <td data-target={question.type ? 'group' : undefined}>{question.title}</td>
                  <td>{questionValueHandler(question)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
