import React from 'react';
import { inject, observer } from 'mobx-react';

import './header.css';

function Header({ uiState }) {
  return (
    <div
      className="app-header"
      style={{ transform: 'translateY(-' + uiState.pageYOffset * 1.2 + 'px)' }}
    />
  );
}

export default inject('uiState')(observer(Header));
