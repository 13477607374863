import { BaseService } from '../base-service';

export class PatientsService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/orgunits' });
  }

  query(params = {}, cancelToken, careUnitId) {
    params = { ...params };

    return this.get(`/${careUnitId}/patients`, { params, cancelToken });
  }

  fetch(careUnitId) {
    return this.get(`/${careUnitId}/patients`);
  }

  fetchOne([careUnitId, patientId]) {
    return this.get(`/${careUnitId}/patients/${patientId}`);
  }

  createNew(careUnitId, data = {}) {
    return this.post(`/${careUnitId}/patients`, { data });
  }

  update(careUnitId, patientId, data = {}) {
    return this.put(`/${careUnitId}/patients/${patientId}`, { data });
  }

  delete(careUnitId, patientId) {
    return this.del(`/${careUnitId}/patients/${patientId}`);
  }
}
