import i18next from '../i18n';
import { observer } from 'mobx-react';
import React from 'react';
import FileSaver from 'file-saver';

export const translate = dicts => (value, opts) => i18next.t(value, { ns: [...dicts], ...opts });
export const existsTranslate = dicts => (value, opts) =>
  i18next.exists(value, { ns: [...dicts], ...opts });

export function encodeUrlQuery(params, forceEncode = true) {
  return Object.keys(params)
    .map(
      key =>
        (forceEncode ? encodeURIComponent(key) : key) +
        '=' +
        (forceEncode ? encodeURIComponent(params[key]) : params[key])
    )
    .join('&');
}

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email) {
  if (!email) {
    return true;
  }

  return emailPattern.test(email);
}

export const HideIf = observer(({ check, children }) => {
  const isValid = typeof check === 'function' ? check() : check;

  if (isValid) {
    return children;
  }

  return null;
});

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function generateGuid() {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function updateFocus() {
  const elements = document.querySelectorAll('[required]');
  const firstEl = elements[0];

  if (firstEl) {
    firstEl.scrollIntoView(false);
  }
}

export function getRedirectParams(hostname = '') {
  const hostnameParts = hostname.split('.');
  let subDomain;
  const [domain, env] = hostnameParts;

  switch (domain) {
    case 'aleris':
    case 'ptj':
      subDomain = 'stelpahjalpen';
      break;
    case 'capio':
      subDomain = 'capiohjalpen';
      break;
    case 'demo':
      subDomain = 'demo';
      break;
    case 'ssh':
      subDomain = 'sshhjalpen';
      break;
    case 'sophiahemmet':
      subDomain = 'sophiahjalpen';
      break;
    default:
      subDomain = '';
  }
  const link =
    env === 'swipecare' ? 'https://help.swipecare.se' : 'http://swipehelp.strikersoft.com';

  return { subDomain, link };
}

export function exportJSON(data = [], name) {
  try {
    const file = new Blob([data], { type: 'text/plain;charset=utf-8' });

    FileSaver.saveAs(file, `${name}.json`);
  } catch (err) {
    console.error(err);
  }
}

export function getFileExtension(fileName) {
  return fileName.split('.').pop();
}

export function isObject(obj) {
  return obj !== null && !Array.isArray(obj) && typeof obj === 'object';
}

export const notEmptyValidator = value => value !== '';
