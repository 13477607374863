import { action } from 'mobx';
import { browserHistory } from 'react-router';

import { secretaryProfileService, adminProfileService } from 'services/transport-layer';
import { AdminModel, SecretaryModel } from 'models/profile';
import { createDomainStore } from './base';
import { resetModelPasswords } from './users';
import SessionStore from './session';
import UIStore from './ui';

const manageProfile = {
  updateProfile() {
    const model = this.list[0];
    model.viewModel.submit();

    const preparedModel = Object.assign(model.serialize(), {
      _id: undefined,
      username: undefined,
      orgunitIds: undefined
    });

    // used for backend. since sending empty works bad
    if (!preparedModel.email) {
      preparedModel.email = null;
    }

    // special cases for backend
    if (!preparedModel.hsaId) {
      preparedModel.hsaId = null;
    }

    // special cases for backend
    if (!preparedModel.personalNumber) {
      preparedModel.personalNumber = null;
    }

    if (SessionStore.isAdmin()) {
      delete preparedModel.hsaId;
      delete preparedModel.personalNumber;
    }

    this.service
      .update(preparedModel)
      .then(
        action(() => {
          browserHistory.push(`/home/surveys`);
          UIStore.setOutOfTransition();

          // clean-up passwords
          resetModelPasswords(model);
        })
      )
      .catch(
        action(error => {
          resetModelPasswords(model);

          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  updatePassword(oldPass, newPass) {
    this.service
      .updatePassword({ old: oldPass, new: newPass })
      .then(
        action(() => {
          browserHistory.push(`/home/surveys`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  }
};

export const {
  store: SecretaryProfileStore,
  itemResolver: secretaryProfileResolver
} = createDomainStore({
  name: 'profileSecretary',
  serviceToInject: secretaryProfileService,
  domainModel: SecretaryModel
});

const manageProfileSecretary = Object.assign({}, manageProfile);
manageProfileSecretary.service = secretaryProfileService;

Object.assign(SecretaryProfileStore, manageProfileSecretary);

export const { store: AdminProfileStore, itemResolver: adminProfileResolver } = createDomainStore({
  name: 'profileAdmin',
  serviceToInject: adminProfileService,
  domainModel: AdminModel
});

const manageProfileAdmin = Object.assign({}, manageProfile);
manageProfileAdmin.service = adminProfileService;

Object.assign(AdminProfileStore, manageProfileAdmin);
