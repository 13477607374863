import { action } from 'mobx';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { careUnitsService } from 'services/transport-layer';

import UIStore from './ui';
import CareUnitModel from 'models/care-unit';
import { createDomainStore } from './base';

const config = {
  name: 'careUnits-v2',
  serviceToInject: careUnitsService,
  domainModel: CareUnitModel,
  useQuery: true,
  sortState: {
    property: 'name',
    value: 'asc'
  }
};

const careUnitAddBanKeys = {
  _id: undefined
};

const careUnitUpdateBanKeys = {
  _id: undefined,
  lastUpdate: undefined,
  deletable: undefined
};

function sanitazeCareUnit(careUnitModel) {
  // special cases for backend
  if (!careUnitModel.internalId) {
    careUnitModel.internalId = null;
  }
  if (!careUnitModel.cgmxHSAId) {
    careUnitModel.cgmxHSAId = null;
  }

  if (!+careUnitModel.logout) {
    careUnitModel.logout = null;
  } else {
    careUnitModel.logout = +careUnitModel.logout;
  }

  if (careUnitModel.swiperead && !careUnitModel.swiperead.sessionDuration) {
    careUnitModel.swiperead.sessionDuration = null;
  }

  careUnitModel.optionalSITHS = undefined;

  return careUnitModel;
}

export const {
  store: CareUnitsStore,
  listResolver: careUnitsResolver,
  itemResolver: careUnitResolver
} = createDomainStore(config);

Object.assign(CareUnitsStore, {
  create(model) {
    model.viewModel.id = Math.random();
    model.ordinaryTemplates.forEach(item => item.viewModel.submit());
    model.swiperead.viewModel.submit();
    model.viewModel.submit();
    model.ordinaryTemplates = model.ordinaryTemplates.filter(item => !!item.routeId);

    UIStore.setToTransition();

    const preparedModel = sanitazeCareUnit(model.serialize());

    careUnitsService
      .createNew(Object.assign(preparedModel, careUnitAddBanKeys))
      .then(
        action(({ insertedId }) => {
          model.id = insertedId;
          model.lastUpdate = moment().format();

          this.addOrUpdateItem(model.serialize());

          browserHistory.push(`/home/care-units/admin`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  update(id) {
    const model = this.getItem(id);
    model.ordinaryTemplates.forEach(item => item.viewModel.submit());
    model.swiperead.viewModel.submit();
    model.viewModel.submit();
    UIStore.setToTransition();

    model.ordinaryTemplates = model.ordinaryTemplates.filter(item => !!item.routeId);

    const preparedModel = sanitazeCareUnit(model.serialize());

    careUnitsService
      .update(id, Object.assign(preparedModel, careUnitUpdateBanKeys))
      .then(
        action(() => {
          browserHistory.push(`/home/care-units/admin`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  switchOptionalSITHS(id, params) {
    UIStore.setToTransition();
    careUnitsService
      .patchOptionalSITHS(id, params)
      .then(
        action(item => {
          this.viewState.changePage(this.viewState.pageState.value);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  delete(id) {
    UIStore.setToTransition();
    careUnitsService
      .delete(id)
      .then(
        action(() => {
          this.queryItems(false);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  }
});
