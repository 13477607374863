import { Model } from './utils';
import { observable } from 'mobx';
import { serializable, alias, object } from 'serializr';

export class EMRModel extends Model {
  @serializable
  @observable
  test;

  @serializable
  @observable
  sign;

  @serializable
  @observable
  templateId;

  @serializable
  @observable
  templateType;

  @serializable
  @observable
  mappingId;

  @serializable
  @observable
  directPush;
}

export default class RoutingModel extends Model {
  @serializable(alias('_id'))
  id;

  @serializable
  @observable
  name;

  @serializable
  @observable
  lastUpdate;

  @serializable
  @observable
  description;

  @serializable
  @observable
  converter;

  @serializable
  @observable
  sender;

  @serializable(object(EMRModel))
  @observable
  EMR = new EMRModel({});

  deletable = true;
}
