import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';

import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';

import { applySorting } from 'formatters/tables';

import './care-units.css';

import { translate } from 'utils/helpers';
const t = translate(['common', 'careUnits']);

class CareUnitsPage extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        property: 'code',
        header: {
          label: t('code'),
          formatters: [applySorting]
        }
      },
      {
        property: 'name',
        header: {
          label: t('fullName'),
          formatters: [applySorting]
        }
      },
      {
        property: 'description',
        header: {
          label: t('description'),
          formatters: [applySorting]
        }
      }
    ];
  }

  onRowClick(row) {
    return {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        browserHistory.push(`/home/care-units/${row.id}/patients`);
      }
    };
  }

  render() {
    const { careUnitsSmall } = this.props;

    return (
      <div className="careUnits-page action-footer-padded">
        <NavbarInfo>{t('listItems')}</NavbarInfo>
        <div className="panel">
          <DataTable data={careUnitsSmall.list} columns={this.columns} onRow={this.onRowClick} />
        </div>
      </div>
    );
  }
}

export default inject('careUnitsSmall')(observer(CareUnitsPage));
