import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import './radio.css';

@observer
export default class Radio extends Component {
  render() {
    const { name, label, className, classNameLabel, classNameRoot, ...rest } = this.props;

    return (
      <div className={cx('custom-radio-wrapper', classNameRoot)}>
        <label className={cx('custom-radio-label', classNameLabel)}>
          {label}
          <input {...rest} className={cx('custom-radio', className)} type="radio" name={name} />
          <span className="checkmark" />
        </label>
      </div>
    );
  }
}
