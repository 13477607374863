import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
export default class Form extends Component {
  isValid() {
    const answers = React.Children.map(this.props.children, item => {
      if (!item) {
        return null;
      }

      const form = item.props.form || this.props.form;

      if (item.props.validator) {
        const isValid = item.props.validator(form[item.props.name], form);

        if (form[item.props.name] === undefined && item.props.required) {
          return false;
        }

        return isValid;
      }
      return true;
    });

    return answers.every(item => !!item);
  }

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSubmit && this.props.onSubmit(e, this.isValid());
  };

  cloneChildren = item => {
    if (item) {
      return React.cloneElement(item, { ...item.props, form: item.props.form || this.props.form });
    }
    return null;
  };

  render() {
    const { form, ...rest } = this.props;
    return (
      <form {...rest} onSubmit={this.onSubmit}>
        {React.Children.map(this.props.children, this.cloneChildren)}
      </form>
    );
  }
}
