import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { resolve, nested } from 'table-resolver';

import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import Input from 'components/input/input';

import { timeFormatter, applySortingServerSide } from 'formatters/tables';
import { translate, HideIf } from 'utils/helpers';
import moment from 'moment';
const t = translate(['common', 'reports']);

@inject('reports', 'session')
@observer
class ReportsPage extends Component {
  constructor(props) {
    super(props);
    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.reports.viewState.sortState
    );

    this.columns = [
      {
        property: 'username',
        header: {
          label: t('username'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'note',
        header: {
          label: t('note'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'source.filename',
        header: {
          label: t('filename'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'source.mimetype',
        header: {
          label: t('mimetype'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'source.size',
        header: {
          label: t('size'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'source.filename',
        header: {
          label: t('export')
        },
        cell: {
          formatters: [this.quickLinkCell]
        }
      }
    ];
  }

  quickLinkCell = filename => (
    <img
      onClick={() => window.open(this.props.session.makeStorageUrl(filename), '_blank')}
      src={require('assets/svg/sc-save.svg')}
    />
  );

  listData = () => resolve({ columns: this.columns, method: nested })(this.props.reports.list);

  onPageClick = selected => this.props.reports.viewState.changePage(selected);

  validateDate = value => {
    if (!value || value.includes('_')) {
      return true;
    }

    return moment(value, 'YYYY-MM-DD').isValid();
  };

  onFilterDate = type => value => {
    if (!value.includes('_')) {
      this.props.reports.viewState.changeReportDate(type, value);
    }
  };

  onSortClick = property => this.props.reports.viewState.applySort(property);

  isFiltered = () => this.props.reports.viewState.isFiltered;

  @computed
  get searchIsOpen() {
    return this.props.reports.viewState.openState.value;
  }

  render() {
    const { reports } = this.props;

    return (
      <div className="users-page action-footer-padded">
        <NavbarInfo>{t('listReports')}</NavbarInfo>
        <div className="panel">
          <DataTable
            data={this.listData}
            columns={this.columns}
            isLoading={reports.isListLoading}
            pageState={reports.viewState.pageState}
            pageCount={reports.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered reports-filter"
          filterState={reports.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="searchByReportUserName"
              name="value"
              form={reports.viewState.reportUserSearchState}
              label={t('filters.name.userNameLabel')}
              leftText
              placeholder={t('filters.name.reportUserPlaceholder')}
              onChange={reports.viewState.changeReportUserSearch}
              //innerRef={node => (this.node = node)}
            />
            <Input
              data-test-id="searchByReportNote"
              name="value"
              form={reports.viewState.reportNoteSearchState}
              label={t('filters.name.noteLabel')}
              leftText
              placeholder={t('filters.name.reportNotePlaceholder')}
              onChange={reports.viewState.changeReportNoteSearch}
            />
            <Input
              name="value"
              data-test-id="searchByDateReportFrom"
              form={reports.viewState.dateReportFromState}
              label={t('filters.date.fromLabel')}
              leftText
              placeholder={t('filters.date.fromPlaceholder')}
              masked
              mask="1111-11-11"
              validator={this.validateDate}
              validationText={t('filters.errors.pleaseInputCorrectDate')}
              onChange={this.onFilterDate('from')}
            />
            <Input
              name="value"
              data-test-id="searchByDateReportTo"
              form={reports.viewState.dateReportToState}
              label={t('filters.date.toLabel')}
              leftText
              placeholder={t('filters.date.toPlaceholder')}
              masked
              mask="1111-11-11"
              validator={this.validateDate}
              validationText={t('filters.errors.pleaseInputCorrectDate')}
              onChange={this.onFilterDate('to')}
            />
          </FilterView>
        </FiltersPanel>

        <ActionFooter>
          <Button
            data-test-id="searchTemplate"
            action="secondary"
            color="steel"
            onClick={reports.viewState.toggleOpen}
          >
            {!this.searchIsOpen ? t('searchReport') : t('closeFilter')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={reports.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
        </ActionFooter>
      </div>
    );
  }
}

export default ReportsPage;
