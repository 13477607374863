import { careUnitsService } from 'services/transport-layer';
import CareUnitModel from 'models/care-unit';
import { createDomainStore } from './base';

const config = {
  name: 'userCareUnits',
  serviceToInject: careUnitsService,
  domainModel: CareUnitModel,
  useQuery: true
};

export const {
  store: UserCareUnitsStore,
  listResolver: careUnitsResolver,
  itemResolver: careUnitResolver
} = createDomainStore(config);
