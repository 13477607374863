import { BaseService } from '../base-service';

export class CareUnitsSmallService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/orgunits' });
  }

  query(params = {}, cancelToken) {
    params = { ...params };

    return this.get('/small', { params, cancelToken });
  }

  fetch() {
    return this.get('/small');
  }

  fetchOne(id) {
    return this.get('/' + id);
  }

  fetchBindingsForUser() {
    return this.get('/binding4user');
  }

  fetchBindingsForCareUnits() {
    return this.get('/binding4orgunit');
  }
}
