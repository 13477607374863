import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { observable, action, toJS, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { deserialize } from 'serializr';
import { Link, browserHistory } from 'react-router';
import Spinner from 'react-spinkit';
import { Tooltip } from 'react-tippy';
import Rodal from 'rodal';

import Card from 'components/card/card';
import ActionFooter from 'components/action-footer/action-footer';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';

import Button from 'components/button/button';
import Checkbox from 'components/checkbox/checkbox';
import Input from 'components/input/input';
import Form from 'components/form/form';
import { NavbarInfo } from 'components/navbar/navbar';
import BackTo from 'components/backTo/back-to';
import DataTable from 'components/dataTable/data-table';
import Radio from 'components/radio/radio';

import CareUnit, { TemplateInfo } from 'stores/models/care-unit';
import { translate, HideIf, notEmptyValidator } from 'utils/helpers';
import {
  isActiveFormatter,
  isActiveToTextFormatter,
  applySortingServerSide,
  timeFormatter
} from 'formatters/tables';

import './care-units.css';

const t = translate(['common', 'careUnits', 'templates']);

function getCurrentRouteId(ordinaryTemplates, templateId) {
  const matched = ordinaryTemplates.find(item => item.viewModel.templateId === templateId);

  if (matched) {
    return matched.viewModel.routeId;
  }

  return '';
}

function getCurrentRouteEntity(routes, ordinaryTemplates, templateId) {
  return routes.find(route => route._id === getCurrentRouteId(ordinaryTemplates, templateId));
}

const QuickLinkCell = observer(data => {
  const tmpl = data.data.rowData;
  const { model, setIsDirty } = data.props;
  const routes = data.routes;
  let hasRoute = false;
  const preliminarySelected = !!model.preliminaryTemplates.find(id => tmpl.id === id);

  if (routes().length) {
    const route = getCurrentRouteEntity(routes(), model.ordinaryTemplates, tmpl.id);

    if (route && route.code !== '') {
      hasRoute = true;
    }
  }

  return (
    <span className="tooltip-wrapper">
      <Tooltip title={t('edit')} arrow="true" distance={20}>
        <Link to={`/home/templates/${tmpl.id}/edit`}>
          <img src={require('assets/svg/sc-edit.svg')} />
        </Link>
      </Tooltip>
      <Tooltip
        title={hasRoute ? t('routeLinked') : t('routeAvailableToLink')}
        arrow="true"
        distance={20}
      >
        <img
          data-test-id={hasRoute ? `linkedRoute-${tmpl.id}` : `unlinkedRoute-${tmpl.id}`}
          onClick={() => data.triggerModal(true, tmpl)}
          src={
            hasRoute ? require(`assets/svg/sc-route-ready.svg`) : require('assets/svg/sc-route.svg')
          }
        />
      </Tooltip>

      <Tooltip title={t('sharedTemplateWithRoute')} arrow="true" distance={20}>
        <img
          data-test-id={`${preliminarySelected ? 'preliminary' : 'notPreliminary'}-${tmpl.id}`}
          src={
            preliminarySelected
              ? require('assets/svg/sc-prelim-ready.svg')
              : require('assets/svg/sc-prelim-default.svg')
          }
          onClick={ev => {
            ev.stopPropagation();
            ev.preventDefault();
            setIsDirty(true);

            if (preliminarySelected) {
              const index = model.preliminaryTemplates.indexOf(tmpl.id);
              model.preliminaryTemplates.splice(index, 1);
            } else {
              model.preliminaryTemplates.push(tmpl.id);
            }
          }}
        />
      </Tooltip>
    </span>
  );
});

class RouteIconsCell extends Component {
  render() {
    const data = this.props.data;

    return data ? (
      <div className="tooltip-wrapper">
        {data.EMR && data.EMR.directPush ? (
          <Tooltip title={t('directPush')} arrow="true" distance={20}>
            <img src={require('assets/svg/sc-directpush.svg')} />
          </Tooltip>
        ) : (
          <span className="fake-icon" />
        )}
        {data.EMR ? (
          <Tooltip
            title={t(data.EMR.templateType === 0 ? 'local' : 'global')}
            arrow="true"
            distance={20}
          >
            <img
              src={require(data.EMR.templateType === 0
                ? 'assets/svg/sc-local.svg'
                : 'assets/svg/sc-global.svg')}
            />
          </Tooltip>
        ) : (
          <span className="fake-icon" />
        )}
        {data.EMR && !data.EMR.test ? (
          <Tooltip title={t('productionReady')} arrow="true" distance={20}>
            <img src={require('assets/svg/sc-productionready.svg')} />
          </Tooltip>
        ) : (
          <span className="fake-icon" />
        )}
      </div>
    ) : (
      ''
    );
  }
}

const RouteRadioCell = observer(({ name, data, currentRouteId, setCurrentRouteId }) => {
  return (
    <div>
      <Radio
        data-test-id={'radio-' + data.rowData._id}
        label={name}
        checked={data.rowData._id === currentRouteId() ? 'checked' : ''}
        name="route"
        onChange={() => {
          setCurrentRouteId(data.rowData._id);
        }}
      />
    </div>
  );
});

@observer
class ChecklistActive extends Component {
  @action
  changeActive = () => {
    const {
      data: { id },
      model
    } = this.props;

    if (model.checklistTemplates.includes(id)) {
      model.checklistTemplates = observable(model.checklistTemplates.filter(idx => idx !== id));
    } else {
      model.checklistTemplates = [...toJS(model.checklistTemplates), id];
    }
  };

  render() {
    const { data, model } = this.props;
    return isActiveFormatter(model.checklistTemplates.includes(data.id), this.changeActive);
  }
}

@observer
class RouteDialog extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        property: 'name',
        header: {
          label: t('routeName')
        },
        cell: {
          formatters: [
            (name, data) => {
              return (
                <RouteRadioCell
                  name={name}
                  data={data}
                  currentRouteId={() => this.currentRouteId}
                  setCurrentRouteId={this.setCurrentRouteId}
                />
              );
            }
          ]
        }
      },
      {
        property: 'id',
        header: {
          label: t('id')
        },
        cell: {
          formatters: [
            (id, data) => {
              if (data && data.rowData.code !== '') {
                return data.rowData.EMR.templateId;
              }
            }
          ]
        }
      },
      {
        property: 'id',
        header: {
          label: t('services')
        },
        cell: {
          formatters: [
            (id, data) => {
              return <RouteIconsCell data={data.rowData} />;
            }
          ]
        }
      }
    ];
  }

  setCurrentRouteId = id => {
    const template = this.matchedOrdinaryTemplate;

    if (template) {
      template.viewModel.routeId = id;
    } else {
      const newModel = deserialize(TemplateInfo, {});

      newModel.viewModel.templateId = this.props.template.id;
      newModel.viewModel.routeId = id;

      this.props.model.ordinaryTemplates.push(newModel);
    }
  };

  closeDialog = () => {
    const template = this.matchedOrdinaryTemplate;

    if (template) {
      template.viewModel.reset();
    } else {
      this.props.model.ordinaryTemplates.pop();
    }
    this.props.triggerModal(false, '');
  };

  confirmDialog = () => {
    const template = this.matchedOrdinaryTemplate;
    this.props.setIsDirty(true);
    if (template) {
      template.viewModel.submit();
    } else {
      this.props.model.ordinaryTemplates.pop();
    }

    this.props.triggerModal(false, '');
  };

  @computed
  get matchedOrdinaryTemplate() {
    return this.props.model.ordinaryTemplates.find(item => {
      const toMatchId = this.props.template ? this.props.template.id : null;
      return item.viewModel.templateId === toMatchId;
    });
  }

  @computed
  get currentRouteId() {
    const matchedTemplate = this.matchedOrdinaryTemplate;

    if (matchedTemplate) {
      return matchedTemplate.viewModel.routeId;
    }

    return '';
  }

  @computed
  get currentRoute() {
    return this.props.data.find(route => route._id === this.currentRouteId);
  }

  render() {
    const tableData = this.props.data;
    const template = this.props.template;

    return (
      <Rodal
        template={template}
        visible={this.props.visible}
        onClose={this.closeDialog}
        width={750}
        className="rodal-lg with-scroll routes-rodal"
      >
        {this.props.isLoading.get() ? (
          <div className="global-spinner">
            <Spinner name="double-bounce" fadeIn="none" />
          </div>
        ) : (
          <div>
            <div className="modal-header">
              <p>
                {' '}
                {t('chooseRouteFor')} {template ? template.name : ''}
              </p>
              <div className="modal-icons-block">
                <div>
                  <img src={require('assets/svg/sc-directpush.svg')} />
                  <span>{t('directPush')}</span>
                </div>
                <div>
                  <img src={require('assets/svg/sc-local.svg')} />
                  <span>{t('local')}</span>
                </div>
                <div>
                  <img src={require('assets/svg/sc-global.svg')} />
                  <span>{t('global')}</span>
                </div>
                <div>
                  <img src={require('assets/svg/sc-productionready.svg')} />
                  <span>{t('productionReady')}</span>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <table className="data-table fake-header">
                <thead>
                  <tr>
                    <th>{t('routeName')}</th>
                    <th>{t('templateId')}</th>
                    <th>{t('services')}</th>
                  </tr>
                </thead>
              </table>
              <div className="scrollable">
                <DataTable rowKey="_id" data={tableData} columns={this.columns} />
              </div>
            </div>
            <button
              onClick={this.confirmDialog}
              data-test-id="confirmRouteModal"
              className="modal-confirm-btn"
            >
              {t('confirm')}
            </button>
            <button
              onClick={this.closeDialog}
              className="modal-cancel-btn"
              data-test-id="cancelRouteModal"
            >
              {t('cancel')}
            </button>
          </div>
        )}
      </Rodal>
    );
  }
}
@observer
class EMRRouteField extends Component {
  render() {
    const currentRoute =
      getCurrentRouteEntity(
        this.props.data.routes,
        this.props.model.ordinaryTemplates,
        this.props.templateId
      ) || null;

    return currentRoute ? (
      <div data-test-id="filledRoute">
        <div>{currentRoute.name}</div>
        <div className="flex-route-cell">
          <div>{currentRoute.EMR ? currentRoute.EMR.templateId : ''}</div>
          <div>
            <RouteIconsCell data={currentRoute} />
          </div>
        </div>
      </div>
    ) : (
      <span data-test-id="emptyRoute">-</span>
    );
  }
}

@inject('routesService')
@observer
class TemplatesList extends Component {
  isLoading = observable.box(true);
  @observable routes = [];
  @observable currentTemplate = null;

  constructor(props) {
    super(props);
    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.templatesSmall.viewState.sortState
    );
    this.defaultColumns = [
      {
        property: 'isActive',
        header: {
          label: t('isActive'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [isActiveToTextFormatter]
        }
      },
      {
        property: 'name',
        header: {
          label: t('name'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'code',
        header: {
          label: t('templateCode'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'version',
        header: {
          label: t('version'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'id',
        header: {
          label: t('EMRRoute')
        },
        cell: {
          formatters: [this.createEMRRouteField],
          props: { style: { width: '300px' } }
        }
      },
      {
        property: 'isActiveChecklist',
        header: {
          label: t('checklist')
        },
        cell: {
          formatters: [
            (isActive, { rowData }) => <ChecklistActive data={rowData} model={this.props.model} />
          ]
        }
      },
      {
        property: 'id',
        header: {
          label: t('quickLink')
        },
        cell: {
          formatters: [
            (id, rowData, triggerModal, model, routes) => {
              return (
                <QuickLinkCell
                  data={rowData}
                  triggerModal={this.triggerModal}
                  props={this.props}
                  routes={() => this.routes}
                />
              );
            }
          ]
        }
      }
    ];
  }

  @computed
  get columns() {
    return this.defaultColumns.filter(
      col => this.props.model.allowChecklist || col.property !== 'isActiveChecklist'
    );
  }

  componentWillMount() {
    Promise.all([
      this.props.templatesSmall.queryItems(false),
      this.props.routesService.fetchUnpaged()
    ]).then(([items, routes]) => {
      this.routes = [{ code: '', name: '-', _id: null }, ...routes];
      this.isLoading.set(false);
    });
  }

  triggerModal = (visible, data) => {
    if (visible) {
      this.currentTemplate = data;
    } else {
      this.currentTemplate = null;
    }
  };

  createEMRRouteField = templateId => (
    <EMRRouteField data={this} model={this.props.model} templateId={templateId} />
  );

  onPageClick = selected => this.props.templatesSmall.viewState.changePage(selected);

  onSortClick = property => this.props.templatesSmall.viewState.applySort(property);

  render() {
    const { templatesSmall } = this.props;
    const columns = this.columns || [];

    return (
      <div>
        <RouteDialog
          template={this.currentTemplate}
          triggerModal={this.triggerModal}
          visible={!!this.currentTemplate}
          data={this.routes}
          setIsDirty={this.props.setIsDirty}
          model={this.props.model}
          isLoading={this.isLoading}
        />
        <div className="table-padding" ref={this.props.innerRef}>
          <DataTable
            data={templatesSmall.list}
            columns={columns}
            isLoading={templatesSmall.isListLoading}
            pageState={templatesSmall.viewState.pageState}
            pageCount={templatesSmall.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered"
          filterState={templatesSmall.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="searchByTemplateName"
              name="value"
              form={templatesSmall.viewState.templateSearchState}
              leftText
              placeholder={t('filters.name.templateNamePlaceholder')}
              onChange={templatesSmall.viewState.changeTemplateSearch}
              innerRef={node => (this.node = node)}
            />
          </FilterView>
        </FiltersPanel>
      </div>
    );
  }
}
@inject('careUnits', 'templatesSmall', 'ui')
@observer
class CareUnitManage extends Component {
  componentWillMount() {
    const { params, careUnits } = this.props;

    if (this.isEditMode()) {
      this.model = careUnits.getItem(params.id);
    } else {
      this.model = deserialize(CareUnit, {
        isActive: true,
        logout: 30,
        swiperead: {
          medications: false,
          diagnosis: false,
          caredocs: false,
          sessionDuration: 15
        }
      });
    }
    this.setIsDirty(false);
  }

  isEditMode() {
    return this.props.route.edit;
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  }

  routerWillLeave = action => {
    if (
      (this.model.viewModel.isDirty || this.model.swiperead.viewModel.isDirty || this.isDirty) &&
      !this.forceUnload
    ) {
      this.props.ui.showModal(
        () => {
          this.forceUnload = true;
          browserHistory.push(action);
        },
        null,
        t('yourWorkIsNotSaved')
      );

      return false;
    }
  };

  componentWillUnmount() {
    this.model.viewModel.reset();
    this.model.swiperead.viewModel.reset();
    this.forceUnload = false;
  }

  onSubmit = (e, isValid) => {
    const { params, careUnits } = this.props;

    if (!isValid) {
      return;
    }

    this.setIsDirty(false);
    if (this.isEditMode()) {
      careUnits.update(params.id);
    } else {
      careUnits.create(this.model);
    }
  };

  cgmxHSAIdValidator = (value, model) => {
    if (!value) {
      return true;
    }

    return /^[0-9a-zA-Z]{12}[-][0-9a-zA-Z]{4}$/.test(value);
  };
  logoutTimeValidator = (min, max) => (value, model) => {
    return /^[0-9]*$/.test(value) && +value >= min && +value <= max;
  };

  isFiltered = () => this.props.templatesSmall.viewState.isFiltered;

  @computed
  get searchIsOpen() {
    return this.props.templatesSmall.viewState.openState.value;
  }
  setIsDirty = flag => (this.isDirty = flag);

  render() {
    const { templatesSmall } = this.props;
    const model = this.model.viewModel;
    const swipereadViewModel = this.model.swiperead.viewModel;

    let titleText;
    let formClass;

    if (this.isEditMode()) {
      titleText = t('editCareUnit', { name: model.name });
      formClass = 'editCareUnitForm';
    } else {
      titleText = t('createCareUnit');
      formClass = 'addCareUnitForm';
    }

    if (model.isDirty || this.model.swiperead.viewModel.isDirty || this.isDirty) {
      titleText += ' *';
    }

    return (
      <div className="care-units-page care-unit-manage action-footer-padded">
        <NavbarInfo
          content={<BackTo title={t('backToCareUnitsList')} to="/home/care-units/admin" />}
        >
          {titleText}
        </NavbarInfo>
        <Card>
          <Form
            ref={form => {
              this.reactForm = form;
              this.nativeForm = findDOMNode(form);
            }}
            onSubmit={this.onSubmit}
            form={model}
            data-test-id={formClass}
          >
            <Input
              name="code"
              label={t('code')}
              placeholder={t('code')}
              validator={notEmptyValidator}
              validationText={t('errors.pleaseInputField')}
              data-test-id="code"
              autoComplete="nope"
              required
            />
            <Input
              name="name"
              label={t('name')}
              placeholder={t('name')}
              validator={notEmptyValidator}
              validationText={t('errors.pleaseInputField')}
              data-test-id="name"
              autoComplete="nope"
              required
            />
            <Input
              name="description"
              label={t('description')}
              placeholder={t('description')}
              data-test-id="descr"
            />
            <Input name="internalId" label={t('internalId')} placeholder={t('internalId')} />
            <Input
              name="cgmxHSAId"
              label={t('cgmxHSAId')}
              validator={this.cgmxHSAIdValidator}
              validationText={t('errors.inputCorrectCgmxHSAId')}
              masked
              mask="############-####"
              data-test-id="cgmxHSAId"
            />
            <Input
              name="logout"
              type="number"
              label={t('logoutTime', { min: 1, max: 1440 })}
              placeholder={t('logoutTime', { min: 1, max: 1440 })}
              validator={this.logoutTimeValidator(1, 1440)}
              validationText={t('errors.inputCorrectLogoutTime', { min: 1, max: 1440 })}
              data-test-id="logout"
            />
            <div className="custom-input-label">SwipeRead access</div>
            <div className="custom-checkbox-wrapper access-levels">
              <Checkbox
                form={swipereadViewModel}
                classNameRoot="form-padding"
                name="caredocs"
                label={t('caredocs')}
              />
              <Checkbox
                form={swipereadViewModel}
                classNameRoot="form-padding"
                name="diagnosis"
                label={t('diagnosis')}
              />
              <Checkbox
                form={swipereadViewModel}
                classNameRoot="form-padding"
                name="medications"
                label={t('medications')}
              />
              <Checkbox
                form={swipereadViewModel}
                classNameRoot="form-padding"
                name="measurements"
                label={t('measurements')}
              />
            </div>
            <Input
              form={swipereadViewModel}
              name="sessionDuration"
              disabled={
                !(
                  swipereadViewModel['medications'] ||
                  swipereadViewModel['diagnosis'] ||
                  swipereadViewModel['caredocs'] ||
                  swipereadViewModel['measurements']
                )
              }
              type="number"
              label={t('logoutTimeSwiperead', { min: 1, max: 1440 })}
              placeholder={t('logoutTimeSwiperead', { min: 1, max: 1440 })}
              validator={this.logoutTimeValidator(1, 1440)}
              validationText={t('errors.inputCorrectLogoutTime', { min: 1, max: 1440 })}
              data-test-id="sessionDuration"
            />

            <Checkbox classNameRoot="form-padding" name="isActive" label={t('isActive')} />

            <Checkbox
              classNameRoot="form-padding"
              name="optionalSITHS"
              label={t('optionalSITHS')}
              readOnly={true}
            />
            <Checkbox
              classNameRoot="form-padding"
              name="allowChecklist"
              label={t('allowChecklist')}
              readOnly={false}
            />

            <ActionFooter>
              <Button
                type="button"
                data-test-id="searchTemplate"
                action="secondary"
                color="steel"
                onClick={() => {
                  templatesSmall.viewState.toggleOpen();
                  if (this.table) {
                    this.table.scrollIntoView(false);
                  }
                }}
              >
                {!this.searchIsOpen ? t('searchTemplate') : t('closeFilter')}
              </Button>
              <HideIf check={this.isFiltered}>
                <Button
                  action="secondary"
                  color="peach"
                  onClick={templatesSmall.viewState.resetFiltered}
                >
                  {t('resetFilter')}
                </Button>
              </HideIf>
              <Button
                data-test-id="saveCareUnit"
                type="submit"
                action="primary"
                disabled={this.searchIsOpen}
                color={!this.searchIsOpen ? 'green' : 'steel disabled'}
              >
                {t('save')}
              </Button>
            </ActionFooter>
          </Form>
        </Card>
        <TemplatesList
          setIsDirty={this.setIsDirty}
          model={model}
          templatesSmall={templatesSmall}
          innerRef={node => (this.table = node)}
        />
      </div>
    );
  }
}

export default CareUnitManage;
