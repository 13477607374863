import React from 'react';
import cx from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import './action-footer.css';

const mouseFade = observable({
  isFaded: false
});

function onMouseEnter() {
  mouseFade.isFaded = true;
}

function onMouseLeave() {
  mouseFade.isFaded = false;
}

export default observer(({ children, className }) => (
  <footer className={cx('action-footer', className)}>
    {React.Children.map(children, ch => {
      if (!ch) {
        return;
      }

      if (ch.props.action === 'primary') {
        return React.cloneElement(ch, { ...ch.props, onMouseEnter, onMouseLeave });
      }

      if (ch.props.action === 'secondary') {
        return React.cloneElement(ch, { ...ch.props, isFaded: mouseFade.isFaded });
      }

      return ch;
    })}
  </footer>
));
