import { careUnitsSmallService } from 'services/transport-layer';
import CareUnitModel from 'models/care-unit-small';
import { createDomainStore } from './base';

const config = {
  name: 'careUnitsSmall',
  serviceToInject: careUnitsSmallService,
  domainModel: CareUnitModel
};

export const {
  store: CareUnitsSmallStore,
  listResolver: careUnitsSmallResolver
} = createDomainStore(config);
