import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Downshift from 'downshift';
import cx from 'classnames';

import './downshift.css';

export const Select = ({ items, placeholder, dataTestId, selectedItem, onChange }) => (
  <Downshift
    dataTestId={dataTestId}
    placeholder={placeholder}
    selectedItem={selectedItem}
    onChange={onChange}
    render={({ isOpen, getButtonProps, getItemProps, selectedItem: dsSelectedItem }) => (
      <div className="downshift-container">
        <button
          data-test-id={dataTestId}
          className={cx('downshift-button', selectedItem === undefined ? 'default' : 'selected')}
          {...getButtonProps()}
        >
          {selectedItem ? selectedItem : placeholder}
        </button>
        <div style={{ position: 'relative' }}>
          {isOpen && (
            <ul className="downshift-list">
              {items.map((item, index) => (
                <li
                  className="downshift-listitem"
                  {...getItemProps({
                    item
                  })}
                  key={item}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    )}
  />
);
