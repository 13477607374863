import React from 'react';
import { inject, observer } from 'mobx-react';
import RouterLink from 'components/routerLink/router-link';

import { translate } from 'utils/helpers';
import { hideWithFade } from 'utils/animations';

import './tabs.css';

const t = translate(['common', 'navigation']);

function Tabs({ uiState, surveyId, surveyGUID, session, surveys }) {
  const isSuperAdmin = session.isSuperAdmin();
  const isSecretary = session.isSecretary();

  let isEditAllowed = false;

  if (surveyId) {
    const survey = surveys.getSurvey(surveyId);

    if (survey) {
      isEditAllowed = !surveys.isSurveyEditModeAllowed(survey.status);
    }
  }

  return (
    <nav className="tabs-root" style={hideWithFade(uiState)}>
      <ul className="list-root">
        {(isSecretary || isSuperAdmin) && (
          <li>
            <RouterLink to={`/home/surveys/${surveyId}/${surveyGUID}`} exact>
              {t('generalInformation')}
            </RouterLink>
          </li>
        )}
        {(isSecretary || isSuperAdmin) &&
          isEditAllowed && (
            <li>
              <RouterLink
                data-test-id="surveyEdit"
                to={`/home/surveys/${surveyId}/${surveyGUID}/edit`}
                exact
              >
                {t('edit')}
              </RouterLink>
            </li>
          )}

        <li>
          <RouterLink
            to={`/home/surveys/${surveyId}/${surveyGUID}/trail`}
            data-test-id="auditTrail"
          >
            {t('auditTrail')}
          </RouterLink>
        </li>

        {/* disabled for now due to request from PM */}
        {/*<li><span>{t('sourceFile')}</span></li> */}
      </ul>
    </nav>
  );
}

export default inject('uiState', 'session', 'surveys')(observer(Tabs));
