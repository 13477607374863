import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Rodal from 'rodal';

import { translate } from 'utils/helpers';
import './modal.css';

const t = translate(['common', 'modal']);

@inject('ui')
@observer
class Modal extends Component {
  onOk = () => {
    this.props.ui.modalState.okCb();
    this.props.ui.closeModal();
  };

  render() {
    const { modalState } = this.props.ui;

    return (
      <Rodal visible={modalState.show} onClose={this.props.ui.closeModal}>
        <div className="modal-header">{t('confirmationTitle')}</div>
        <div className="modal-body">
          {this.props.ui.modalState.title || t('reallyWantToDelete')}
        </div>
        <button data-test-id="confirmModal" className="modal-confirm-btn" onClick={this.onOk}>
          {t('confirm')}
        </button>
        <button
          data-test-id="cancelModal"
          className="modal-cancel-btn"
          onClick={modalState.cancelCb || this.props.ui.closeModal}
        >
          {t('cancel')}
        </button>
      </Rodal>
    );
  }
}

export default Modal;
