import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';

import { NavbarInfo } from 'components/navbar/navbar';
import DataTable from 'components/dataTable/data-table';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import Input from 'components/input/input';

import {
  isActiveToTextFormatter,
  timeFormatter,
  applySorting,
  applySortingServerSide,
  deletableFormatter
} from 'formatters/tables';

import { translate, HideIf } from 'utils/helpers';
const t = translate(['common', 'routing']);

@inject('routing')
@observer
class RoutingPage extends Component {
  constructor(props) {
    super(props);

    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.routing.viewState.sortState
    );

    this.columns = [
      {
        property: 'name',
        header: {
          label: t('name'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'description',
        header: {
          label: t('description'),
          formatters: [sortFormatter]
        }
      },
      {
        header: {
          label: t('remove')
        },
        cell: {
          formatters: [deletableFormatter(this.onDelete)],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    ];
  }

  onDelete = id => this.props.routing.delete(id);
  addNewRouting = () => browserHistory.push(`/home/routing/new`);

  onRowClick = row => {
    return {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.nodeName !== 'IMG') {
          browserHistory.push(`/home/routing/${row.id}/edit`);
        }
      }
    };
  };
  onPageClick = selected => this.props.routing.viewState.changePage(selected);

  onSortClick = property => this.props.routing.viewState.applySort(property);

  isFiltered = () => this.props.routing.viewState.isFiltered;

  @computed
  get searchIsOpen() {
    return this.props.routing.viewState.openState.value;
  }

  render() {
    const { routing } = this.props;

    return (
      <div className="routing-page action-footer-padded">
        <NavbarInfo>{t('listRouting')}</NavbarInfo>
        <div className="panel">
          <DataTable
            data={routing.list}
            columns={this.columns}
            onRow={this.onRowClick}
            isLoading={routing.isListLoading}
            pageState={routing.viewState.pageState}
            pageCount={routing.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered"
          filterState={routing.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="searchByRoutingName"
              name="value"
              form={routing.viewState.routingSearchState}
              leftText
              placeholder={t('filters.name.routingNamePlaceholder')}
              onChange={routing.viewState.changeRoutingSearch}
              innerRef={node => (this.node = node)}
            />
          </FilterView>
        </FiltersPanel>

        <ActionFooter>
          <Button
            data-test-id="searchRouting"
            action="secondary"
            color="steel"
            onClick={routing.viewState.toggleOpen}
          >
            {!this.searchIsOpen ? t('searchRouting') : t('closeFilter')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={routing.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
          <Button
            action="primary"
            data-test-id="addRouting"
            disabled={this.searchIsOpen}
            color={!this.searchIsOpen ? 'wisteria' : 'steel disabled'}
            onClick={this.addNewRouting}
          >
            {t('addRouting')}
          </Button>
        </ActionFooter>
      </div>
    );
  }
}

export default RoutingPage;
