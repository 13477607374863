import { BaseService } from '../base-service';

const debounce = require('debounce-promise');
const debounceDelay = 400;

export class TrailService extends BaseService {
  query;

  constructor({ restClient }) {
    super({ restClient, resource: '/trails' });
    this.query = debounce((params, cancelToken) => {
      return this.get('/', { params, cancelToken });
    }, debounceDelay);
  }

  entity(id) {
    return this.get('/' + id);
  }
}
