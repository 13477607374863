import React, { Component } from 'react';
import { observable, action, toJS, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { deserialize } from 'serializr';
import { Link, browserHistory } from 'react-router';

import { updateFocus, notEmptyValidator } from 'utils/helpers';

import Card from 'components/card/card';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import Checkbox from 'components/checkbox/checkbox';
import Input from 'components/input/input';
import Form from 'components/form/form';
import { NavbarInfo } from 'components/navbar/navbar';
import BackTo from 'components/backTo/back-to';
import DataTable from 'components/dataTable/data-table';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';

import { UserModel } from 'stores/models/user';
import { translate, validateEmail, HideIf } from 'utils/helpers';
import { personNumberValidator } from 'utils/custom-validations';
import {
  isActiveFormatter,
  applySortingServerSide,
  timeFormatter,
  parentCareUnit
} from 'formatters/tables';
const t = translate(['users', 'common', 'careUnits', 'patients']);

const quickLinkCell = id => {
  return (
    <Link to={`/home/care-units/admin/${id}/edit`}>
      <img src={require('assets/svg/sc-edit.svg')} />
    </Link>
  );
};

@observer
class CareUnitActive extends Component {
  @action
  changeActive = () => {
    this.props.data.isActive = !this.props.data.isActive;
    this.props.onActiveChange(this.props.data.id);
  };

  render() {
    const { data, model } = this.props;
    return isActiveFormatter(model.orgunitIds.includes(data.id), this.changeActive);
  }
}
@observer
class CareUnitList extends Component {
  constructor(props) {
    super(props);
    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.userCareUnits.viewState.sortState
    );
    this.columns = [
      {
        property: 'isActive',
        header: {
          label: t('assign')
        },
        cell: {
          formatters: [
            (isActive, { rowData }) => (
              <CareUnitActive
                data={rowData}
                onActiveChange={this.props.onActiveChange}
                model={this.props.model}
              />
            )
          ]
        }
      },
      {
        property: 'name',
        header: {
          label: t('name'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'description',
        header: {
          label: t('description'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'id',
        header: {
          label: t('edit')
        },
        cell: {
          formatters: [quickLinkCell]
        }
      }
    ];
  }

  @observable data = [];

  componentWillMount() {
    const { userCareUnits } = this.props;

    userCareUnits.queryItems(false).catch(error => {
      console.error(error);
    });
  }
  onPageClick = selected => this.props.userCareUnits.viewState.changePage(selected);

  onSortClick = property => this.props.userCareUnits.viewState.applySort(property);

  render() {
    const { userCareUnits } = this.props;

    if (this.props.store.isItemFetching.get()) {
      return (
        <DataTable
          data={userCareUnits.list}
          columns={this.columns}
          isLoading={this.props.store.isItemFetching}
          pageState={userCareUnits.viewState.pageState}
          pageCount={userCareUnits.getPageCount}
          onPageChange={this.onPageClick}
          serverSide
        />
      );
    }

    return (
      <div data-test-id="userCareUnitList" className="table-padding" ref={this.props.innerRef}>
        <DataTable
          data={userCareUnits.list}
          columns={this.columns}
          isLoading={userCareUnits.isListLoading}
          pageState={userCareUnits.viewState.pageState}
          pageCount={userCareUnits.getPageCount}
          onPageChange={this.onPageClick}
          serverSide
        />
      </div>
    );
  }
}

@inject('users', 'admins', 'userCareUnits', 'ui', 'session')
@observer
class UserManage extends Component {
  componentWillMount() {
    const { params } = this.props;
    const store = this.getStore();

    this.model = this.isEditMode()
      ? store.getItem(params.id)
      : deserialize(UserModel, { isActive: true, noPasswordNotification: true });
  }

  getStore() {
    if (this.props.route.admin) {
      return this.props.admins;
    }
    return this.props.users;
  }

  isEditMode() {
    return this.props.route.edit;
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);

    // FIXME: This hack is introduced to avoid firefox agressive autocomplete
    // More info: https://jira.strikersoft.com/jira/browse/SWIPECARE-243
    if (!this.isEditMode()) {
      setTimeout(() => {
        this.model.viewModel.username = undefined;
        this.model.viewModel.password = undefined;
      }, 1000);
    }
  }

  routerWillLeave = action => {
    if (this.model.viewModel.isDirty && !this.forceUnload) {
      this.props.ui.showModal(
        () => {
          this.forceUnload = true;
          browserHistory.push(action);
        },
        null,
        t('yourWorkIsNotSaved')
      );

      return false;
    }
  };

  componentWillUnmount() {
    this.model.viewModel.reset();
    this.forceUnload = false;
  }

  onSubmit = (e, isValid) => {
    const { params } = this.props;
    const store = this.getStore();

    if (!isValid) {
      return;
    }

    if (this.isEditMode()) {
      store.updateUser(params.id);
    } else {
      store.createUser(this.model);
    }
  };

  @action
  onCareUnitChange = id => {
    const model = this.model.viewModel;
    if (model.orgunitIds.includes(id)) {
      model.orgunitIds = observable(model.orgunitIds.filter(idx => idx !== id));
    } else {
      model.orgunitIds = [...toJS(model.orgunitIds), id];
    }
  };

  checkPassMatch = model => {
    if (this.model.viewModel.password !== this.model.viewModel.confirmPassword) {
      this.props.ui.showError({ messageId: 'errors.pleaseInputToMatchPassword' }, t);
    }
    updateFocus();
  };

  passwordValidator = (value, model) => value === model.password;

  personNumberValidator = (value, model) => {
    if (!value) {
      return true;
    }

    return personNumberValidator(value, model).isValid;
  };

  expirationDemoValidator = value => /^\d{4}-[01]\d-[0-3]\d/.test(value);

  emailValidator = value => {
    if (this.props.route.admin && value === '') {
      return false;
    }

    return validateEmail(value);
  };

  isFiltered = () => this.props.userCareUnits.viewState.isFiltered;

  @computed
  get searchIsOpen() {
    return this.props.userCareUnits.viewState.openState.value;
  }

  render() {
    const { userCareUnits } = this.props;
    const store = this.getStore();
    const model = this.model.viewModel;
    const { query } = this.props.location;
    const { admin } = this.props.route;
    let headerString;

    if (this.isEditMode()) {
      const name = [model.firstName, model.lastName].join(' ');

      headerString = t(admin ? 'editAdmin' : 'editUser', { name });

      if (model.isDirty) {
        headerString += ' *';
      }
    } else {
      headerString = t('createUser');
    }

    const isFromNurse = query && query.from && query && query.from === 'nurse';

    let titleList;
    let pageRoute;

    if (this.props.route.admin) {
      titleList = t('backToAdminsList');
      pageRoute = '/home/admins';
    } else if (isFromNurse) {
      titleList = t('backToNurseList');
      pageRoute = `/home/care-units/admin/${query.careUnitId}/nurses`;
    } else {
      titleList = t('backToUsersList');
      pageRoute = '/home/users';
    }

    return (
      <div className="users-page action-footer-padded">
        <NavbarInfo content={<BackTo title={titleList} to={pageRoute} />}>
          {headerString}
        </NavbarInfo>
        <Card>
          <Form
            ref={form => {
              this.nativeForm = form;
            }}
            onSubmit={this.onSubmit}
            form={model}
            autoComplete="nope"
            data-test-id="addUserForm"
          >
            <Input
              data-test-id="username"
              name="username"
              label={t('userName')}
              placeholder={t('userName')}
              validator={notEmptyValidator}
              validationText={t('errors.pleaseInputField')}
              disabled={this.isEditMode()}
              autoComplete="nope"
              required
            />
            {admin && (
              <Input
                name="password"
                type="password"
                label={t('password')}
                placeholder={t('password')}
                validator={notEmptyValidator}
                validationText={t('errors.pleaseInputField')}
                required={!this.isEditMode()}
                autoComplete="nope"
              />
            )}
            {admin && (
              <Input
                name="confirmPassword"
                type="password"
                label={t('confirmPassword')}
                placeholder={t('confirmPassword')}
                validator={this.passwordValidator}
                validationText={t('errors.passwordsShouldMatch')}
                required={!this.isEditMode()}
              />
            )}
            <Input
              data-test-id="name"
              name="name"
              label={t('name')}
              placeholder={t('name')}
              validator={notEmptyValidator}
              validationText={t('errors.pleaseInputField')}
              required
            />
            <Input
              data-test-id="email"
              name="email"
              label={t('email')}
              placeholder={t('email')}
              type="email"
              required={admin || !model.isNotSendEmail}
              validator={this.emailValidator}
              validationText={t('errors.emailShouldMatchRules')}
            />

            {!admin && (
              <Checkbox
                classNameRoot="form-padding"
                name="isNotSendEmail"
                label={t('isNotSendEmail')}
              />
            )}

            {!admin && <Input name="jobTitle" label={t('jobTitle')} placeholder={t('jobTitle')} />}
            {!admin && (
              <Input
                key="personalNumber"
                name="personalNumber"
                label={t('personalNumber')}
                validator={this.personNumberValidator}
                validationText={t('errors.inputCorrectPersonalNumber')}
                masked
                mask="11111111-1111"
              />
            )}
            {!admin && (
              <Input
                data-test-id="hsaid"
                name="hsaId"
                required
                label={t('hsaId')}
                placeholder={t('hsaId')}
                onChange={value => (model.hsaId = value.toUpperCase())}
              />
            )}
            {!admin && (
              <Input
                data-test-id="sithsId"
                name="sithsId"
                label={t('sithsId')}
                placeholder={t('sithsId')}
                onChange={value => (model.sithsId = value.toUpperCase())}
              />
            )}
            {!admin &&
              this.props.session.isDemo && (
                <Input
                  data-test-id="expirationDemo"
                  name="expirationDemo"
                  validator={this.expirationDemoValidator}
                  validationText={t('errors.inputCorrectExpirationDemo')}
                  label={t('expirationDemo')}
                  placeholder={t('expirationDemo')}
                  masked
                  mask="1111-11-11"
                />
              )}
            {!admin && (
              <Checkbox classNameRoot="form-padding" name="isActive" label={t('isActive')} />
            )}
            {!admin && (
              <FiltersPanel
                className="filter-centered"
                filterState={userCareUnits.viewState}
                onAnimationEnd={() => this.node && this.node.focus()}
              >
                <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
                  <Input
                    data-test-id="searchCareUnit"
                    name="value"
                    form={userCareUnits.viewState.careUnitSearchState}
                    leftText
                    placeholder={t('filters.name.careUnitNamePlaceholder')}
                    onChange={userCareUnits.viewState.changeCareUnitSearchState}
                    innerRef={node => (this.node = node)}
                  />
                </FilterView>
              </FiltersPanel>
            )}
            <ActionFooter>
              {!admin && (
                <Button
                  type="button"
                  data-test-id="searchCareUnit"
                  action="secondary"
                  color="steel"
                  onClick={() => {
                    userCareUnits.viewState.toggleOpen();
                    if (this.table) {
                      this.table.scrollIntoView(false);
                    }
                  }}
                >
                  {!this.searchIsOpen ? t('searchCareUnit') : t('closeFilter')}
                </Button>
              )}
              {!admin && (
                <HideIf check={this.isFiltered}>
                  <Button
                    type="button"
                    action="secondary"
                    color="peach"
                    onClick={userCareUnits.viewState.resetFiltered}
                  >
                    {t('resetFilter')}
                  </Button>
                </HideIf>
              )}
              <Button
                data-test-id="saveUser"
                onClick={this.checkPassMatch}
                type="submit"
                action="primary"
                disabled={this.searchIsOpen}
                color={!this.searchIsOpen ? 'green' : 'steel disabled'}
              >
                {t('save')}
              </Button>
            </ActionFooter>
          </Form>
        </Card>
        {!admin && (
          <CareUnitList
            innerRef={node => (this.table = node)}
            model={model}
            store={store}
            onActiveChange={this.onCareUnitChange}
            userCareUnits={userCareUnits}
          />
        )}
      </div>
    );
  }
}

export default UserManage;
