import { termsService } from 'services/transport-layer';

const TermsStore = {
  fetchTerms(search, cb) {
    if (!search) {
      return cb(null, { options: [] });
    }

    if (search.length < 3) {
      return cb(null, { options: [] });
    }

    return termsService.fetch(search).then(json => cb(null, { options: json }));
  }
};

export default TermsStore;
