import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, Body, Header } from 'reactabular-table';
import { translate } from 'utils/helpers';
import { observer } from 'mobx-react';
import ReactPaginate from 'react-js-pagination';
import { TextBlock } from 'react-placeholder/lib/placeholders';
import cx from 'classnames';

import './data-table.css';

const t = translate(['common']);

const style = { height: '70px', padding: '20px' };

// TODO: wait for https://github.com/buildo/react-placeholder/pull/20 than update to 1.0.0
function generateLoadingRows(colsLength, itemsCountPerPage) {
  return new Array(itemsCountPerPage).fill().map((_, idx) => (
    <tr key={idx}>
      {new Array(colsLength).fill().map((_, idx) => (
        <td key={idx} style={style}>
          <TextBlock rows={1} color="#F0F0F0" />
        </td>
      ))}
    </tr>
  ));
}

@observer
class CustomBody extends Component {
  static propTypes = {
    data: PropTypes.oneOfType(
      [PropTypes.array, PropTypes.object] // since it can be mobx data
    ).isRequired,
    rowKey: PropTypes.string.isRequired,
    onRow: PropTypes.func,
    itemsCountPerPage: PropTypes.number,
    isLoading: PropTypes.shape({
      get: PropTypes.func
    }),
    columns: PropTypes.array
  };

  render() {
    const { data, onRow, rowKey, isLoading, columns, itemsCountPerPage } = this.props;

    if (isLoading && isLoading.get()) {
      return <tbody>{generateLoadingRows(columns.length, itemsCountPerPage)}</tbody>;
    }

    if (data.length) {
      return <Body rows={data} rowKey={rowKey} onRow={onRow} />;
    }

    return (
      <tbody>
        <tr>
          <td data-test-id="noDataAvailable">{t('noDataAvailable')}</td>
        </tr>
      </tbody>
    );
  }
}

const CustomPagination = observer(({ pageCount, pageState, onPageChange, itemsCountPerPage }) => {
  return (
    <ReactPaginate
      activePage={pageState.value}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={pageCount()}
      pageRangeDisplayed={5}
      onChange={onPageChange}
      linkClassPrev="prev"
      linkClassFirst="first"
      linkClassNext="next"
      linkClassLast="last"
      activeLinkClass="active"
    />
  );
});

function DataTable({
  className,
  children,
  columns,
  data = [],
  onRow,
  rowKey = 'id',
  serverSide = false,
  isLoading,
  pageState,
  pageCount,
  onPageChange,
  itemsCountPerPage = 10
}) {
  return (
    <Provider className={cx('data-table', className)} columns={columns}>
      <Header />
      {children}
      <CustomBody
        data={typeof data === 'function' ? data() : data}
        columns={columns}
        onRow={onRow}
        rowKey={rowKey}
        serverSide={serverSide}
        itemsCountPerPage={itemsCountPerPage}
        isLoading={isLoading}
      />
      {serverSide && (
        <tfoot>
          <tr>
            <td colSpan={columns.length}>
              <CustomPagination
                pageState={pageState}
                pageCount={pageCount}
                onPageChange={onPageChange}
                itemsCountPerPage={itemsCountPerPage}
              />
            </td>
          </tr>
        </tfoot>
      )}
    </Provider>
  );
}

export default observer(DataTable);
