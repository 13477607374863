import { BaseService } from '../base-service';

const debounce = require('debounce-promise');
const debounceDelay = 400;

export class SurveysService extends BaseService {
  query;
  fetchOne;

  constructor({ restClient }) {
    super({ restClient, resource: '/surveys' });

    this.query = debounce((params, cancelToken) => {
      return this.get('/', { params, cancelToken });
    }, debounceDelay);

    this.fetchOne = debounce((id, cancelToken) => {
      return this.get('/' + id, { cancelToken });
    }, debounceDelay);
  }

  fetchSurveyBody(id) {
    return this.get(`/${id}/body`);
  }

  fetchSurveyEmr(id) {
    return this.get(`/${id}/emrdata`);
  }

  fetch() {
    return this.get('');
  }

  archive(id) {
    return this.patch('/' + id, { data: { action: 'archive' } });
  }

  unarchive(id) {
    return this.patch('/' + id, { data: { action: 'unarchive' } });
  }

  confirmSITHS(id) {
    return this.patch('/' + id, { data: { action: 'confirmSITHS' } });
  }

  defectize(id) {
    return this.patch('/' + id, { data: { action: 'defectize' } });
  }

  startQa(id) {
    return this.patch('/' + id, { data: { action: 'startQA' } });
  }

  sendToEmr(id, emrData = []) {
    return this.patch('/' + id, { data: { action: 'send2EMR', emrData } });
  }

  saveToEmr(id, emrData = []) {
    return this.patch('/' + id, { data: { action: 'save4EMR', emrData } });
  }

  archiveById(id) {
    return this.patch('/' + id, { data: { action: 'archive' } });
  }

  retrySending(id) {
    return this.patch('/' + id, { body: { action: 'retry' } });
  }
}
