import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';

import '../../components/tooltip/tooltip.css';

@observer
export default class TermId extends Component {
  render() {
    const { term } = this.props;

    return (
      <span className="cell-id">
        {('' + term.termId).startsWith('custom') ? (
          <span>-</span>
        ) : (
          <span key={term.termId}>{term.termId}</span>
        )}
        <img
          key={term.termId + 'img'}
          src={require('assets/svg/sc-info.svg')}
          className="sc-info"
          data-tip={term.termDesc}
          style={{ visibility: !term.termDesc ? 'hidden' : null }}
        />
        <ReactTooltip
          className="tooltip-main"
          place="right"
          type="info"
          effect="solid"
          event="click"
          globalEventOff="click"
        />
      </span>
    );
  }
}
