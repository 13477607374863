import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory } from 'react-router';
import { resolve, nested } from 'table-resolver';
import { NavbarInfo } from 'components/navbar/navbar';
import DataTable from 'components/dataTable/data-table';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import { Tooltip } from 'react-tippy';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import Input from 'components/input/input';

import {
  isActiveToTextFormatter,
  applySortingServerSide,
  timeFormatter,
  applySorting,
  deletableFormatter
} from 'formatters/tables';

import './mapping.css';

import { translate, HideIf } from 'utils/helpers';
const t = translate(['common', 'mapping']);

class MappingPage extends Component {
  constructor(props) {
    super(props);
    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.mapping.viewState.sortState
    );
    this.columns = [
      {
        property: 'name',
        header: {
          label: t('name'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'id',
        header: {
          label: t('quickLink')
        },

        cell: {
          formatters: [
            (id, data) => this.quickLinkCell(id, data.rowData.name),
            deletableFormatter(this.onDelete)
          ],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    ];
  }

  quickLinkCell = (id, name) => {
    return (
      <span>
        <Tooltip title={t('exportFile')} arrow="true" distance={20}>
          <img
            data-test-id={`export-${id}`}
            src={require('assets/svg/sc-save.svg')}
            onClick={() => this.props.mapping.export(id, name)}
          />
        </Tooltip>
      </span>
    );
  };

  listData = () => resolve({ columns: this.columns, method: nested })(this.props.mapping.list);

  onDelete = id => this.props.mapping.delete(id);

  addNewMapping = () => browserHistory.push(`/home/mapping/new`);

  onRowClick = row => {
    return {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.nodeName !== 'IMG') {
          browserHistory.push(`/home/mapping/${row.id}/edit`);
        }
      }
    };
  };

  onPageClick = selected => this.props.mapping.viewState.changePage(selected);

  onSortClick = property => this.props.mapping.viewState.applySort(property);

  isFiltered = () => this.props.mapping.viewState.isFiltered;

  @computed
  get searchIsOpen() {
    return this.props.mapping.viewState.openState.value;
  }

  render() {
    const { mapping } = this.props;

    return (
      <div className="mapping-page action-footer-padded">
        <NavbarInfo>{t('listMapping')}</NavbarInfo>
        <div className="panel">
          <DataTable
            data={this.listData}
            columns={this.columns}
            onRow={this.onRowClick}
            isLoading={mapping.isListLoading}
            pageState={mapping.viewState.pageState}
            pageCount={mapping.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered"
          filterState={mapping.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="searchByMappingName"
              name="value"
              form={mapping.viewState.mappingSearchState}
              leftText
              placeholder={t('filters.name.mappingNamePlaceholder')}
              onChange={mapping.viewState.changeMappingSearch}
              innerRef={node => (this.node = node)}
            />
          </FilterView>
        </FiltersPanel>

        <ActionFooter>
          <Button
            data-test-id="searchMapping"
            action="secondary"
            color="steel"
            onClick={mapping.viewState.toggleOpen}
          >
            {!this.searchIsOpen ? t('searchMapping') : t('closeFilter')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={mapping.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
          <Button
            action="primary"
            data-test-id="addMapping"
            disabled={this.searchIsOpen}
            color={!this.searchIsOpen ? 'wisteria' : 'steel disabled'}
            onClick={this.addNewMapping}
          >
            {t('addMapping')}
          </Button>
        </ActionFooter>
      </div>
    );
  }
}
export default inject('mapping')(observer(MappingPage));
