module.exports = {
  common: {
    username: 'Username',
    password: 'Password',
    submit: 'Submit',
    save: 'Save',
    reset: 'Reset',
    archive: 'Archive',
    logout: 'Log out',
    status: 'Status',
    edit: 'Edit',
    quickLink: 'Quick link',
    note: 'Note',
    value: 'Value',
    lastUpdate: 'Last update',
    remove: 'Remove',
    fullName: 'Full name',
    address: 'Address',
    name: 'Name',
    email: 'Email',
    isActive: 'Active',
    caredocs: 'Journal',
    diagnosis: 'Patient diagnosis',
    medications: 'Medication',
    measurements: 'Measurements',
    yourWorkIsNotSaved: 'Your work is not saved! Are you sure you want to leave?',
    code: 'Code',
    description: 'Description',
    typeToSearch: 'Type to search (at least 3 symbols)',
    noResults: 'No results within your query',
    reallyWantToDelete: 'Do you really want to delete?',
    noDataAvailable: 'No data available',
    loading: 'Loading',
    openFilter: 'Open filter',
    closeFilter: 'Close filter',
    resetFilter: 'Reset filter',
    yes: 'Yes',
    no: 'No',
    login: 'Login'
  },

  login: {
    greeting: 'Welcome to Swipecare!',
    enterCredentials: 'To start processing the surveys you need to login',
    SITHSAuth: 'Log in via SITHS ID',
    getBackToSITHS: 'Get back to the SITHS authorization',
    loginViaSITHS: 'Log in via SITHS',
    signInWithCredentials: 'sign in with credentials',
    isAdmin: 'You are an administrator',
    troublesWithLogin: 'troubles with login?',

    forgotPassword: 'Forgot password',
    greetingRecovery: 'Forgot password?',
    enterCredentialsRecovery: 'Type in your mail',
    backToLogin: 'Back to login',
    update: 'Update password',
    greetingRecoveryConfirm: 'Password recovery',
    enterCredentialsRecoveryConfirm: 'Type in your password',
    submitRecovery: 'Recover password',

    errors: {
      youCantOpenRecoveryWhenLoggedIn: `You can't open recovery link when you are logged in`
    }
  },

  navigation: {
    surveys: 'Surveys',
    careUnits: 'Care units',
    users: 'Users',
    templates: 'Templates',
    patients: 'Patients',
    generalInformation: 'General information',
    auditTrail: 'Audit trail',
    sourceFile: 'Source file',
    admins: 'Admins',
    reports: 'Log reports',
    mapping: 'Mapping',
    routing: 'Routing',
    help: 'Help',
    edit: 'Edit'
  },

  help: {
    loginTutorial: 'Login tutorial',
    loginTutorHelp: 'This video will explain got to login into the Swipecare app.',
    goBackToLogin: 'Get back to login',
    helpTitle: 'Help',
    tutorialsNav: 'Tutorials: ',
    headerSubtitle: 'Hjälpfilmer för användning av SwipeCare',
    footerHelpOne:
      'Gå in på intranätet Praktikertjänst Insikt: insikt.ptj.se. Klicka på Praktikertjänst N.Ä.R.A. Tryck på rutan ”Support Kontakta oss” till höger. Fyll i formuläret och ange kategori IT och Stelpa.',
    footerHelpTwo: 'Du kan också ringa IT supporten på 010-1284400 eller maila på:',
    footerHelpThree:
      'Om du behöver mer hjälp, kontakta Linda Vogt, sjuksköterska på Praktikertjänst N.Ä.R.A. som varit med och utvecklat produkten.',
    footerHelpEmail: 'Epost:',
    footerHelpEmailPraktiker: 'praktikerservice@ptj.se',
    footerHelpEmailLinda: 'Linda.Vogt@ptj.se'
  },

  auditTrail: {
    compareType: 'Compare type',
    event: 'Event',
    timestamp: 'Timestamp',

    backToAuditTrail: 'Back to Audit Trail',
    compareMode: 'Compare mode',
    removed: 'Removed',
    termDetails: 'Term details',
    dataRouteFound: 'Data route found',
    generalInfo: 'General info',
    termid: 'Term ID',
    termname: 'Term name',
    termtype: 'Term type',
    termdesc: 'Description',
    templateId: 'Template ID',
    mappingId: 'Mapping ID',
    templateType: 'Template type',
    directPush: 'Direct push',
    test: 'Test',
    converter: 'Converter',
    sender: 'Sender',
    viewDetails: 'view details',
    events: {
      Uploaded: 'New survey uploaded',
      SITHS_Confirmed: 'SITHS signed',
      QA_Started: 'QA started',
      Archived: 'Archived',
      DataExtracted: 'Read survey uploaded',
      ErrDataExtracted: 'Error on reading survey uploaded',
      RetryProcessing: 'Retry processing survey uploaded',
      MarkedAsDefective: 'Marked as Defective',
      Unarchived: 'Unarchived',
      DataSaved: 'Data saved',
      DataSendingManual: 'Data Send Manually',
      DataSendingAttempt: 'Data Sending Attempt',
      DataSent: 'Data sent',
      ErrDataSent: 'Error on data sending',
      DataRouteFound: 'Data route detected',
      DataUpdated: 'Data updated by user',
      ManualDataSendingRequired: 'Manual data Sending Required',
      Ready4QA: 'Ready for QA'
    },

    types: {
      Uploaded: 'Saved fileids, name',
      DataExtracted: 'General survey info',
      ErrDataExtracted: 'General survey info',
      RetryProcessing: 'General survey info',
      MarkedAsDefective: 'General survey info',
      QA_Started: 'General survey info',
      Archived: 'General survey info',
      Unarchived: 'General survey info',
      DataSaved: 'EMR data. Sensitive info',
      DataSendingManual: 'General survey info',
      DataSendingAttempt: 'General survey info',
      DataSent: 'General survey info',
      ErrDataSent: 'General survey info',
      DataRouteFound: 'Route details',
      DataUpdated: 'General survey info',
      SITHS_Confirmed: 'SITHS signed',
      Ready4QA: 'General survey info',
      ManualDataSendingRequired: 'General survey info'
    }
  },

  surveys: {
    listTitle: 'Surveys list',
    detailsTitle: 'Survey details',
    editTitle: 'Survey edit',
    backToSurveysList: 'Back to surveys list',
    waitingForQa: 'Waiting for QA',
    requiringAttention: 'Requiring attention',
    imported: 'Imported',
    qaStage: 'QA stage',
    retrySending: 'Retry sending',
    retryProcessing: 'Retry processing',
    archiveSurvey: 'Archive survey',
    unarchiveSurvey: 'Unarchive survey',
    markAsDefective: 'Mark defective',
    confirmSITHS: 'Confirm SITHS',
    readyForEMR: 'Ready for EMR',
    startQa: 'Start QA',
    finished: 'Finished',
    requireSITHSConfirmation: 'Require SITSH confirmation',
    defective: 'Defective',
    archived: 'Archived',
    importGaveUp: 'Import gave up',
    uploadDate: 'Upload date',
    visitDate: 'Visit date',
    uploadBy: 'Upload by',
    qa: 'QA',
    careUnit: 'Care unit',
    surveyType: 'Survey type',
    doctor: 'Doctor',
    patient: 'Patient',
    sendToEmr: 'Send to EMR',
    uploadedBy: 'Uploaded by',
    templateCode: 'Template code',
    patientName: 'Patient name',
    termName: 'Term name',
    valueTerms: 'Value terms',
    termValue: 'Term value',
    termNote: 'Term note',
    termType: 'Term type',
    metadata: 'Metadata',
    id: 'ID',
    templateName: 'Template name',
    careUnitInternalId: 'Care unit internal ID',
    careUnitCode: 'Care unit code',
    visit: 'Visit',
    time: 'Time',
    durationInMinutes: 'Duration (minutes)',
    doctorName: 'Doctor name',
    doctorSpeciality: 'Doctor speciality',
    closeFilter: 'Close filter',
    resetFilter: 'Reset filter',
    error: 'Error',
    export: 'Export',

    openAddNewToEmr: 'Add',
    addNewToEmr: 'Add term',
    closeAddNewToEmr: 'Close',
    addNewBnt: 'Add term',
    numeric: 'Value',

    filters: {
      errors: {
        pleaseInputCorrectDate: 'Please input corrent date'
      },
      status: {
        header: 'Search by status',
        desc: 'You can apply quick status filters',
        chooseStatus: 'Choose status'
      },
      name: {
        header: 'Search by name',
        desc: 'Filter surveys by name of the patient',
        patientNameLabel: 'Patient name or number',
        patientNamePlaceholder: 'Start input patient...',
        templateNameLabel: 'Template name',
        templateNamePlaceholder: 'Start input template name...',
        qaByLabel: 'QA by',
        qaByPlaceholder: 'Start input qa name...',
        uploadedByLabel: 'Uploaded by',
        uploadedByPlaceholder: 'Start input uploaded name...',
        doctorByLabel: 'Doctor name',
        doctorByPlaceholder: 'Start input doctor name...',
        careUnitNameLabel: 'Care unit',
        careUnitNamePlaceholder: 'Start input care unit name...'
      },
      date: {
        header: 'Search by date',
        desc: 'You can filter results by different period',
        fromLabel: 'Visit date from',
        fromPlaceholder: 'Start input date like 2017-01-01...',
        toLabel: 'Visit date to',
        toPlaceholder: 'Start input date like 2017-01-01...'
      },
      lastUpdate: {
        labelFrom: 'Last update from',
        placeholderFrom: 'Start input date like 2017-01-01...',
        labelTo: 'Last update to',
        placeholderTo: 'Start input date like 2017-01-01...'
      }
    },

    errors: {
      termNameAlreadyExist: 'This term is already used in this survey',
      termNameIsNotSelected: 'Term name is not selected'
    }
  },

  patients: {
    listTitle: 'Patients list of {{careUnit}}',
    backToCareUnitsList: 'Back to Care Units lists',
    backToPatientsList: 'Back to Patients lists',
    personalNumber: 'Personal number',
    isSpareNumber: 'Use Spare number',
    personalOrSpareNumber: 'Personal or spare number',
    addPatient: 'Add patient',
    editPatient: 'Edit patient {{name}}',
    createPatient: 'Create patient',
    male: 'Male',
    female: 'Female',
    sex: 'Sex',
    birthday: 'Birthday',
    postCode: 'Post code',
    city: 'City',
    street: 'Street',
    number: 'Number',
    patientPlace: 'Place',
    co: 'CO',
    searchPatient: 'Search patient',
    filters: {
      name: {
        header: 'Search patients',
        desc: 'Search patients by a full name',
        fullNamePlaceholder: 'Input full name',
        fullNameLabel: 'Full name',
        patientPlaceLabel: 'Place',
        patientPlacePlaceholder: 'Input room number',
        sparelNumberLabel: 'Spare number',
        personalNumberPlaceholder: 'Input personal number',
        spareNumberPlaceholder: 'Input spare number'
      }
    },

    errors: {
      inputCorrectPersonalNumber: 'Please, input correct personal number',
      inputCorrectSpareNumber: 'Please, input correct spare number',
      inputCorrectFullNam: 'Please fill to proceed'
    }
  },

  nurses: {
    listTitle: 'Nurse list of {{careUnit}}',
    backToCareUnitsList: 'Back to Care Units list',
    searchNurse: 'Search nurse',
    filters: {
      name: {
        header: 'Search by name',
        desc: 'Search nurses by name, username ',
        nurseNamePlaceholder: 'Search nurse'
      }
    }
  },

  users: {
    listTitle: 'Users list',
    listAdminTitle: 'Admins list',
    userName: 'Username',
    userNameTitle: 'Username',
    email: 'Email',
    addUser: 'Add user',
    editUser: 'Edit user {{name}}',
    editAdmin: 'Edit admin {{name}}',
    createUser: 'Create user',
    backToUsersList: 'Back to Users list',
    backToAdminsList: 'Back to Admins list',
    confirmPassword: 'Confirm password',
    firstName: 'First name',
    lastName: 'Last name',
    assign: 'Assign',
    jobTitle: 'Job title',
    isNotSendEmail:
      'Uncheck for sending an e-mail with login information (not relevant for organisations using SITHS)',
    expirationDemo: 'Expiration demo time',
    sithsId: 'HSA ID',
    hsaId: 'TakeCare användarnamn',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    updatePassword: 'Update password',
    updateProfile: 'Update profile',
    editProfile: 'Edit my profile ({{name}})',
    backToSurveyList: 'Back to Surveys list',
    backToCareList: 'Back to Care units list',
    careUnit: 'Care unit',
    searchUser: 'Search user',
    backToNurseList: 'Back to Nurse List',
    export: 'Export',
    filters: {
      name: {
        header: 'Search by name',
        desc: 'Filter users by name, username or HSA ID',
        userNamePlaceholder: 'Nelle Meyer or 9FA8CBB8-2A...'
      },
      isActiveOnly: 'Active only'
    },

    errors: {
      passwordsShouldMatch: 'Passwords should match',
      emailShouldMatchRules: 'Inputted email is incorrect',
      pleaseInputField: 'This field should not be empty',
      pleaseInputOldPassword: 'Please input old password',
      pleaseInputNewPassword: 'Please input new password',
      pleaseInputToMatchPassword: 'Please input password to match',
      inputCorrectExpirationDemo: 'Incorrect format of date'
    }
  },

  modal: {
    confirmationTitle: 'Confirmation',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },

  reports: {
    listReports: 'Log reports list',
    filename: 'Filename',
    mimetype: 'Mimetype',
    size: 'Size',
    searchReport: 'Search report',
    filters: {
      name: {
        header: 'Search by name of user, note, date',
        desc: 'Filter log reports by name of user, note and by different period',
        userNameLabel: 'Username',
        reportUserPlaceholder: 'Start input name of user...',
        noteLabel: 'Note',
        reportNotePlaceholder: 'Start input note...'
      },
      date: {
        fromLabel: 'Date from',
        fromPlaceholder: 'Start input date like 2017-01-01...',
        toLabel: 'Date to',
        toPlaceholder: 'Start input date like 2017-01-01...'
      }
    },
    export: 'Export'
  },

  templates: {
    listTitle: 'Templates list',
    linkTitle: 'Templates link',
    addTemplate: 'Add template',
    templateCode: 'Template code',
    templateDescription: 'Template description',
    version: 'Version',
    createTemplate: 'Create template',
    editTemplate: 'Edit template {{name}}',
    templateSourceFile: 'Template source file (json)',
    noFilesSelected: 'No files selected',
    chooseNewFile: 'Choose new file',
    processingYourFile: 'Processing your file',
    resourceId: 'Resource ID: {{resourceId}}',
    backToTemplateList: 'Back to Templates list',
    assignTemplate: 'assign template',
    applyTemplate: 'apply template',
    unassignTemplate: 'unassign template',
    progenitorName: 'Linked template',
    templateLink: 'Choose template to link',
    yourSureUnlinkTemplate: 'Are you sure you want to unlink that template?',
    APPLY: 'APPLY',
    ASSIGN: 'ASSIGN',
    UNASSIGN: 'UNASSIGN',
    ASSIGNED: 'ASSIGNED',
    searchTemplate: 'Search template',
    filters: {
      name: {
        header: 'Search by name',
        desc: 'Filter templates by name',
        templateNamePlaceholder: 'Input name'
      }
    },
    errors: {
      pleaseInputField: 'This field should not be empty',
      addAllResources: 'Please, add all resources',
      incorrectJSONFile: 'Incorrect JSON or JS file uploaded',
      fileTypeIsInvalid: 'File type of uploaded file is invalid.'
    }
  },

  careUnits: {
    listItems: 'Care units',
    parentCareUnit: 'Parent care unit',
    addCareUnit: 'Add Care Unit',
    createCareUnit: 'Create Care Unit',
    backToCareUnitsList: 'Back to Care Units list',
    editCareUnit: 'Edit Care Unit {{name}}',
    optionalSITHS: 'Allow password login',
    allowChecklist: 'Allow using checklist',
    EMRRoute: 'Route',
    checklist: 'Check list',
    internalId: 'Care unit ID',
    patientList: 'Patient list',
    nurseList: 'Nurse list',
    routeAvailableToLink: 'Route is available to link',
    routeLinked: 'Linked route',
    sharedTemplate: 'Shared template',
    sharedTemplateWithRoute: 'Unmark template as preliminary',
    readyToShareTemplate: 'Mark template as preliminary',
    directPush: 'Direct push',
    local: 'Local',
    global: 'Global',
    productionReady: 'Production ready',
    chooseRouteFor: 'Choose route for {{templateName}}',
    routeName: 'Route name',
    services: 'Services',
    templateId: 'Template ID',
    cgmxHSAId: 'CGMX HSA ID',
    logoutTime: 'Inactivity logout time ({{min}}-{{max}} in minutes)',
    logoutTimeSwiperead: 'Inactivity SwipeRead logout time ({{min}}-{{max}} in minutes)',
    searchCareUnit: 'Search care unit',
    filters: {
      name: {
        header: 'Search by name',
        desc: 'Filter care units by name',
        careUnitNamePlaceholder: 'Input name'
      }
    },
    errors: {
      pleaseInputField: 'This field should not be empty',
      inputCorrectCgmxHSAId: 'Please, input correct CGMX HSA ID number',
      inputCorrectLogoutTime: 'This field should be only integer ({{min}}-{{max}})'
    }
  },

  mapping: {
    listMapping: 'Mapping list',
    isActive: 'Active',
    elements: 'Elements',
    pages: 'Pages',
    questions: 'Questions',
    search: 'Search by id, term or description',
    elementId: 'Element ID',
    termId: 'Term ID',
    addElement: 'Add element',
    addPage: 'Add page',
    addQuestion: 'Add question',
    addMapping: 'Add mapping',
    editMapping: 'Edit {{name}}',
    backToMappingList: 'Back to Mapping list',
    exportFile: 'Export',
    importFile: 'Import',
    complex: 'Complex',
    searchMapping: 'Search mapping',
    filters: {
      name: {
        header: 'Search by name',
        desc: 'Filter mappings by name',
        mappingNamePlaceholder: 'Input name'
      }
    }
  },

  routing: {
    listRouting: 'Routing list',
    routingName: 'Routing name',
    isActive: 'Active',
    testEmr: 'Test EMR',
    signEmr: 'Send signed',
    directPush: 'Direct push',
    converter: 'Converter',
    sender: 'Sender',
    templateId: 'Template ID',
    templateType: 'Template type',
    mappingId: 'Mapping ID',
    addRouting: 'Add routing',
    editRouting: 'Edit {{name}}',
    backToRoutingList: 'Back to Routing list',
    templateTypeLocal: 'Local',
    templateTypeGlobal: 'Global',
    mappingName: 'Mapping name',
    typeMappingName: 'Start input mapping name...',
    mappingNamesNotLoaded: 'Mapping names were not loaded',
    convertersNotLoaded: 'Converters were not loaded',
    sendersNotLoaded: 'Senders were not loaded',
    searchRouting: 'Search routing',
    filters: {
      name: {
        header: 'Search by name',
        desc: 'Filter routings by name',
        routingNamePlaceholder: 'Input name'
      }
    }
  },

  errorsFromBackend: {
    unexpected_database_error: 'Unexpected database error',
    unexpected_error: 'Unexpected error',
    user_username_exists: 'User with such username already exists',
    admin_username_exists: 'Admin with such username already exists',
    patient_personal_number_exists: 'Patient with such personal number already exists in care unit',
    orgunit_code_exists: 'Orgunit with such code already exists',
    parent_orgunit_can_not_have_employees:
      'Parent organization unit can not have employees. Please reload web page and try again.',
    parent_orgunit_can_not_have_patients:
      'Parent organization unit can not have patients. Please reload web page and try again.',
    incorrect_credentials: 'Incorrect credentials',
    user_credentials_are_deactivated: 'Your credentials are deactivated',

    selected_orgunits_have_child_orgutins:
      'Some of selected organization units have child units. Please reload web page and try again.',
    orgunit_has_child_orgutins:
      'Organization unit has child units. Please choose another unit to assign patiens.',
    template_code_exists: 'Template with such code already exists',
    template_data_absent: 'Template data is absent/incorrect',
    do_not_have_admin_rights: 'You do not have admin rights',
    can_not_find_patient_in_orgunit: 'Can not find patien in care unit',
    can_not_find_orgunit: 'Can not find care unit',
    can_not_find_survey_by_id: 'Can not find survey by ID',
    do_not_have_access_to_survey: 'You do not have access to selected survey',
    do_not_have_access_to_orgunit: 'You do not have access to selected care unit',
    survey_is_not_active_or_does_not_exist: 'Survey is not active or does not exist',
    action_is_forbidden_because_of_survey_status: 'Action is forbidden because of survey status',
    can_not_find_survey_to_read: 'Can not find survey info to read survey',
    can_not_find_survey_to_send: 'Can not find survey info to send it',
    can_not_find_survey_to_adopt: 'Can not find survey info to adopt survey',
    can_not_find_survey_to_process: 'Can not find survey info to process survey',
    can_not_find_route_code_for_survey: 'Can not find route code for survey',
    can_not_find_route_in_config: 'Can not find route for survey in config file',
    can_not_load_reader: 'Can not load reader for survey',
    can_not_load_converter: 'Can not load converter for survey',
    can_not_load_sender: 'Can not load sender for survey',
    can_not_find_orgunit_id_by_code: 'Can not find care unit ID by code',
    can_not_find_template_id_by_code: 'Can not find template ID by code',
    can_not_find_template_to_update: 'Can not find template to update',
    username_can_not_be_empty: 'Username can not be empty',
    can_not_send_empty_emr_data: 'Can not send empty EMR data',

    required_fields_are_absent: 'One or more required fields are absent',
    invalid_value_of_fields: 'One or more fields has invalid value',
    invalid_type_of_fields: 'One or more fields has invalid type',
    unexcpected_validation_error: 'Unexpected validation error',

    disallowed_fields: 'Some fields are not allowed to modify',

    unbing_child_before_deletion: 'Unbind child orgunits before deletion',
    unknown_action_for_survey: 'Unknown action for survey',
    incorrect_current_password: 'Incorrect current password',
    changes_are_forbidden_because_of_invalid_transition_of_survey_status:
      'Changes are forbidden because of invalid transition of survey status',

    user_hsaid_exists: 'User with such HSA ID already exists',
    offline_error: 'Please check your internet connection',

    siths_user_is_not_registered: 'SITHS user is not registered in SwipeCare system',
    user_email_exists: 'User with such email already exists',
    user_password_is_not_generated: 'Your password is not generated',
    can_not_find_user_by_ticket_code: 'Can not find user by ticket code',

    there_is_no_user_email:
      'There is no email associated with this user or sending is not allowed. Contact Administrator.',
    there_are_surveys_belong_to_deleting_orgunit:
      'There are surveys which belong to the deleting orgunit',
    invalid_siths_session: 'Invalid SITHS session',
    incorrect_token: 'Unauthorized or incorrect/outdated token',
    incorrect_tenant_data_in_token: 'Incorrect tenant data in token',
    tenant_data_mismatch: 'Tenant data mismatch',
    cgmx_error: 'CGMX error',
    auth_session_is_expired: 'Auth session is expired',
    incorrect_help_token: 'Incorrect/outdated help token',
    incorrect_siths_credentials: 'Incorrect SITHS credentials',
    password_can_not_be_empty: 'Password can not be empty',
    resource_files_are_missed: 'Some of resource files of template are missed',
    cgmx_hsa_id_does_not_assign_4_orgunit: 'CGMX HSA ID does not assign for care unit',
    can_not_find_survey_body: 'Can not find survey body',
    can_not_find_survey_emr_data: 'Can not find survey EMR data',
    can_not_find_preliminary_survey: 'Can not find preliminary survey',
    can_not_find_preliminary_survey_body: 'Can not find preliminary survey body',
    survey_is_deactivated: 'Survey is deactivated',
    invalid_data_to_change_survey: 'Invalid data to change survey',
    empty_action_for_survey: 'Empty action for survey',
    can_not_find_survey_to_convert: 'Can not find survey info to convert survey',
    can_not_find_route: 'Can not find route for survey',
    can_not_find_route_id: 'Can not find route ID for survey',
    can_not_find_mapping: 'Can not find mapping for survey',
    can_not_find_mapping_id: 'Can not find mapping ID for survey',
    can_not_find_template: 'Can not find template',
    can_not_find_progenitor: 'Can not find template progenitor',
    can_not_find_template_body: 'Can not find template body',
    can_not_find_template_by_id: 'Can not find template by ID',
    can_not_find_audit_trail_record: 'Can not find audit trail record',
    can_not_find_mapping_by_id: 'Can not find mapping by ID',
    route_using_mapping: 'One or more routes are using deleting mapping',
    orgunit_using_route: 'One or more careunits are using deleting route',
    there_are_surveys_based_on_deleting_template:
      'There are surveys based on the deleting template',
    can_not_find_template_to_delete: 'Can not find template to delete',
    session_id_is_absent: 'Session ID is absent',
    callback_url_is_absent: 'Callback URL is absent',
    incorrect_length_of_field: 'One of field has incorrect length',
    incorrect_patient_personal_number: 'Patient personal number is incorrect',
    patient_information_is_absent: 'Patient information is absent',
    careunit_information_is_absent: 'Careunit information is absent',
    report_file_is_absent: 'Report file is absent',
    can_not_find_user_by_username: 'Can not find user by username',
    can_not_find_orgunit_by_id: 'Can not find careunit by ID',
    user_is_deactivated: 'User is deactivated',
    orgunit_is_deactivated: 'Careunit is deactivated',
    template_is_deactivated: 'Template is deactivated',
    user_do_not_have_access_to_orgunit: 'User do not have access to careunit',
    ticket_is_locked: 'Ticket is locked',
    ticket_is_outdated: 'Ticket is outdated',
    ticket_is_not_activated: 'Ticket is not activated',
    unknown_listening_type: 'Unknown listening type',
    outdated_token: 'Outdated token',
    incorrect_token_4ws: 'Incorrect token for web socket',
    unavailable_cgmx_data_type: 'Unavailable CGMX data type',
    patient_ID_is_required_search_parameter: 'Patient ID is required search parameter',
    patient_number_is_required_search_parameter: 'Patient number is required search parameter',
    do_not_have_valid_patient_consent: 'You do not have valid patient consent',
    user_does_not_have_HSA_ID: 'User does not have HSA ID',
    tenant_ID_is_absent: 'Tenant ID is absent',
    tenant_ID_is_invalid: 'Tenant ID is invalid',
    can_not_find_assessment: 'Can not find assessment',
    can_not_find_user_profile: "Can not find user's profile"
  }
};
