import { Model } from './utils';
import { observable } from 'mobx';
import { serializable, alias, list, object } from 'serializr';

export class TemplateResourcesModel extends Model {
  @serializable
  @observable
  id;

  @serializable
  @observable
  filename;

  @serializable
  @observable
  lastUpdate;

  @serializable
  @observable
  mimetype;

  @serializable
  @observable
  originalFilename;

  @serializable
  @observable
  size;

  file;
}

class TemplateSourceModel extends Model {
  @serializable
  @observable
  filename;

  @serializable
  @observable
  lastUpdate;

  @serializable
  @observable
  mimetype;

  @serializable
  @observable
  originalFilename;

  @serializable
  @observable
  size;

  file;
  uploaded = false;
}

class SurveyMetadataTemplate {
  @serializable code = '';
}

class SurveyMetadata {
  @serializable(object(SurveyMetadataTemplate))
  template = {};
}

class SurveyTemplate {
  @serializable(object(SurveyMetadata))
  metadata = {};
}
class progenitorModel {
  @serializable(alias('_id'))
  id;
  @serializable name;
  @serializable version;
}

export default class TemplateModel extends Model {
  @serializable(alias('_id'))
  id;

  @serializable
  @observable
  isActive;

  @serializable
  @observable
  name;

  @serializable
  @observable
  description;

  @serializable
  @observable
  code;

  @serializable lastUpdate;

  @serializable version;

  @serializable deletable;

  @serializable(object(progenitorModel))
  @observable
  progenitor;

  @serializable(list(object(TemplateResourcesModel)))
  @observable
  resources = [];

  @serializable(object(TemplateSourceModel))
  @observable
  source = new TemplateSourceModel({});

  @serializable(object(SurveyTemplate))
  body;
}
