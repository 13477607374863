import { createDomainStore } from './base';
import { reportsService } from 'services/transport-layer';
import ReportModel from 'models/report';

export const { store: ReportsStore, listResolver: reportsResolver } = createDomainStore({
  name: 'reports',
  serviceToInject: reportsService,
  domainModel: ReportModel,
  useQuery: true
});
