import { BaseService } from '../base-service';

export class AdminProfileService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/profiles' });
  }

  fetchOne() {
    return this.get('/admin');
  }

  update(data = {}) {
    return this.put('/admin', { data });
  }

  updatePassword(data = {}) {
    return this.put('/password/admin', { data });
  }
}
