import { action, computed } from 'mobx';
import UIStore from './ui';
import moment from 'moment';
import { browserHistory } from 'react-router';

import { patientsService } from 'services/transport-layer';
import PatientModel from 'models/patient';
import { createDomainStore } from './base';

export const {
  store: PatientsStore,
  listResolver: patientsResolver,
  itemResolver: patientResolver
} = createDomainStore({
  name: 'patiens',
  serviceToInject: patientsService,
  domainModel: PatientModel,
  useQuery: true,
  selectors: {
    paramsSelector: nextState => [nextState.params.careUnitId],
    paramsItemSelector: nextState => [nextState.params.careUnitId, nextState.params.patientId],
    dataSelector: (_, response) => {
      return response.data.map(p => {
        p.careUnitName = response.orgunit.name;
        return p;
      });
    },
    modelNormalizer: model => {
      const m = model.patient || model;
      m.isSpareNumber = (m.personalNumber || '').length === 11;
      return m;
    }
  }
});

Object.assign(PatientsStore, {
  getListByCareUnitId(id) {
    return this.list.filter(p => p.careUnitId === id);
  },

  getCareUnitName() {
    return this.queryRawData.orgunit ? this.queryRawData.orgunit.name : '...';
  },

  create(model, careUnitId) {
    model.viewModel.id = Math.random();
    model.viewModel.address.viewModel.submit();
    model.viewModel.submit();

    UIStore.setToTransition();
    patientsService
      .createNew(
        careUnitId,
        Object.assign(model.serialize(), {
          _id: undefined,
          careUnitName: undefined,
          isSpareNumber: undefined
        })
      )
      .then(
        action(({ insertedId }) => {
          model.id = insertedId;
          model.lastUpdate = moment().format();

          this.addOrUpdateItem(model.serialize());

          browserHistory.push(`/home/care-units/${careUnitId}/patients`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  update(id, careUnitId) {
    const model = this.getItem(id);
    model.viewModel.submit();
    model.address.viewModel.submit();

    UIStore.setToTransition();

    patientsService
      .update(
        careUnitId,
        id,
        Object.assign(model.serialize(), {
          _id: undefined,
          lastUpdate: undefined,
          careUnitName: undefined,
          isSpareNumber: undefined
        })
      )
      .then(
        action(() => {
          browserHistory.push(`/home/care-units/${careUnitId}/patients`);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  delete(id, careUnitId) {
    UIStore.setToTransition();
    patientsService
      .delete(careUnitId, id)
      .then(
        action(() => {
          this.queryItems(false, [careUnitId]);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  }
});
