import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { browserHistory, Link } from 'react-router';

import DataTable from 'components/dataTable/data-table';
import { NavbarInfo } from 'components/navbar/navbar';
import ActionFooter from 'components/action-footer/action-footer';
import Button from 'components/button/button';
import { Tooltip } from 'react-tippy';
import FiltersPanel, { FilterView } from 'components/filter/filters-view';
import Input from 'components/input/input';

import {
  timeFormatter,
  applySorting,
  applySortingServerSide,
  isActiveToTextFormatter,
  deletableFormatter
} from 'formatters/tables';

import './templates.css';

import { translate, HideIf } from 'utils/helpers';
const t = translate(['common', 'templates']);

const quickLinkCell = (id, { rowData }, { templates, ui }) => {
  return (
    <span>
      <Tooltip title={t('editTemplate')} arrow="true" distance={20}>
        <Link data-test-id="EditTemplate" to={`/home/templates/${id}/edit`}>
          <img src={require('assets/svg/sc-edit.svg')} />
        </Link>
      </Tooltip>
      {!rowData.progenitor ? (
        <Tooltip title={t('assignTemplate')} arrow="true" distance={20}>
          <Link data-test-id="assignTemplate" to={`/home/templates/${id}/assign`}>
            <img src={require('assets/svg/sc-link.svg')} />
          </Link>
        </Tooltip>
      ) : (
        <Tooltip title={t('unassignTemplate')} arrow="true" distance={20}>
          <Link
            data-test-id="unassignTemplate"
            onClick={() => {
              if (rowData && rowData.progenitor) {
                ui.showModal(
                  () => {
                    templates.unlinkFromProgenitor(id, rowData.progenitor.id);
                  },
                  null,
                  t('yourSureUnlinkTemplate')
                );
              }
            }}
          >
            <img src={require('assets/svg/sc-link-rdy.svg')} />
          </Link>
        </Tooltip>
      )}
    </span>
  );
};

@inject('templates', 'ui')
@observer
class TemplatesPage extends Component {
  constructor(props) {
    super(props);
    const sortFormatter = applySortingServerSide(
      this.onSortClick,
      props.templates.viewState.sortState
    );

    this.columns = [
      {
        property: 'isActive',
        header: {
          label: t('isActive'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [isActiveToTextFormatter]
        }
      },
      {
        property: 'name',
        header: {
          label: t('name'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'progenitor',
        header: {
          label: t('progenitorName'),
          formatters: [
            progenitor =>
              sortFormatter(progenitor, {
                property: 'progenitor.name'
              })
          ]
        },
        cell: {
          formatters: [progenitor => (progenitor ? progenitor.name : '-')]
        }
      },
      {
        property: 'version',
        header: {
          label: t('version'),
          formatters: [sortFormatter]
        }
      },
      {
        property: 'lastUpdate',
        header: {
          label: t('lastUpdate'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [timeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },
      {
        property: 'id',
        header: {
          label: t('quickLink')
        },
        cell: {
          formatters: [
            (id, dataRow) => quickLinkCell(id, dataRow, this.props),
            deletableFormatter(this.onDelete)
          ],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    ];
  }

  onDelete = id => this.props.templates.delete(id);

  onRowClick(row) {
    return {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.nodeName !== 'IMG') {
          browserHistory.push(`/home/templates/${row.id}/edit`);
        }
      }
    };
  }

  onNewClick = () => browserHistory.push(`/home/templates/new`);

  onPageClick = selected => this.props.templates.viewState.changePage(selected);

  onSortClick = property => this.props.templates.viewState.applySort(property);

  isFiltered = () => this.props.templates.viewState.isFiltered;

  @computed
  get searchIsOpen() {
    return this.props.templates.viewState.openState.value;
  }

  render() {
    const { templates } = this.props;

    return (
      <div className="templates-page action-footer-padded">
        <NavbarInfo>{t('listTitle')}</NavbarInfo>
        <div className="panel">
          <DataTable
            data={templates.list}
            columns={this.columns}
            isLoading={templates.isListLoading}
            pageState={templates.viewState.pageState}
            pageCount={templates.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
        <FiltersPanel
          className="filter-centered"
          filterState={templates.viewState}
          onAnimationEnd={() => this.node && this.node.focus()}
        >
          <FilterView header={t('filters.name.header')} subheader={t('filters.name.desc')}>
            <Input
              data-test-id="searchByTemplateName"
              name="value"
              form={templates.viewState.templateSearchState}
              leftText
              placeholder={t('filters.name.templateNamePlaceholder')}
              onChange={templates.viewState.changeTemplateSearch}
              innerRef={node => (this.node = node)}
            />
          </FilterView>
        </FiltersPanel>

        <ActionFooter>
          <Button
            data-test-id="searchTemplate"
            action="secondary"
            color="steel"
            onClick={templates.viewState.toggleOpen}
          >
            {!this.searchIsOpen ? t('searchTemplate') : t('closeFilter')}
          </Button>
          <HideIf check={this.isFiltered}>
            <Button action="secondary" color="peach" onClick={templates.viewState.resetFiltered}>
              {t('resetFilter')}
            </Button>
          </HideIf>
          <Button
            action="primary"
            data-test-id="addTemplate"
            disabled={this.searchIsOpen}
            color={!this.searchIsOpen ? 'wisteria' : 'steel disabled'}
            onClick={this.onNewClick}
          >
            {t('addTemplate')}
          </Button>
        </ActionFooter>
      </div>
    );
  }
}

export default TemplatesPage;
