module.exports = {
  common: {
    username: 'Användarnamn',
    password: 'Lösenord',
    submit: 'Logga in',
    save: 'Spara',
    reset: 'Återställ',
    archive: 'Arkivera',
    logout: 'Logga ut',
    status: 'Status',
    edit: 'Editera',
    quickLink: 'Länk',
    note: 'Notis',
    value: 'Värde',
    lastUpdate: 'Senast uppdaterad',
    fullName: 'Namn',
    remove: 'Ta bort',
    address: 'Adress',
    name: 'Namn',
    email: 'Email',
    isActive: 'Aktiv',
    caredocs: 'Journal',
    diagnosis: 'Patientdiagnos',
    medications: 'Läkemedel',
    measurements: 'Mätningar',
    yourWorkIsNotSaved: 'Inte sparat, är du säker på att du vill avsluta?',
    code: 'Koda',
    description: 'Beskrivning',
    typeToSearch: 'Sök (minst 3 bokstäver)',
    noResults: 'Inga resultat',
    reallyWantToDelete: 'Vill du verkligen ta bort?',
    noDataAvailable: 'Inga data tillgängliga',
    loading: 'Laddar',
    openFilter: 'Öppna filter',
    closeFilter: 'Stäng filter',
    resetFilter: 'Återställ filter',
    yes: 'Ja',
    no: 'Nej',

    errors: {
      youCantOpenRecoveryWhenLoggedIn: `Du kan inte öppna återställningslänken när du är inloggad`
    }
  },

  login: {
    greeting: 'Välkommen till SwipeCare!',
    enterCredentials: 'Välkommen att logga in.',
    SITHSAuth: 'Logga in med SITHS ID',
    getBackToSITHS: 'Tillbaka till SITHS inlogging',
    loginViaSITHS: 'Logga in med SITHS',
    signInWithCredentials: 'Logga in med användarnamn',
    isAdmin: 'Administratör',

    forgotPassword: 'Glömt lösenord',
    greetingRecovery: 'Glömt lösenord?',
    enterCredentialsRecovery: 'Ange ditt användarnamn',
    backToLogin: 'Tillbaka till login',
    submitRecovery: 'Återskapa lösenord',
    update: 'Uppdatera lösenord',
    greetingRecoveryConfirm: 'Återställ lösenord',
    enterCredentialsRecoveryConfirm: 'Skriv in ditt lösenord',

    errors: {
      youCantOpenRecoveryWhenLoggedIn: `Återställningslänk kan inte öppnas när du är inloggad`
    }
  },

  navigation: {
    surveys: 'Undersökningar',
    careUnits: 'Vårdenheter',
    users: 'Användare',
    templates: 'Mallar',
    patients: 'Patienter',
    generalInformation: 'Allmän information',
    auditTrail: 'Uppdateringshistorik',
    sourceFile: 'Källfil',
    admins: 'Administratörer',
    reports: 'Logga rapporter',
    mapping: 'Mappning',
    routing: 'Routing',
    edit: 'Editera',
    help: 'Hjälp'
  },

  auditTrail: {
    compareType: 'Jämför typ',
    event: 'Händelse',
    timestamp: 'Tidsstämpel',

    backToAuditTrail: 'Tillbaka till Uppdateringshistorik',
    compareMode: 'Jämför mode',
    removed: 'Tog bort',
    termDetails: 'Term detaljer',
    dataRouteFound: 'Hittade data route',
    generalInfo: 'Generell information',
    termid: 'Term ID',
    termname: 'Termnamn',
    termtype: 'Termtyp',
    termdesc: 'Beskrivning',
    templateId: 'Mall ID',
    mappingId: 'Mappning ID',
    templateType: 'Malltyp',
    directPush: 'Skicka direct',
    test: 'Test',
    converter: 'Konverter',
    sender: 'Sender',
    viewDetails: 'Visa detaljer',

    events: {
      Uploaded: 'Ny undersökning är uppladdad',
      DataExtracted: 'Läst undersökning är uppladdad',
      ErrDataExtracted: 'Fel vid läsande av uppladdad undersökning',
      RetryProcessing: 'Försök igen att exekvera uppladdad undersökning',
      MarkedAsDefective: 'Felmarkerad',
      SITHS_Confirmed: 'SITHS signerad',
      QA_Started: 'QA startad',
      Ready4QA: 'Redo för QA',
      ManualDataSendingRequired: 'Manuell dataöverföring krävs',
      Archived: 'Arkiverad',
      Unarchived: 'Framtagen ur arkiv',
      DataSaved: 'Data sparat',
      DataSendingManual: 'Data sänds manuellt',
      DataSendingAttempt: 'Datasändningsförsök',
      DataSent: 'Data skickat',
      ErrDataSent: 'Fel vid data sändning',
      DataRouteFound: 'Data route detekterad',
      DataUpdated: 'Fel vid data route detektering'
    },

    types: {
      Uploaded: 'Sparade fil identiteter, namn',
      DataExtracted: 'Generell undersökningsinformation',
      ErrDataExtracted: 'Generell undersökningsinformation',
      RetryProcessing: 'Generell undersökningsinformation',
      MarkedAsDefective: 'Generell undersökningsinformation',
      Ready4QA: 'Generell undersökningsinformation',
      ManualDataSendingRequired: 'Generell undersökningsinformation',
      Archived: 'Generell undersökningsinformation',
      Unarchived: 'Generell undersökningsinformation',
      DataSaved: 'EMR data. Känslig information',
      DataSendingManual: 'Generell undersökningsinformation',
      DataSendingAttempt: 'Generell undersökningsinformation',
      DataSent: 'Generell undersökningsinformation',
      ErrDataSent: 'Generell undersökningsinformation',
      DataRouteFound: 'Route detaljer',
      DataUpdated: 'Generell undersökningsinformation',
      SITHS_Confirmed: 'SITHS signerad'
    }
  },

  help: {
    loginTutorial: 'Login tutorial',
    loginTutorHelp: 'This video will explain got to login into the Swipecare app.',
    goBackToLogin: 'Get back to login',
    helpTitle: 'Hjälpfilmer',
    tutorialsNav: 'Hjälpfilmer: ',
    headerSubtitle: 'Hjälpfilmer för användning av SwipeCare',
    footerHelpOne:
      'Gå in på intranätet Praktikertjänst Insikt: insikt.ptj.se. Klicka på Praktikertjänst N.Ä.R.A. Tryck på rutan ”Support Kontakta oss” till höger. Fyll i formuläret och ange kategori IT och Stelpa.',
    footerHelpTwo: 'Du kan också ringa IT supporten på 010-1284400 eller maila på:',
    footerHelpThree:
      'Om du behöver mer hjälp, kontakta Linda Vogt, sjuksköterska på Praktikertjänst N.Ä.R.A. som varit med och utvecklat produkten.',
    footerHelpEmail: 'Epost:',
    footerHelpEmailPraktiker: 'praktikerservice@ptj.se',
    footerHelpEmailLinda: 'Linda.Vogt@ptj.se'
  },

  surveys: {
    listTitle: 'Undersökningar',
    detailsTitle: 'Undersökning',
    editTitle: 'Editera undersökning',
    backToSurveysList: 'Tillbaka till undersökningar',
    waitingForQa: 'Väntar på granskning',
    requiringAttention: 'Kräver åtgärd',
    imported: 'Importerad',
    qaStage: 'Granskas',
    retrySending: 'Skicka igen',
    retryProcessing: 'Bearbeta igen',
    archiveSurvey: 'Arkivera undersökning',
    unarchiveSurvey: 'Avarkivera undersökning',
    markAsDefective: 'Markera som felaktig',
    confirmSITHS: 'Konfirmera med SITHS kort',
    readyForEMR: 'Klar för journal',
    startQa: 'Börja granskning',
    finished: 'Färdig',
    requireSITHSConfirmation: 'SITHS konfirmering krävs',
    defective: 'Felaktig',
    archived: 'Arkiverad',
    importGaveUp: 'Fel vid sändning',
    uploadDate: 'Skickad datum',
    uploadBy: 'Skickad av',
    qa: 'Granskning',
    careUnit: 'Vårdenhet',
    surveyType: 'Undersökningstyp',
    doctor: 'Personal',
    patient: 'Patient',
    sendToEmr: 'Skicka till journal',
    uploadedBy: 'Skickad av',
    templateCode: 'Mall kod',
    patientName: 'Patient namn',
    doctorName: 'Användare',
    doctorSpeciality: 'Specialitet',
    termName: 'Termnamn',
    valueTerms: 'Värdetermer',
    termType: 'Termtyp',
    visitDate: 'Datum',
    metadata: 'Metadata',
    id: 'ID',
    templateName: 'Mallnamn',
    careUnitCode: 'VårdenhetsCode',
    careUnitInternalId: 'VårdenhetsID',
    visit: 'Undersöking',
    time: 'Tid',
    durationInMinutes: 'Tidsåtgång',
    closeFilter: 'Stäng filter',
    error: 'Fel',
    addNewToEmr: 'Lägg till Term',
    openAddNewToEmr: 'Lägg till',
    closeAddNewToEmr: 'Stäng',
    addNewBnt: 'Lägg till',
    export: 'Exportera',
    numeric: 'Värde',

    filters: {
      errors: {
        pleaseInputCorrectDate: 'VG ange datum'
      },
      status: {
        header: 'Sök på status',
        desc: 'Du kan använda snabbfilter',
        chooseStatus: 'Välj status'
      },
      name: {
        header: 'Sök på namn',
        desc: 'Filtrera på namn',
        patientNameLabel: 'Namn eller personnummer på patient',
        patientNamePlaceholder: 'Lägg till ny patient...',
        templateNameLabel: 'Besöksmall',
        templateNamePlaceholder: 'Välj besöksmall...',
        qaByLabel: 'Granskad av',
        qaByPlaceholder: 'Skriv in personal namn...',
        uploadedByLabel: 'Skickad av',
        uploadedByPlaceholder: 'Skriv uppladdat namn...',
        doctorByLabel: 'Personal',
        doctorByPlaceholder: 'Skriv personal namn...',
        careUnitNameLabel: 'Vårdenheter',
        careUnitNamePlaceholder: 'Mata in vårdenhetens namnet...'
      },
      date: {
        header: 'Datum',
        desc: 'Filtrera på datum',
        fromLabel: 'Från',
        fromPlaceholder: 'Skriv datum på format 2017-01-01...',
        toLabel: 'Till',
        toPlaceholder: 'Skriv datum på format 2017-01-01...'
      },
      lastUpdate: {
        labelFrom: 'Senast uppdaterad från',
        placeholderFrom: 'Skriv datum på format 2017-01-01...',
        labelTo: 'Senast uppdaterad till',
        placeholderTo: 'Skriv datum på format 2017-01-01...'
      }
    },

    errors: {
      termNameAlreadyExist: 'Denna term finns reedan i undersökningen',
      termNameIsNotSelected: 'Termnamn inte valt'
    }
  },

  reports: {
    listReports: 'Lista på loggar',
    filename: 'Filnamn',
    mimetype: 'MIME typ',
    size: 'Storlek',
    searchReport: 'Sök loggrapporter',
    filters: {
      name: {
        header: 'Sök på användarens namn, anteckning, datum',
        desc: 'Filtrera loggrapporter på användarens namn, anteckning och på tidsperiod',
        userNameLabel: 'Användarnamn',
        reportUserPlaceholder: 'Börja skriva användarens namn...',
        noteLabel: 'Anteckning',
        reportNotePlaceholder: 'Börja skriva anteckning...'
      },
      date: {
        fromLabel: 'Datum fr.o.m.',
        fromPlaceholder: 'Datum i format ÅÅÅÅ-MM-DD',
        toLabel: 'Datum t.o.m.',
        toPlaceholder: 'Datum i format ÅÅÅÅ-MM-DD'
      }
    },
    export: 'Exportera'
  },

  modal: {
    confirmationTitle: 'Bekräftelse',
    confirm: 'Bekräfta',
    cancel: 'Avbryt'
  },

  patients: {
    listTitle: 'Patient lista för {{careUnit}}',
    backToCareUnitsList: 'Tillbaka till vårdenheter',
    backToPatientsList: 'Tillbaka till patienter',
    personalNumber: 'Personnummer',
    isSpareNumber: 'Använd Reservnummer',
    personalOrSpareNumber: 'Personnummer eller sparenumber',
    addPatient: 'Lägg till patient',
    editPatient: 'Editera patient {{name}}',
    createPatient: 'Lägg till patient',
    male: 'Man',
    female: 'Kvinna',
    sex: 'Kön',
    birthday: 'Födelsedatum',
    postCode: 'Postnummer',
    city: 'Ort',
    street: 'Gatuadress',
    number: 'Nummer',
    co: 'c/o',
    searchPatient: 'Sök patient',
    patientPlace: 'Plats',
    filters: {
      name: {
        header: 'Sök patient',
        desc: 'Sök patienter efter namn och personnummer',
        fullNamePlaceholder: 'Ange för- och efternamn',
        fullNameLabel: 'För- och efternamn',
        personalNumberLabel: 'Personnummer',
        patientPlaceLabel: 'Plats',
        patientPlacePlaceholder: 'Ange Plats',
        sparelNumberLabel: 'Sparenumber',
        personalNumberPlaceholder: 'Ange personnummer',
        spareNumberPlaceholder: 'Ange sparenumber'
      }
    },

    errors: {
      inputCorrectPersonalNumber: 'VG skriv in korrekt personnummer',
      inputCorrectSpareNumber: 'VG skriv in korrekt sparenumber',
      inputCorrectFullNam: 'VG fyll i för att fortsätta'
    }
  },

  nurses: {
    listTitle: 'Personallista på {{careUnit}}',
    backToCareUnitsList: 'Tillbaka till vårdenhetslista',
    searchNurse: 'Sök personal',
    filters: {
      name: {
        header: 'Sök på namn',
        desc: 'Sök personal på namn, användarnamn',
        nurseNamePlaceholder: 'Sök personal'
      }
    }
  },

  users: {
    listTitle: 'Användare',
    listAdminTitle: 'Administratörer',
    userNameTitle: 'Användarnamn',
    userName: 'Användarnamn (t.ex. HSA ID)',
    email: 'Email',
    addUser: 'Lägg till användare',
    editUser: 'Editera {{name}}',
    editAdmin: 'Editera administratör {{name}}',
    createUser: 'Lägg till användare',
    backToUsersList: 'Tillbaka till användare',
    backToAdminsList: 'Tillbaka till Administratörer',
    confirmPassword: 'Bekräfta lösenord',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    assign: 'Tilldela',
    jobTitle: 'Titel',
    isNotSendEmail: 'Använderen auktoriseras med SITHS (inget mejl med lösenordsinfo skickas)',
    sithsId: 'HSA ID (anges vid avvikelse)',
    hsaId: 'TakeCare användarnamn',
    expirationDemo: '[SV] Expiration demo time',
    oldPassword: 'Gammalt lösenord',
    newPassword: 'Nytt lösenord',
    confirmNewPassword: 'Bekräfta nytt lösenord',
    updatePassword: 'Ändra lösenord',
    updateProfile: 'Ändra profil',
    editProfile: 'Ändra profil ({{name}})',
    backToSurveyList: 'Tillbaka till undersökningar',
    backToCareList: 'Tillbaka till vårdenheter',
    careUnit: 'Vård enhet',
    searchUser: 'Sök användare',
    backToNurseList: 'Tillbaka till personallista',
    export: 'Exportera',
    filters: {
      name: {
        header: 'Sök på namn',
        desc: 'Att söka på efter namn, användarnamn eller HSA ID',
        userNamePlaceholder: 'Nelle Meyer eller 9FA8CBB8-2A...'
      },
      isActiveOnly: 'Aktive endast'
    },

    errors: {
      passwordsShouldMatch: 'Lösenorden skall vara lika',
      emailShouldMatchRules: 'Fel format på mailadress',
      pleaseInputField: 'Måste fyllas i',
      pleaseInputOldPassword: 'Ange gamla lösenordet',
      pleaseInputNewPassword: 'Ange lösenord',
      pleaseInputToMatchPassword: 'Ange matchande lösenord',
      inputCorrectExpirationDemo: '[SV]Incorrect format of date'
    }
  },

  templates: {
    listTitle: 'Mallar',
    linkTitle: 'Sammankoppling för mallar',
    addTemplate: 'Lägg till mall',
    templateCode: 'Mall kod',
    templateDescription: 'Mallbeskrivning',
    version: 'Version',
    createTemplate: 'Skapa mall',
    editTemplate: 'Editera mall {{name}}',
    templateSourceFile: 'Källfil för mall (json)',
    noFilesSelected: 'Ingen fil vald',
    chooseNewFile: 'Välj ny fil',
    processingYourFile: 'Filen behandlas',
    resourceId: 'Resurs ID: {{resourceId}}',
    backToTemplateList: 'Tillbaka till Mallar',
    assignTemplate: 'Skapa koppling',
    applyTemplate: '[SV] apply template',
    unassignTemplate: 'Ta bort koppling',
    progenitorName: 'Kopplad mall',
    templateLink: 'Välj mall att koppla',
    yourSureUnlinkTemplate: 'Är du säker att du vill ta bort länken från mallen?',
    APPLY: '[SV] APPLY',
    ASSIGN: 'Koppla',
    UNASSIGN: 'Ta bort koppling',
    ASSIGNED: 'Kopplad',
    searchTemplate: 'Sök mall',
    filters: {
      name: {
        header: 'Sök på namn',
        desc: 'Sök mallar på namn',
        templateNamePlaceholder: 'Ange namn'
      }
    },
    errors: {
      pleaseInputField: 'Detta fält får inte vara tomt',
      addAllResources: 'VG lägg till alla resurser',
      incorrectJSONFile: 'Felaktig JSON eller JS fil uppladdad',
      fileTypeIsInvalid: 'Den uppladade filen har inkorrekt filtyp.'
    }
  },

  careUnits: {
    listItems: 'Vårdenheter',
    parentCareUnit: 'Överordnad vårdenhet',
    addCareUnit: 'Lägg till vårdenhet',
    createCareUnit: 'Lägg till vårdenhet',
    backToCareUnitsList: 'Tillbaka till vårdenheter',
    editCareUnit: 'Editera vårdenhet {{name}}',
    optionalSITHS: 'Tillåt lösenordslogin',
    allowChecklist: 'Tillåt checklistan',
    EMRRoute: 'Route',
    checklist: 'Checklista',
    internalId: 'Vårdenhetsid',
    patientList: 'Patientlista',
    nurseList: 'Personallista',
    routeAvailableToLink: 'Route är tillgänglig för länkning',
    routeLinked: 'Länkad route',
    sharedTemplate: 'Gemensam mall',
    sharedTemplateWithRoute: 'Ta bort markering "preliminär"',
    readyToShareTemplate: 'Markera som "preliminär"',
    directPush: 'Direkt sändning',
    global: 'Global',
    local: 'Lokal',
    productionReady: 'Redo för produktion',
    chooseRouteFor: 'Välj route för {{templateName}}',
    routeName: 'Route namn',
    services: 'Tjänster',
    templateId: 'Mall ID',
    cgmxHSAId: 'CGMX HSA ID',
    searchCareUnit: 'Sök vårdenhet',
    logoutTime: 'Logga ut efter inaktivitet ({{min}}-{{max}} minuter)',
    logoutTimeSwiperead: 'Logga ut efter inaktivitet ({{min}}-{{max}} minuter)',
    filters: {
      name: {
        header: 'Sök på namn',
        desc: 'Sök på ID',
        careUnitNamePlaceholder: 'Ange namn'
      }
    },

    errors: {
      pleaseInputField: 'Detta fält får inte vara tomt',
      inputCorrectCgmxHSAId: 'Var god, ange giltigt CGMX HSA ID',
      inputCorrectLogoutTime: 'Endast heltal är tillåtna i det här fältet ({{min}}-{{max}} minuter)'
    }
  },

  mapping: {
    listMapping: 'Mappningslista',
    isActive: 'Aktiv',
    elements: 'Element',
    pages: 'Sidor',
    questions: 'Frågor',
    search: 'Sök på id, term eller beskrivning',
    elementId: 'Element ID',
    termId: 'Term ID',
    addElement: 'Lägg till element',
    addPage: 'Lägg till sida',
    addQuestion: 'Lägg till fråga',
    addMapping: 'Lägg till mapping',
    createMapping: 'Skapa mapping',
    editMapping: 'Redigera {{name}}',
    backToMappingList: 'Tillbaka till mapping listan',
    exportFile: 'Exportera',
    importFile: 'Importera',
    complex: 'Komplex',
    searchMapping: 'Sök mappning',
    filters: {
      name: {
        header: 'Sök på namn',
        desc: 'Sök mappningar på namn',
        mappingNamePlaceholder: 'Ange namn'
      }
    }
  },

  routing: {
    listRouting: 'Routing lista',
    routingName: 'Routing namn',
    isActive: 'Aktiv',
    testEmr: 'Testa EMR',
    signEmr: 'Skicka som signerad',
    directPush: 'Skicka direkt',
    converter: 'Konverter',
    sender: 'Sender',
    templateId: 'Mall ID',
    templateType: 'Mall typ',
    mappingId: 'Mapping ID',
    addRouting: 'Lägg till routing',
    editRouting: 'Redigera {{name}}',
    backToRoutingList: 'Tillbaka till routing listan',
    templateTypeLocal: 'Lokal',
    templateTypeGlobal: 'Global',
    mappingName: 'Mapping namn',
    typeMappingName: 'Börja skriva mappings namn...',
    mappingNamesNotLoaded: 'Mappings namn har inte laddats',
    convertersNotLoaded: 'Converters har inte laddats',
    sendersNotLoaded: 'Senders har inte laddats',
    searchRouting: 'Sök routing',
    filters: {
      name: {
        header: 'Sök efter namn',
        desc: 'Filtrera routingar efter namn',
        routingNamePlaceholder: 'Ange namn'
      }
    }
  },

  errorsFromBackend: {
    unexpected_database_error: 'Databasfel',
    unexpected_error: 'Oväntat fel',
    user_username_exists: 'Användarnamnet finns redan',
    admin_username_exists: 'Admin med detta användarnamn finns redan',
    patient_personal_number_exists: 'Patent med detta personnummer finns redan på denna vårdenhet',
    orgunit_code_exists: 'Vårdenhet med denna ID finns redan',
    parent_orgunit_can_not_have_employees:
      'Överordnad enhet kan inte ha personal. VG ladda om web sidan och försök igen',
    parent_orgunit_can_not_have_patients:
      'Överordnad enhet kan inte ha patienter. VG ladda om web sidan och försök igen',
    incorrect_credentials: 'Fel lösenord eller användarnamn',
    user_credentials_are_deactivated: 'Din användare är inaktiv, Kontakta SwipeCare administratör',

    selected_orgunits_have_child_orgutins:
      'En del av de valda vårdenheterna har underordnade enheter. VG ladda om websidan och försök igen',
    orgunit_has_child_orgutins:
      'Vårdeneheten har underordnade enheter. Välj en enhet utan underordnade enheter för at lägga till patieneter',
    template_code_exists: 'Det finns redan en mall med denna kod',
    template_data_absent: 'Data för mallen är inte komplett eller felaktigt',
    do_not_have_admin_rights: 'Du har inte administratörsrättigheter',
    can_not_find_patient_in_orgunit: 'Kan inte hitta pienten på vårdenheten',
    can_not_find_orgunit: 'Kan inte hitta vårdenheten',
    can_not_find_survey_by_id: 'Kan inte hitta undersökningens ID',
    do_not_have_access_to_survey: 'Du har inte rättigheter för den valda undersökningen',
    do_not_have_access_to_orgunit: 'Du har inte rättigheter för den valda vårdenheten',
    survey_is_not_active_or_does_not_exist: 'Undersökningen är inte aktiv eller finns inte',
    action_is_forbidden_because_of_survey_status: 'Kan ej utföras pga underökningens status',
    can_not_find_survey_to_read: 'Kan inte hitta information för att läsa undersökning',
    can_not_find_survey_to_send: 'Kan inte hitta information för att skicka undersökning',
    can_not_find_survey_to_adopt: 'Kan inte hitta information för att ändra undersökning',
    can_not_find_survey_to_process: 'Kan inte hitta information för att tranformera undersökning',
    can_not_find_route_code_for_survey: 'Kan inte hitta route kod för att skicka undersökning',
    can_not_find_route_in_config: 'Kan inte hitta route för undersökning in konfigurationsfil',
    can_not_load_reader: 'Kan inte starta läsare för undersökning',
    can_not_load_converter: 'Kan inte starta konverterare för undersökning',
    can_not_load_sender: 'Kan inte ladda sändaren för undersökningen',
    can_not_find_orgunit_id_by_code: 'Kan inte hitta vårdenhet med kod',
    can_not_find_template_id_by_code: 'Kan inte hitta mall med kod',
    can_not_find_template_to_update: 'Kan inte hitta mall att uppdatera',
    username_can_not_be_empty: 'Användarnamn kan inte vara tomt',
    can_not_send_empty_emr_data: 'Kan inte skicka tomma journaldata',

    required_fields_are_absent: 'Ett eller flera obligatoriska fält saknas',
    invalid_value_of_fields: 'Ett eller flera fält har ogiltiga värden',
    invalid_type_of_fields: 'Ett eller flera fält har ogiltig informationstyp',
    unexcpected_validation_error: 'Oväntat valideringsfel',

    disallowed_fields: 'En del fält kan inte modifieras',
    unbing_child_before_deletion: 'Ta bort underordnade enheter innan du tar bort denna enhet',
    unknown_action_for_survey: 'Okänd åtgärd för undersökning',
    incorrect_current_password: 'Fel lösenord',

    user_hsaid_exists: 'Det finns redan en användare med detta HSA ID',
    offline_error: 'Kontrollera din internetanslutning',

    siths_user_is_not_registered: 'SITHS användare är inte registrerad SwipeCare systemet',

    user_email_exists: 'Det finns redan en användare med den här e-mail adressen',
    user_password_is_not_generated: 'Ditt lösenord är inte skapat',
    there_is_no_user_email:
      'Det finns ingen e-mail kopplad till den här användaren. Kontakta administratör.',
    there_are_surveys_belong_to_deleting_orgunit:
      'Det finns undersökningar som är beroende av denna organisationsenhet som du vill ta bort',
    invalid_siths_session: 'Ogiltig SITHS-session (låst kort?)',
    incorrect_token: 'Oauktoriserad eller ogiltig token',
    incorrect_tenant_data_in_token: 'Inkorrekt tenant data i token',
    tenant_data_mismatch: 'Omatchande tenant data',
    cgmx_error: 'Fel hos CGMX',
    auth_session_is_expired: 'Auth session gick ut',
    incorrect_help_token: 'Inkorrekt/för gammal hjälp token',
    incorrect_siths_credentials: 'Inkorrekt SITHS referens',
    password_can_not_be_empty: 'Lösenord får inte vara toma',
    resource_files_are_missed: 'Vissa filresurser saknas i mallen',
    cgmx_hsa_id_does_not_assign_4_orgunit: 'CGMX HSA ID kopplar ej till vårdenhet',
    can_not_find_survey_body: 'Kan ej hitta "body" i undersökningen',
    can_not_find_survey_emr_data:
      'Det gick inte att hitta journaldata' || 'Kan ej hitta journaldata i undersökningen',
    can_not_find_preliminary_survey: 'Kan ej hitta preliminär undersökning',
    can_not_find_preliminary_survey_body: 'Kan ej hitta "body" i preliminära undersökningen',
    survey_is_deactivated: 'Undersökningen är inaktiverad',
    invalid_data_to_change_survey: 'Ogiltig data, kan ej redigera undersökningen',
    empty_action_for_survey: 'Saknas händelse för undersökningen',
    can_not_find_survey_to_convert: 'Saknar information för att konvertera undersökningen',
    can_not_find_route: 'Saknar route för undersökningen',
    can_not_find_route_id: 'Saknar route ID för undersökningen',
    can_not_find_mapping: 'Saknar mappning för undersökningen',
    can_not_find_mapping_id: 'Saknar mapping ID för undersökningen',
    can_not_find_template: 'Saknar mallen',
    can_not_find_progenitor: 'Saknar "progenitor" i mallen',
    can_not_find_template_body: 'Saknar "body" i mallen',
    can_not_find_template_by_id: 'Kan ej hitta mallen med ID',
    can_not_find_audit_trail_record: 'Kan ej hitta audit trail record',
    can_not_find_mapping_by_id: 'Kan ej hitta mappning med ID',
    route_using_mapping: 'En eller flera routes använder den här mappningen',
    orgunit_using_route: 'En eller fler vårdenheter använder den här routen',
    there_are_surveys_based_on_deleting_template:
      'Det finns undersökningar som är beroende av den här mallen',
    can_not_find_template_to_delete: 'Saknar mall att ta bort',
    session_id_is_absent: 'Saknar session ID',
    callback_url_is_absent: 'Saknar Callback URL',
    incorrect_length_of_field: 'Ett av fälten har inkorrekt längd',
    incorrect_patient_personal_number: 'Patientens personnummer är ogiltigt',
    patient_information_is_absent: 'Saknar patientinformation',
    careunit_information_is_absent: 'Saknar vårdenhetsinformation',
    report_file_is_absent: 'Saknar rapportfilen',
    can_not_find_user_by_username: 'Hittar ej användaren med användarnamnet',
    can_not_find_orgunit_by_id: 'Hittar ej vårdenhet med ID',
    user_is_deactivated: 'Användaren är inaktiverad',
    orgunit_is_deactivated: 'Vårdenheten är inaktiverad',
    template_is_deactivated: 'Mallen är inaktiverad',
    user_do_not_have_access_to_orgunit: 'Användaren saknar tillträde till vårdenheten',
    ticket_is_locked: 'Biljetten är låst',
    ticket_is_outdated: 'Biljetten har gått ut',
    ticket_is_not_activated: 'Biljetten är inaktiv',
    unknown_listening_type: 'Okänd "listening" typ',
    outdated_token: 'Token har gått ut',
    incorrect_token_4ws: 'Inkorrekt token för web socket',
    unavailable_cgmx_data_type: 'Datatypen är otillgänglig i CGMX',
    patient_ID_is_required_search_parameter: 'Patient ID är krävs för sökning',
    patient_number_is_required_search_parameter: 'Patientnummer krävs för sökning',
    do_not_have_valid_patient_consent: 'Du saknar giltig medgivande från patienten',
    user_does_not_have_HSA_ID: 'Användaren saknar HSA ID',
    tenant_ID_is_absent: 'Tenat ID saknas',
    tenant_ID_is_invalid: 'Tenant ID är ogiltig',
    can_not_find_assessment: 'Gick ej att hitta bedömningen',
    can_not_find_user_profile: 'Gick ej att hitta användarprofilen'
  }
};
