import React, { Component } from 'react';
import { reaction, observable, runInAction, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import { diffChars } from 'diff';

import { NavbarInfo } from 'components/navbar/navbar';
import BackTo from 'components/backTo/back-to';
import DataTable from 'components/dataTable/data-table';

import './survey-audit-diff.css';

import { translate } from 'utils/helpers';
const t = translate(['common', 'surveys', 'auditTrail']);

const typeOfChange = [
  { key: 'termId', text: t('id') },
  { key: 'termName', text: t('termName') },
  { key: 'value', text: t('termValue') },
  { key: 'note', text: t('termNote') }
];

function DiffTable({ current, previous }) {
  const columns = [
    {
      property: 'typeOfChange',
      header: { label: '' }
    },
    {
      property: 'prev',
      header: { label: `${previous.timestamp.format('dddd, MMMM Do YYYY h:mm:ss a')}` }
    },
    {
      property: 'current',
      header: { label: `${current.timestamp.format('dddd, MMMM Do YYYY h:mm:ss a')}` }
    }
  ];

  let diffData = [];
  let addData = [];
  let removedData = [];

  current.value.forEach(compareItem => {
    const prevItem = previous.value.find(item => item.termId === compareItem.termId);
    const diff = [];
    const add = [];

    typeOfChange.forEach((changeType, i) => {
      const currentItemData = compareItem[changeType.key].toString();
      let prevItemData;
      let currentDiff = [];
      let previousDiff = [];

      if (!prevItem) {
        add.push({
          id: i,
          typeOfChange: changeType.text,
          current: (
            <span key={i} style={{ color: '#66bb6a' }}>
              {currentItemData}
            </span>
          ),
          prev: ''
        });

        return;
      }

      prevItemData = prevItem[changeType.key].toString();

      diffChars(prevItemData, currentItemData).map((part, i) => {
        if (part.removed) {
          currentDiff.push('');
        } else {
          const color = part.added ? '#66bb6a' : null;
          currentDiff.push(
            <span key={i} style={{ color }}>
              {part.value}
            </span>
          );
        }

        if (part.added) {
          previousDiff.push('');
        } else {
          const color = part.removed ? '#dd5c60' : null;
          previousDiff.push(
            <span key={i} style={{ color }}>
              {part.value}
            </span>
          );
        }
      });

      diff.push({
        id: i,
        typeOfChange: changeType.text,
        current: currentDiff,
        prev: previousDiff
      });
    });

    if (diff.length) {
      diffData.push(diff);
    }

    if (add.length) {
      addData.push(add);
    }
  });

  previous.value.forEach(item => {
    if (current.value.findIndex(currentItem => currentItem.termId === item.termId) === -1) {
      removedData.push(
        typeOfChange.map((changeType, i) => ({
          id: i,
          typeOfChange: changeType.text,
          current: (
            <span key={i} style={{ color: '#dd5c60' }}>
              {t('removed')}
            </span>
          ),
          prev: item[changeType.key].toString()
        }))
      );
    }
  });

  const data = [...removedData, ...addData, ...diffData];

  return (
    <div>
      {data.map((tableData, i) => <DataTable key={i} data={tableData} columns={columns} />)}
    </div>
  );
}

@observer
class DiffView extends Component {
  @observable leftLoading = true;
  @observable rightLoading = true;

  @observable leftSideItem = null;
  @observable rightSideItem = null;

  componentDidMount() {
    reaction(() => this.props.diff.get(), () => this.fetchAll());

    // Initial fetch
    this.fetchAll();
  }

  fetchAll() {
    this.getDiffLeft().then(this.getDiffRight);
  }

  getDiffLeft() {
    this.leftLoading = true;
    return this.props.trail.fetchTrailEntity(this.props.diff.get()).then(model =>
      runInAction(() => {
        this.leftSideItem = model;
        this.leftLoading = false;
      })
    );
  }

  @action
  getDiffRight = () => {
    this.rightLoading = true;
    const currentIdx = this.props.trail.timeline.findIndex(
      line => line._id === this.props.diff.get()
    );
    const nextIdx = currentIdx + 1;
    const nextItem = this.props.trail.timeline[nextIdx];

    if (nextItem) {
      this.props.trail.fetchTrailEntity(nextItem._id).then(model =>
        runInAction(() => {
          this.rightSideItem = model;
          this.rightLoading = false;
        })
      );
    } else {
      this.rightSideItem = Object.assign({}, this.leftSideItem, { value: [] });
      this.rightLoading = false;
    }
  };

  render() {
    if (this.rightLoading || this.leftLoading) {
      return (
        <DataTable isLoading={{ get: () => true }} columns={[1, 2, 3]} itemsCountPerPage={4} />
      );
    }

    return (
      <div>
        <DiffTable previous={this.rightSideItem} current={this.leftSideItem} />
      </div>
    );
  }
}

@observer
class TimelineItem extends Component {
  componentDidMount() {
    if (this.isSelected()) {
      this.node.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }

  selectLine = () => {
    this.props.trail.selectDiff(this.props.line.id);
  };

  isSelected() {
    return this.props.trail.selectedDiff.get() === this.props.line.id;
  }

  render() {
    const { line } = this.props;

    return (
      <li
        ref={node => (this.node = node)}
        key={line.id}
        className={cx({ selected: this.isSelected() })}
        onClick={this.selectLine}
      >
        <div>
          <time>{line.timestamp.format('dddd, MMMM Do YYYY[\n]h:mm:ss a')}</time>
        </div>
      </li>
    );
  }
}

const TimelineList = observer(({ trail }) => {
  const items = trail
    .aggregatedTimeline()
    .map(line => <TimelineItem key={line.id} line={line} trail={trail} />);

  return <ul>{items}</ul>;
});

@inject('trail')
@observer
export default class TrailDiffPage extends Component {
  render() {
    const backTo = (
      <BackTo
        title={t('backToAuditTrail')}
        to={`/home/surveys/${this.props.params.id}/${this.props.params.guid}/trail`}
      />
    );

    return (
      <div>
        <NavbarInfo content={backTo}>{t('compareMode')}</NavbarInfo>
        <div className="timeline-views">
          <section className="left-panel">
            <DiffView trail={this.props.trail} diff={this.props.trail.selectedDiff} />
          </section>
          <section className="right-panel timeline">
            <TimelineList trail={this.props.trail} />
          </section>
        </div>
      </div>
    );
  }
}
