import React, { Component } from 'react';
import { observer } from 'mobx-react';

import cx from 'classnames';

import './checkbox.css';

@observer
export default class Checkbox extends Component {
  onCheck = event => {
    if (this.props.setFieldValue) {
      this.props.setFieldValue(this.props.name, !this.props.checked);
    } else {
      this.props.form[this.props.name] = !this.props.form[this.props.name];
    }
    this.props.onChange && this.props.onChange(event);
  };

  getChecked() {
    const { form, name, checked } = this.props;

    return form ? form[name] || false : checked;
  }

  render() {
    const {
      form,
      name,
      label,
      className,
      classNameLabel,
      classNameRoot,
      isFormik,
      checked,
      readOnly,
      setFieldValue,
      ...rest
    } = this.props;

    if (label) {
      let src = '';
      if (!readOnly) {
        src = this.getChecked()
          ? require('assets/svg/ic-checkbox-active.svg')
          : require('assets/svg/ic-checkbox-default.svg');
      } else {
        src = this.getChecked()
          ? require('assets/svg/ic-checkbox-active-disabled.svg')
          : require('assets/svg/ic-checkbox-default.svg');
      }

      return (
        <div
          data-test-id={rest[`data-test-id`]}
          className={cx('custom-checkbox-wrapper', classNameRoot)}
          name={name}
        >
          <div
            onClick={readOnly ? undefined : this.onCheck}
            className={cx('custom-checkbox-wrapper')}
          >
            <img
              src={src}
              id={name + '_img'}
              className={cx('custom-checkbox', className, { notAllow: readOnly })}
            />
            <label className={cx('custom-checkbox-label', classNameLabel, { notAllow: readOnly })}>
              {label}
            </label>
          </div>
        </div>
      );
    }

    return (
      <input
        className={cx('custom-checkbox', className)}
        onChange={readOnly ? undefined : this.onChange}
        type="checkbox"
        checked={this.getChecked()}
        name={name}
        {...rest}
      />
    );
  }
}
