import { action } from 'mobx';
import { userService } from 'services/transport-layer';
import { UserModel } from 'models/user';
import { createDomainStore } from './base';
import { browserHistory } from 'react-router';
import UIStore from 'stores/ui';
import SessionStore from './session';
import { encodeUrlQuery } from 'utils/helpers';
import moment from 'moment';

const config = {
  name: 'users',
  serviceToInject: userService,
  domainModel: UserModel,
  useQuery: true
};

export const {
  store: UsersStore,
  listResolver: usersResolver,
  itemResolver: userResolver
} = createDomainStore(config);

export function resetModelPasswords(model) {
  model.password = undefined;
  model.confirmPassword = undefined;
}

function sanitazeUser(userModel) {
  // special cases for backend
  if (!userModel.email) {
    userModel.email = null;
  }

  // special cases for backend
  if (!userModel.hsaId) {
    userModel.hsaId = null;
  }

  // special cases for backend
  if (!userModel.sithsId) {
    userModel.sithsId = null;
  }

  // special cases for backend
  if (!userModel.jobTitle) {
    userModel.jobTitle = null;
  }

  // special cases for backend
  if (!userModel.expirationDemo) {
    userModel.expirationDemo = null;
  } else {
    userModel.expirationDemo = new Date(userModel.expirationDemo).toISOString();
  }

  // special cases for backend
  if (!userModel.personalNumber) {
    userModel.personalNumber = null;
  }

  return userModel;
}

Object.assign(UsersStore, {
  createUser(model) {
    model.viewModel.id = Math.random();
    model.viewModel.submit();

    const userModel = sanitazeUser(model.serialize());

    UIStore.setToTransition();
    userService
      .createNew(
        Object.assign(userModel, {
          _id: undefined,
          lastUpdate: undefined,
          orgunits: undefined
        })
      )
      .then(
        action(({ insertedId }) => {
          // when model is not active or email is blank, do not request pass generation token
          if (!model.isActive || model.isNotSendEmail || !model.email) {
            return Promise.resolve(insertedId);
          }

          return userService
            .generateTokenForPasswordReset(model.username, true)
            .then(() => Promise.resolve(insertedId));
        })
      )
      .then(insertedId => {
        resetModelPasswords(model);
        model.id = insertedId;
        model.lastUpdate = moment().format();

        this.addOrUpdateItem(model.serialize());

        browserHistory.push(`/home/users`);
        UIStore.setOutOfTransition();
      })
      .catch(
        action(error => {
          resetModelPasswords(model);

          // make model dirty again!
          model.viewModel.lastUpdate = moment().format();
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  updateUser(id) {
    const model = this.getItem(id);
    const prevIsNotSendEmail = model.isNotSendEmail;

    model.viewModel.submit();
    UIStore.setToTransition();

    const userModel = sanitazeUser(model.serialize());

    userService
      .update(
        id,
        Object.assign(userModel, {
          _id: undefined,
          username: undefined,
          lastUpdate: undefined,
          orgunits: undefined
        })
      )
      .then(
        action(() => {
          // when model is active and
          // email value is changed from empty to some value - request pass generation token
          if (model.isActive && !model.isNotSendEmail && prevIsNotSendEmail && model.email) {
            return userService
              .generateTokenForPasswordReset(model.username, false)
              .then(() => Promise.resolve());
          }
          return Promise.resolve();
        })
      )
      .then(
        action(() => {
          browserHistory.push(`/home/users`);
          UIStore.setOutOfTransition();

          // clean-up passwords
          resetModelPasswords(model);
        })
      )
      .catch(
        action(error => {
          resetModelPasswords(model);

          // make model dirty again!
          model.viewModel.lastUpdate = moment().format();
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  delete(id) {
    UIStore.setToTransition();
    userService
      .delete(id)
      .then(
        action(() => {
          this.queryItems(false);
          UIStore.setOutOfTransition();
        })
      )
      .catch(
        action(error => {
          UIStore.showError(error);
          UIStore.setOutOfTransition();
          console.error(error);
        })
      );
  },

  exportExcel() {
    const query = {
      'filter[isActive]': true,
      'filter[name]': 'test',
      token: SessionStore.getToken()
    };
    window.open(`/api/v2/users/report?${encodeUrlQuery(query, false)}`, '_blank');
  }
});
