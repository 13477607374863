import axios from 'axios';
import { observable, action } from 'mobx';

import { createDomainStore } from './base';
import { trailService } from 'services/transport-layer';
import TrailModel from 'models/trail';
import { errorHandler } from './mixins';
import UIStore, { createFilterStore } from './ui';
import moment from 'moment';
import SurveysStore from './surveys';
import SessionStore from './session';

const generalInfo = [
  'Uploaded',
  'DataExtracted',
  'ErrDataExtracted',
  'RetryProcessing',
  'MarkedAsDefective',
  'SITHS_Confirmed',
  'Ready4QA',
  'QA_Started',
  'Archived',
  'Unarchived',
  'DataUpdated',
  'DataSendingManual',
  'DataSendingAttempt',
  'ManualDataSendingRequired',
  'ErrDataSent',
  'DataSent'
];
const sensitiveInfo = ['DataSaved'];
const routeDetails = ['DataRouteFound'];

const actionToEvents = {
  Uploaded: generalInfo,
  DataRouteFound: routeDetails,
  DataSaved: sensitiveInfo,
  DataExtracted: generalInfo,
  ErrDataExtracted: generalInfo,
  RetryProcessing: generalInfo,
  MarkedAsDefective: generalInfo,
  SITHS_Confirmed: generalInfo,
  QA_Started: generalInfo,
  Archived: generalInfo,
  Unarchived: generalInfo,
  DataSendingManual: generalInfo,
  DataSendingAttempt: generalInfo,
  DataSent: generalInfo,
  ErrDataSent: generalInfo,
  DataUpdated: generalInfo,
  ManualDataSendingRequired: generalInfo
};

const { store: AuditTrailStore } = createDomainStore({
  name: 'reports',
  serviceToInject: trailService,
  domainModel: TrailModel
});

const store = Object.assign(AuditTrailStore, {
  query() {
    store.isListLoading.set(true);

    if (store.cancelToken) {
      store.cancelToken.cancel();
    }

    store.cancelToken = axios.CancelToken.source();

    const query = Object.assign(store.viewState.requestQuery, {
      guid: store.selectedSurveyGUID.get()
    });

    return trailService
      .query(query, store.cancelToken.token)
      .then(
        action(({ data, meta }) => {
          store.resetItems(data);
          store.meta.total = meta.total;
          store.meta.filtered = meta.filtered;
          store.isListLoading.set(false);
          store.cancelToken = null;
        })
      )
      .catch(error => {
        if (axios.isCancel(error)) {
          // just ignore canceling
          return;
        }

        UIStore.showError(error);
        store.isListLoading.set(false);
      });
  },

  getPageCount() {
    return store.meta.filtered;
  },

  fetchTimeline(events = []) {
    const query = Object.assign({
      guid: store.selectedSurveyGUID.get(),
      timeline: true,
      'filter[events]': events.join(',')
    });

    return trailService
      .query(query)
      .then(action(({ data }) => store.timeline.replace(data)))
      .catch(error => {
        console.error(error);
        UIStore.showError(error);
      });
  },

  fetchTrailEntity(trailId) {
    return trailService.entity(trailId).then(response => store.addOrUpdateItem(response));
  },

  getTrailById(id) {
    return store.getItem(id);
  },

  selectDiff(id) {
    store.selectedDiff.set(id);
  },

  aggregatedTimeline() {
    return store.timeline.map(item => {
      const line = item || {};
      return {
        id: line._id,
        timestamp: moment(line.timestamp),
        event: line.event
      };
    });
  },

  isSensitiveInfoTrail(event) {
    return sensitiveInfo.includes(event);
  },

  isRouteDetailsTrail(event) {
    return routeDetails.includes(event);
  }
});

store.meta = observable({ total: [], filtered: 0 });
store.viewState = createFilterStore('surveys-audit-trail', store.query, { itemsPerPage: 10 });
store.isListLoading = observable.box(false);
store.selectedSurvey = observable.box(null);
store.selectedSurveyGUID = observable.box(null);
store.trailEntities = observable.map({});
store.selectedDiff = observable.box(null);
store.timeline = observable.array([]);

export default store;

export function auditTrailResolver(nextState, replace, callback) {
  store.selectedSurvey.set(nextState.params.id);
  store.selectedSurveyGUID.set(nextState.params.guid);
  UIStore.setToTransition();

  const isAllowedToFetchSurveyDetails = SessionStore.isSuperAdmin() || SessionStore.isSecretary();
  const hasSurvey = !!SurveysStore.getSurvey(nextState.params.id);

  if (isAllowedToFetchSurveyDetails && !hasSurvey) {
    return SurveysStore.fetchSurveyById(nextState.params.id)
      .then(store.query.bind(store))
      .then(callback)
      .then(UIStore.setOutOfTransition)
      .catch(errorHandler(...arguments));
  }

  UIStore.setOutOfTransition();
  callback();
  return store.query().catch(errorHandler(...arguments));
}

export function auditTrailDetailsResolver(nextState, replace, callback) {
  UIStore.setToTransition();
  store.selectedSurvey.set(nextState.params.id);
  store.selectedSurveyGUID.set(nextState.params.guid);

  store
    .fetchTrailEntity(nextState.params.type)
    .then(({ event }) => store.fetchTimeline(actionToEvents[event]))
    .then(() => {
      store.selectedDiff.set(nextState.params.type);
      UIStore.setOutOfTransition();
      callback();
    })
    .catch(errorHandler(...arguments));
}
