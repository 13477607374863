import { autorun, observable, computed, action, when, toJS } from 'mobx';
import { queueProcessor } from 'mobx-utils';
import { browserHistory } from 'react-router';
import i18n, { lStorageLangKey } from '../i18n';
import { restClient } from 'services/transport-layer';
import moment from 'moment';
import { statusSerializer } from 'models/survey';

import { translate, existsTranslate } from 'utils/helpers';
const translateArea = [
  'errorsFromBackend',
  'templates',
  'users',
  'login',
  'surveys',
  'patients',
  'users',
  'careUnits'
];
const t = translate(translateArea);
const exists = existsTranslate(translateArea);

class UIStore {
  // Used to detect is application have some requests in stack
  @observable pendingRequests = 0;
  @observable
  modalState = {
    show: false,
    title: null,
    okCb: null,
    cancelCb: null
  };
  @observable currentLang = localStorage.getItem(lStorageLangKey) || 'sv';
  @observable notifications = null; // will be injected

  // Used to show spinner between transitions
  @observable transitionPending = false;

  @observable
  location = {
    last: '/',
    current: window.location.pathname
  };

  @observable pendingNotifications = [];

  constructor() {
    browserHistory.listen(({ pathname }) => {
      this.location.last = this.location.current;
      this.location.current = pathname;
    });

    restClient.axios.interceptors.request.use(
      config => {
        this.addPendingRequest();
        return config;
      },
      error => {
        this.removePendingRequest();
        return Promise.reject(error);
      }
    );

    restClient.axios.interceptors.response.use(
      response => {
        this.removePendingRequest();
        return response;
      },
      error => {
        this.removePendingRequest();
        return Promise.reject(error);
      }
    );

    queueProcessor(this.pendingNotifications, msg => {
      when(() => !!this.notifications, () => this.notifications.addNotification(msg));
    });
  }

  isListView() {
    return !/\d/.test(this.location.current);
  }

  isActiveNavigation(location, exact) {
    if (exact) {
      return this.location.current === location;
    }

    return this.location.current.includes(location);
  }

  @computed
  get isInTransition() {
    return this.transitionPending === true;
  }

  @action
  setToTransition() {
    this.transitionPending = true;
  }

  isActiveLanguage(lang) {
    return this.currentLang.includes(lang);
  }

  @action
  changeLanguage(lang) {
    this.currentLang = lang;

    i18n.changeLanguage(lang, err => {
      if (!err) {
        window.location.reload();
      }
    });
  }

  @action.bound
  showModal(onOk, onCancel, title) {
    this.modalState.show = true;
    this.modalState.title = title;
    this.modalState.okCb = onOk;
    this.modalState.cancelCb = onCancel;
  }

  @action.bound
  closeModal() {
    this.modalState.show = false;
    this.modalState.title = null;
    this.modalState.okCb = null;
    this.modalState.cancelCb = null;
  }

  @action.bound
  setOutOfTransition() {
    this.transitionPending = false;
  }

  @computed
  get isInSync() {
    return this.pendingRequests === 0;
  }

  @action
  addPendingRequest() {
    this.pendingRequests += 1;
  }

  @action
  removePendingRequest() {
    if (this.pendingRequests >= 0) {
      this.pendingRequests -= 1;
    }
  }

  @action.bound
  showError({ messageId, message }, translate) {
    console.error(arguments);

    let localMessage;
    if (messageId && exists(messageId)) {
      localMessage = translate ? translate(messageId) : t(messageId);
    } else {
      localMessage = message || t('unexpected_error');
    }
    const msg = {
      message: localMessage,
      level: 'error',
      position: 'tc',
      autoDismiss: 10
    };

    this.pendingNotifications.push(msg);
  }
}

class FilterState {
  LSKey = 'sc-filters';
  hooks = { onChange: null };
  isAnimating = false;

  config;

  // general
  @observable openState = { property: 'open', value: false };
  @observable openStateDoneCb = { property: 'openStateDoneCb', value: () => {} };

  // surveys
  @observable dateFromState = { property: 'dateFrom', value: '' };
  @observable dateToState = { property: 'dateTo', value: '' };
  @observable surveyStatusesState = { property: 'surveyStatuses', value: [] };
  @observable patientNameState = { property: 'patientState', value: '' };

  @observable templateNameState = { property: 'templateState', value: '' };
  @observable qaByState = { property: 'qaByState', value: '' };
  @observable uploadedByState = { property: 'uploadedByState', value: '' };
  @observable doctorByState = { property: 'doctorByState', value: '' };
  @observable lastUpdateFromState = { property: 'lastUpdateFromState', value: '' };
  @observable lastUpdateToState = { property: 'lastUpdateToState', value: '' };
  @observable careUnitNameState = { property: 'careUnitNameState', value: '' };

  // report
  @observable reportUserSearchState = { property: 'reportUserSearchState', value: '' };
  @observable reportNoteSearchState = { property: 'reportNoteSearchState', value: '' };
  @observable dateReportFromState = { property: 'dateReportFromState', value: '' };
  @observable dateReportToState = { property: 'dateReportToState', value: '' };

  // users
  @observable userNameState = { property: 'userState', value: '' };
  @observable userActiveState = { property: 'userActiveState', value: false };

  // patients
  @observable patientSearchState = { property: 'patientSearchState', value: '' };
  @observable patientPlaceSearchState = { property: 'patientPlaceSearchState', value: '' };
  @observable personalNumberSearchState = { property: 'personalNumberSearchState', value: '' };
  @observable spareNumberSearchState = { property: 'spareNumberSearchState', value: '' };

  // template
  @observable templateSearchState = { property: 'templateSearchState', value: '' };

  // mapping
  @observable mappingSearchState = { property: 'mappingSearchState', value: '' };

  // mapping
  @observable routingSearchState = { property: 'routingSearchState', value: '' };

  // nurses
  @observable nurseSearchState = { property: 'nurseSearchState', value: '' };

  // care unit
  @observable careUnitSearchState = { property: 'careUnitSearchState', value: '' };

  // common
  @observable sortState = { property: null, value: null };
  @observable pageState = { property: 'page', value: 1 };

  constructor(
    type,
    onChange,
    config = { itemsPerPage: 10 },
    sortState = { property: null, value: null }
  ) {
    this.type = type;
    this.config = config;
    this.sortState = sortState;
    this.states = [
      'sortState',
      'pageState',
      'surveyStatusesState',
      'dateFromState',
      'dateReportFromState',
      'dateToState',
      'dateReportToState',
      'patientNameState',
      'patientSearchState',
      'patientPlaceSearchState',
      'reportUserSearchState',
      'reportNoteSearchState',
      'personalNumberSearchState',
      'spareNumberSearchState',
      'templateSearchState',
      'mappingSearchState',
      'routingSearchState',
      'careUnitSearchState',
      'userActiveState',
      'templateNameState',
      'qaByState',
      'uploadedByState',
      'lastUpdateFromState',
      'lastUpdateToState',
      'careUnitNameState',
      'doctorByState',
      'userNameState',
      'nurseSearchState'
    ];
    const isInLS = localStorage.getItem(`${this.LSKey}-${type}`);

    this.hooks.onChange = onChange;

    if (isInLS) {
      this.states.forEach(state => {
        const item = localStorage.getItem(`${this.LSKey}-${type}-${state}`);
        if (item) {
          this[state] = JSON.parse(item);
        }
      });
    }

    autorun(() => {
      if (!isInLS) {
        localStorage.setItem(`${this.LSKey}-${type}`, true);
      }

      this.states.forEach(state => {
        localStorage.setItem(`${this.LSKey}-${type}-${state}`, JSON.stringify(toJS(this[state])));
      });
    });
  }

  @action.bound
  clearAll() {
    this.states.forEach(state => localStorage.removeItem(`${this.LSKey}-${this.type}-${state}`));
  }

  @action.bound
  resetAll() {
    this.clearAll();
    this.resetFiltered(false);
    this.pageState.value = 1;
    this.sortState.value = null;
    this.sortState.property = null;
  }

  @action.bound
  toggleOpen() {
    if (this.isAnimating) {
      return;
    }

    this.openState.value = !this.openState.value;
  }

  @action.bound
  closeViewPanel(doneCb = () => {}) {
    this.openState.value = false;
    this.openStateDoneCb.value = doneCb;
  }

  @action.bound
  applySort(newProp) {
    let { property, value: currentValue } = this.sortState;

    if (property !== newProp) {
      currentValue = null;
    }

    if (!currentValue) {
      this.sortState.value = 'desc';
    }

    if (currentValue === 'desc') {
      this.sortState.value = 'asc';
    }

    if (currentValue === 'asc') {
      this.sortState.value = null;
    }

    this.sortState.property = newProp;

    this.fireOnChange();
  }

  @action.bound
  changePage(page) {
    this.pageState.value = page;
    this.fireOnChange();
  }

  @action.bound
  changeStatus(id) {
    const idx = this.surveyStatusesState.value.indexOf(id);
    if (idx !== -1) {
      this.surveyStatusesState.value.splice(idx, 1);
    } else {
      this.surveyStatusesState.value.push(id);
    }

    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changePatientName(value) {
    this.patientNameState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeNurseName(value) {
    this.nurseSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }
  @action.bound
  changeUserName(value) {
    this.userNameState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeDoctorBy(value) {
    this.doctorByState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeTemplateName(value) {
    this.templateNameState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeTemplateSearch(value) {
    this.templateSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeMappingSearch(value) {
    this.mappingSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }
  @action.bound
  changeRoutingSearch(value) {
    this.routingSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeCareUnitSearchState(value) {
    this.careUnitSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }
  @action.bound
  changeUserActiveState() {
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changePatientSearch(value) {
    this.patientSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changepatientPlaceSearch(value) {
    this.patientPlaceSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeReportUserSearch(value) {
    this.reportUserSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeReportNoteSearch(value) {
    this.reportNoteSearchState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changePersonalNumberSearch(value) {
    let indexOfUndescore = value.indexOf('_');

    this.personalNumberSearchState.value = value.substring(
      0,
      ~indexOfUndescore ? indexOfUndescore : value.length
    );

    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeSparePersonalNumberSearch(value) {
    let indexOfUndescore = value.indexOf('_');

    this.spareNumberSearchState.value = value.substring(
      0,
      ~indexOfUndescore ? indexOfUndescore : value.length
    );

    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeQaBy(value) {
    this.qaByState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeUploadedBy(value) {
    this.uploadedByState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeCareUnitName(value) {
    this.careUnitNameState.value = value;
    this.pageState.value = 1;
    this.fireOnChange();
  }

  @action.bound
  changeDate(type, date) {
    const dateMoment = moment(date, 'YYYY-MM-DD');
    const state = this[type === 'to' ? 'dateToState' : 'dateFromState'];
    this.pageState.value = 1;

    if (dateMoment.isValid()) {
      state.value = date;
      this.fireOnChange();
    }

    if (!date) {
      this.fireOnChange();
    }
  }
  @action.bound
  changeReportDate(type, date) {
    const dateMoment = moment(date, 'YYYY-MM-DD');
    const state = this[type === 'to' ? 'dateReportToState' : 'dateReportFromState'];
    this.pageState.value = 1;

    if (dateMoment.isValid()) {
      state.value = date;
      this.fireOnChange();
    }

    if (!date) {
      this.fireOnChange();
    }
  }

  @action.bound
  changeLastUpdate(type, date) {
    const dateMoment = moment(date, 'YYYY-MM-DD');
    const state = this[type === 'to' ? 'lastUpdateToState' : 'lastUpdateFromState'];
    this.pageState.value = 1;

    if (dateMoment.isValid()) {
      state.value = date;
      this.fireOnChange();
    }

    if (!date) {
      this.fireOnChange();
    }
  }

  @action.bound
  resetFiltered(doFire = true) {
    this.patientSearchState.value = '';
    this.patientPlaceSearchState.value = '';
    this.reportUserSearchState.value = '';
    this.reportNoteSearchState.value = '';
    this.personalNumberSearchState.value = '';
    this.spareNumberSearchState.value = '';
    this.templateSearchState.value = '';
    this.mappingSearchState.value = '';
    this.routingSearchState.value = '';
    this.careUnitSearchState.value = '';
    this.userActiveState.value = false;
    this.nurseSearchState.value = '';
    this.templateNameState.value = '';
    this.patientNameState.value = '';
    this.qaByState.value = '';
    this.uploadedByState.value = '';
    this.dateFromState.value = '';
    this.dateReportFromState.value = '';
    this.dateToState.value = '';
    this.dateReportToState.value = '';
    this.lastUpdateFromState.value = '';
    this.lastUpdateToState.value = '';
    this.careUnitNameState.value = '';
    this.doctorByState.value = '';
    this.surveyStatusesState.value = [];
    this.userNameState.value = '';

    if (doFire) {
      this.fireOnChange();
    }
  }

  @computed
  get isFiltered() {
    return (
      this.patientSearchState.value ||
      this.patientPlaceSearchState.value ||
      this.reportUserSearchState.value ||
      this.reportNoteSearchState.value ||
      this.personalNumberSearchState.value ||
      this.spareNumberSearchState.value ||
      this.mappingSearchState.value ||
      this.routingSearchState.value ||
      this.templateSearchState.value ||
      this.careUnitSearchState.value ||
      this.templateNameState.value ||
      this.patientNameState.value ||
      this.qaByState.value ||
      this.uploadedByState.value ||
      this.dateFromState.value ||
      this.dateReportFromState.value ||
      this.dateToState.value ||
      this.dateReportToState.value ||
      this.lastUpdateFromState.value ||
      this.lastUpdateToState.value ||
      this.careUnitNameState.value ||
      this.doctorByState.value ||
      this.surveyStatusesState.value.length ||
      this.userNameState.value ||
      this.nurseSearchState.value ||
      this.userActiveState.value
    );
  }

  @computed
  get requestQuery() {
    const query = { 'page[number]': this.pageState.value, 'page[size]': this.config.itemsPerPage };
    const { property: sortProp, value: sortVal } = this.sortState;
    const { value: dateFromVal } = this.dateFromState;
    const { value: dateReportFromVal } = this.dateReportFromState;
    const { value: dateToVal } = this.dateToState;
    const { value: dateReportToVal } = this.dateReportToState;
    const { value: patienVal } = this.patientNameState;
    const { value: patientSearchVal } = this.patientSearchState;
    const { value: patientPlaceSearchVal } = this.patientPlaceSearchState;
    const { value: reportUserSearchVal } = this.reportUserSearchState;
    const { value: reportNoteSearchVal } = this.reportNoteSearchState;
    const { value: personalNumberSearchVal } = this.personalNumberSearchState;
    const { value: sparelNumberSearchVal } = this.spareNumberSearchState;
    const { value: mappingSearchVal } = this.mappingSearchState;
    const { value: routingSearchVal } = this.routingSearchState;
    const { value: templateSearchVal } = this.templateSearchState;
    const { value: careUnitSearchVal } = this.careUnitSearchState;
    const { value: userActiveVal } = this.userActiveState;
    const { value: templateVal } = this.templateNameState;
    const { value: statusesVal } = this.surveyStatusesState;
    const { value: qaByVal } = this.qaByState;
    const { value: uploadedByVal } = this.uploadedByState;
    const { value: lastUpdateFromVal } = this.lastUpdateFromState;
    const { value: lastUpdateToVal } = this.lastUpdateToState;
    const { value: careUnitNameVal } = this.careUnitNameState;
    const { value: doctorNameVal } = this.doctorByState;
    const { value: userNameVal } = this.userNameState;
    const { value: nurseSearchVal } = this.nurseSearchState;

    if (sortProp && sortVal) {
      // serialization for backend
      switch (sortProp) {
        case 'careUnitName':
          query.sort = sortVal === 'asc' ? 'orgunitName' : `-orgunitName`;
          break;
        case 'patientPlace':
          query.sort = sortVal === 'asc' ? 'patientPlace' : `-patientPlace`;
          break;
        default:
          query.sort = sortVal === 'asc' ? sortProp : `-${sortProp}`;
      }
    }

    const dateFrom = moment(dateFromVal, 'YYYY-MM-DD');
    if (dateFromVal && dateFrom.isValid()) {
      query['filter[visitDateFrom]'] = dateFrom.format('YYYY-MM-DD');
    }

    const dateTo = moment(dateToVal, 'YYYY-MM-DD');

    if (dateToVal && dateTo.isValid()) {
      query['filter[visitDateTo]'] = dateTo.format('YYYY-MM-DD');
    }

    const dateReportFrom = moment(dateReportFromVal, 'YYYY-MM-DD');
    if (dateReportFrom && dateReportFrom.isValid()) {
      query['filter[from]'] = dateReportFrom.format('YYYY-MM-DD');
    }

    const dateReportTo = moment(dateReportToVal, 'YYYY-MM-DD');

    if (dateReportTo && dateReportTo.isValid()) {
      query['filter[to]'] = dateReportTo.format('YYYY-MM-DD');
    }

    if (patienVal) {
      query['filter[patient]'] = patienVal;
    }

    if (templateVal) {
      query['filter[template]'] = templateVal;
    }

    if (templateSearchVal) {
      query['filter[name]'] = templateSearchVal;
    }

    if (patientSearchVal) {
      query['filter[fullName]'] = patientSearchVal;
    }

    if (patientPlaceSearchVal) {
      query['filter[patientPlace]'] = patientPlaceSearchVal;
    }

    if (personalNumberSearchVal) {
      query['filter[personalNumber]'] = personalNumberSearchVal;
    }

    if (sparelNumberSearchVal) {
      query['filter[personalNumber]'] = sparelNumberSearchVal;
    }

    if (qaByVal) {
      query['filter[qaBy]'] = qaByVal;
    }

    if (uploadedByVal) {
      query['filter[uploadedBy]'] = uploadedByVal;
    }

    if (statusesVal.length) {
      query['filter[statuses]'] = statusesVal.map(statusSerializer).join(',');
    }

    if (lastUpdateFromVal) {
      query['filter[lastUpdateFrom]'] = lastUpdateFromVal;
    }

    if (lastUpdateToVal) {
      query['filter[lastUpdateTo]'] = lastUpdateToVal;
    }

    if (careUnitNameVal) {
      query['filter[orgunit]'] = careUnitNameVal;
    }

    if (doctorNameVal) {
      query['filter[doctor]'] = doctorNameVal;
    }

    if (userNameVal) {
      query['filter[name]'] = userNameVal;
    }

    if (nurseSearchVal) {
      query['filter[name]'] = nurseSearchVal;
    }

    if (careUnitSearchVal) {
      query['filter[name]'] = careUnitSearchVal;
    }

    if (userActiveVal) {
      query['filter[isActive]'] = 'true';
    }

    if (mappingSearchVal) {
      query['filter[name]'] = mappingSearchVal;
    }

    if (routingSearchVal) {
      query['filter[name]'] = routingSearchVal;
    }

    if (reportUserSearchVal) {
      query['filter[username]'] = reportUserSearchVal;
    }

    if (reportNoteSearchVal) {
      query['filter[note]'] = reportNoteSearchVal;
    }

    return query;
  }

  fireOnChange() {
    this.hooks.onChange && this.hooks.onChange();
  }
}

const stores = {};
export function createFilterStore(type, onChange, config, sortState) {
  if (!stores[type]) {
    stores[type] = new FilterState(type, onChange, config, sortState);
    return stores[type];
  }

  return stores[type];
}

export default new UIStore();
