import React from 'react';

import './survey-quick-info.css';

export default ({ label = '<empty>', data = '<empty>' }) => (
  <div className="survey-quick-info">
    <div>
      <label>
        {label}:
        <p>{data}</p>
      </label>
    </div>
  </div>
);
