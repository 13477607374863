import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import { resolve, nested } from 'table-resolver';
import { Link } from 'react-router';
import Rodal from 'rodal';
import Spinner from 'react-spinkit';
import DataTable from 'components/dataTable/data-table';
import { statusDeserializer } from 'stores/models/survey';
import { momentTimeFormatter, userNameFormatter, applySortingServerSide } from 'formatters/tables';

import { translate, isObject } from 'utils/helpers';
import { computed, observable } from 'mobx';

import './surveys.css';
import stylesDetails from './survey-details.css';

const t = translate(['common', 'surveys', 'auditTrail']);

const Content = props => (
  <table data-test-id={`SurveyDetail_${props.header}`}>
    <tbody>
      {props.data.filter(Boolean).map((item, idx) => (
        <tr key={idx}>
          <td>{t(item.label)}</td>
          <td>{item.label === 'status' ? t(statusDeserializer(item.text)) : item.text}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

function SurveyDetailsBlock({ header = 'header', metaData = [] }) {
  return (
    <div>
      {metaData.filter(Boolean).map((el, idx) => (
        <div className="block" key={idx}>
          <h1 className="header">{el.title}</h1>
          <Content data={el.data} header={el.title} />
        </div>
      ))}
    </div>
  );
}

function compareEventsFormatter(trail, session, ...params) {
  const isAllowedToFetchSurveyDetails = session.isSuperAdmin() || session.isSecretary();
  return (value, { rowData }) => {
    if (!value) {
      return '';
    }
    if (trail.isSensitiveInfoTrail(rowData.event)) {
      return valueTermsFormatter(
        value,
        rowData,
        'termDetails',
        isAllowedToFetchSurveyDetails,
        ...params
      );
    }
    if (trail.isRouteDetailsTrail(rowData.event)) {
      return valueTermsFormatter(
        value,
        rowData,
        'dataRouteFound',
        isAllowedToFetchSurveyDetails,
        ...params
      );
    }
    return valueTermsFormatter(
      value,
      rowData,
      'generalInfo',
      isAllowedToFetchSurveyDetails,
      ...params
    );
  };
}

function objectToArray(obj, prefix = '') {
  let data = [];
  const arrayKeys = Object.keys(obj).filter(el => el !== '_id');

  for (let index = 0; index < arrayKeys.length; index++) {
    let key = arrayKeys[index];
    let value = obj[key];
    if (Array.isArray(value)) {
      data = data.concat({
        label: key,
        text: value.map((el, idx) => <div key={idx}>{el}</div>)
      });
    } else if (key === 'EMR') {
      const arr = objectToArray(value);
      data = data.concat({
        label: key,
        text: arr.map((el, idx) => (
          <div key={idx}>
            {t(el.label)}: {el.text + ''}
          </div>
        ))
      });
    } else if (isObject(value)) {
      data = data.concat(objectToArray(value, key));
    } else {
      data = data.concat({
        label: prefix + key,
        text: value + ''
      });
    }
  }

  return data;
}

function valueTermsFormatter(
  value = {},
  rowData,
  title,
  isAllowedToFetchSurveyDetails,
  triggerModal,
  setViewDetailsData
) {
  return [
    Array.isArray(value)
      ? `${t(title)}: ${value.length}`
      : (value.error && t('error')) ||
        (value.status && t(statusDeserializer(value.status))) ||
        value.name,
    <br key={'br' + rowData.id} />,

    isAllowedToFetchSurveyDetails && (value.length || isObject(value)) ? (
      <small key={'small' + rowData.id} className="survey-time">
        <a
          data-test-id={'link-' + rowData.id}
          to={''}
          onClick={() => {
            const metaData = [];
            let data;
            if (Array.isArray(value)) {
              value.forEach((obj, i) => {
                data = objectToArray(obj);
                metaData.push({ title: `${t(title)} ${i + 1}`, data });
              });
            } else {
              data = objectToArray(value);
              metaData.push({ title: t(title), data });
            }
            setViewDetailsData(metaData);
            triggerModal(true);
          }}
        >
          {t('viewDetails')}
        </a>
      </small>
    ) : (
      ''
    )
  ];
}

@observer
class DetailDialog extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        property: 'title',
        header: {
          label: t('title')
        }
      },
      {
        property: 'label',
        header: {
          label: t('label')
        }
      },
      {
        property: 'text',
        header: {
          label: t('text')
        }
      }
    ];
  }

  closeDialog = () => {
    this.props.triggerModal(false, '');
  };

  render() {
    const data = this.props.data;

    return (
      <Rodal
        visible={this.props.visible}
        onClose={this.closeDialog}
        width={750}
        className="rodal-lg with-scroll"
      >
        {this.props.isLoading ? (
          <div className="global-spinner">
            <Spinner name="double-bounce" fadeIn="none" />
          </div>
        ) : (
          <div className="surveys-auditTrail-page" data-test-id="auditTrail-Modal">
            <div className="modal-header" />
            <div className="modal-body">
              <div className="scrollable">
                <div className="content">
                  <SurveyDetailsBlock header={t('metadata')} metaData={data} />
                </div>
              </div>
            </div>
            <button
              onClick={this.closeDialog}
              className="modal-ok-btn"
              data-test-id="ok-detail-Modal"
            >
              {t('ok')}
            </button>
          </div>
        )}
      </Rodal>
    );
  }
}
@inject('trail', 'session')
@observer
export default class AuditTrail extends Component {
  @observable visible = false;
  viewDetailsData = [];

  constructor(props) {
    super(props);

    const sortFormatter = applySortingServerSide(this.onSortClick, props.trail.viewState.sortState);

    this.columns = [
      {
        property: 'user',
        header: {
          label: t('username'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [userNameFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },

      {
        property: 'event',
        header: {
          label: t('event')
        },
        cell: {
          // Dynamic translate from predefined backend codes
          formatters: [val => t(`events.${val}`)],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      },

      {
        property: 'value',
        header: {
          label: t('value'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [
            compareEventsFormatter(
              props.trail,
              props.session,
              this.triggerModal,
              this.setViewDetailsData
            )
          ]
        }
      },
      {
        property: 'timestamp',
        header: {
          label: t('timestamp'),
          formatters: [sortFormatter]
        },
        cell: {
          formatters: [momentTimeFormatter],
          props: { style: { whiteSpace: 'nowrap' } }
        }
      }
    ];
  }

  listData = () => resolve({ columns: this.columns, method: nested })(this.props.trail.list);
  onSortClick = property => this.props.trail.viewState.applySort(property);
  onPageClick = selected => this.props.trail.viewState.changePage(selected);

  triggerModal = visible => {
    this.visible = visible;
  };

  setViewDetailsData = data => {
    this.viewDetailsData = data;
  };

  render() {
    const { trail } = this.props;
    return (
      <div className="surveys-page">
        <DetailDialog
          triggerModal={this.triggerModal}
          visible={this.visible}
          data={this.viewDetailsData}
          isLoading={!this.viewDetailsData.length}
        />
        <div className="panel padded-top20" data-test-id="auditTrail-table">
          <DataTable
            className="table-fixed"
            data={this.listData}
            columns={this.columns}
            isLoading={trail.isListLoading}
            pageState={trail.viewState.pageState}
            pageCount={trail.getPageCount}
            onPageChange={this.onPageClick}
            serverSide
          />
        </div>
      </div>
    );
  }
}
