export function dateFormat(momentObj) {
  return momentObj.format('YYYY-MM-DD');
}

export function timeFormat(momentObj) {
  return momentObj.format('h:mm a');
}

export function dateTimeFormat(momentObj) {
  return momentObj.format('YYYY-MM-DD h:mm a');
}
