import { BaseService } from '../base-service';

export class TermsService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/terms' });
  }

  fetch(input = '') {
    return this.get('/', { params: { name: input } });
  }
}
