import { BaseService } from '../base-service';

export class VersionService extends BaseService {
  constructor({ restClient }) {
    super({ restClient, resource: '/version' });
  }

  fetch() {
    return this.get('/');
  }
}
